var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Sidebar", {
        attrs: { no_profile: this.$store.state.current_profile == null },
        on: { side_toggle: _vm.sidebar_toggle },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main-content" },
        [
          _c("Topbar", { on: { side_toggle: _vm.sidebar_toggle } }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: {
                id: "accountpref-modal",
                tabindex: "-1",
                role: "dialog",
                "aria-labelledby": "exampleModalLabel",
                "aria-hidden": "true",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog modal-lg",
                  attrs: { role: "document" },
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-head d-flex align-items-center justify-content-between",
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "modal-title",
                            attrs: { id: "accountpref-modal-label" },
                          },
                          [
                            _vm._v(
                              "\n              Настройки аккаунта\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: {
                              type: "button",
                              "data-dismiss": "modal",
                              "aria-label": "Close",
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "16",
                                  height: "15",
                                  viewBox: "0 0 16 15",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z",
                                    fill: "#6C757D",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(0),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          this.$store.state.current_profile == null
            ? _c("MainClear")
            : _vm._e(),
          _vm._v(" "),
          this.$store.state.current_profile != null &&
          this.$store.state.current_profile.platform == "wz"
            ? _c("Main", {
                attrs: {
                  base64_qr: _vm.base64_qr,
                  is_task_exist: _vm.is_task_exist,
                  authorizations: _vm.authorizations,
                  user: this.user,
                },
                on: {
                  toggle_task_exist: _vm.toggle_task_exist,
                  qr_updating: _vm.qr_updating,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          this.$store.state.current_profile != null &&
          this.$store.state.current_profile.platform == "tg"
            ? _c("MainTG", {
                attrs: {
                  base64_qr: _vm.base64_qr,
                  is_task_exist: _vm.is_task_exist,
                  authorizations: _vm.authorizations,
                  user: this.user,
                },
                on: {
                  toggle_task_exist: _vm.toggle_task_exist,
                  qr_updating: _vm.qr_updating,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          this.$store.state.current_profile != null &&
          this.$store.state.current_profile.platform == "av"
            ? _c("MainAvito", {
                attrs: { authorizations: _vm.authorizations, user: this.user },
              })
            : _vm._e(),
          _vm._v(" "),
          this.$store.state.current_profile != null &&
          this.$store.state.current_profile.platform == "sms"
            ? _c("MainSMS", {
                attrs: { authorizations: _vm.authorizations, user: this.user },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-text p-20" }, [
      _c("h4", [_vm._v("Смена пароля")]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-sm-4" }, [
          _c("label", [_vm._v("Ваш текущий пароль")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group pass_show" }, [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "password", placeholder: "Текущий пароль" },
            }),
          ]),
          _vm._v(" "),
          _c("label", [_vm._v("Новый пароль")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group pass_show" }, [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "password", placeholder: "Новый пароль" },
            }),
          ]),
          _vm._v(" "),
          _c("label", [_vm._v("Новый пароль")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group pass_show" }, [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "password", placeholder: "Новый пароль" },
            }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }