var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lk_footer" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between" },
      [
        _c("div", { staticClass: "d-flex lkf_links" }, [
          !this.$store.state.user.moysklad
            ? _c("div", { staticClass: "me-3" }, [
                _c(
                  "a",
                  { staticClass: "grey_link", attrs: { href: "/oferta" } },
                  [_vm._v("Пользовательское соглашение")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.$store.state.user.moysklad
            ? _c("div", { staticClass: "me-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "grey_link",
                    attrs: { href: "/oferta", target: "_blank" },
                  },
                  [_vm._v("Пользовательское соглашение")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !this.$store.state.user.moysklad
            ? _c("div", [
                _c(
                  "a",
                  { staticClass: "grey_link", attrs: { href: "/privacy" } },
                  [_vm._v("Политика конфиденциальности")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.$store.state.user.moysklad
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "grey_link",
                    attrs: { href: "/privacy", target: "_blank" },
                  },
                  [_vm._v("Политика конфиденциальности")]
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "me-2" }, [
            _vm._v("© " + _vm._s(_vm.currentDate.getFullYear())),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-content" }, [_vm._v("Wappi")]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }