<template>
  
    <nav class="nav navbar navbar-expand-lg navbar-light iq-navbar">
      <div class="container-fluid navbar-inner">
        <a v-if="this.$store.state.amo_mode" href="/dashboard" class="navbar-brand">
          <picture>
            <source srcset="/static/index/img/logo.webp" type="image/webp" />
            <img src="/static/index/img/logo.png" alt="Wappi Logo" width="100"
          /></picture>
        </a>
        <a v-if="!this.$store.state.amo_mode" href="/" class="navbar-brand">
          <picture>
            <source srcset="/static/index/img/logo.webp" type="image/webp" />
            <img src="/static/index/img/logo.png" alt="Wappi Logo" width="100"
          /></picture>
        </a>
        <div
          class="sidebar-toggle mini"
          data-toggle="sidebar"
          data-active="true"
          @click="side_toggle"
        >
          <i class="icon">
            <svg width="24px" height="24px" viewBox="0 0 24 24">

              <path fill="currentColor" d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z"></path>
              <path fill="currentColor" d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"></path>
              <path fill="currentColor" d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"></path>
            </svg>
          </i>
        </div>


          <a
            class="nav-link py-0 d-flex d-sm-flex d-md-flex d-lg-none align-items-center"
            href="#"
            id="navbarDropdown_mob"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 6.5C12.5806 6.5 9.80645 9.1875 9.80645 12.5C9.80645 15.8125 12.5806 18.5 16 18.5C19.4194 18.5 22.1935 15.8125 22.1935 12.5C22.1935 9.1875 19.4194 6.5 16 6.5ZM16 15.5C14.2903 15.5 12.9032 14.1562 12.9032 12.5C12.9032 10.8438 14.2903 9.5 16 9.5C17.7097 9.5 19.0968 10.8438 19.0968 12.5C19.0968 14.1562 17.7097 15.5 16 15.5ZM16 0.5C7.16129 0.5 0 7.4375 0 16C0 24.5625 7.16129 31.5 16 31.5C24.8387 31.5 32 24.5625 32 16C32 7.4375 24.8387 0.5 16 0.5ZM16 28.5C12.7935 28.5 9.86452 27.3563 7.60645 25.475C8.56774 24.0375 10.2129 23.0625 12.0968 23.0063C13.4387 23.4062 14.7161 23.6063 16 23.6063C17.2839 23.6063 18.5613 23.4125 19.9032 23.0063C21.7871 23.0688 23.4323 24.0375 24.3935 25.475C22.1355 27.3563 19.2065 28.5 16 28.5ZM26.4968 23.2437C24.9226 21.2812 22.4903 20 19.7161 20C19.0581 20 18.0387 20.6 16 20.6C13.9677 20.6 12.9419 20 12.2839 20C9.51613 20 7.08387 21.2812 5.50323 23.2437C3.99355 21.2 3.09677 18.7 3.09677 16C3.09677 9.10625 8.88387 3.5 16 3.5C23.1161 3.5 28.9032 9.10625 28.9032 16C28.9032 18.7 28.0065 21.2 26.4968 23.2437Z"
                fill="#23BB86"
              />
            </svg>
            <p class="ms-2 mb-0 caption-sub-title d-none d-sm-inline">
              {{ this.$store.state.user.email }}
            </p>
            <span class="ms-2 d-none d-sm-inline">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 5.11771L1.70711 0.824815C1.31658 0.43429 0.683417 0.43429 0.292893 0.824815C-0.0976305 1.21534 -0.0976305 1.8485 0.292893 2.23903L5.29289 7.23903C5.68342 7.62955 6.31658 7.62955 6.70711 7.23903L11.7071 2.23903C12.0976 1.8485 12.0976 1.21534 11.7071 0.824815C11.3166 0.43429 10.6834 0.43429 10.2929 0.824815L6 5.11771Z"
                  fill="#6c757d"
                />
              </svg>
            </span>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown_mob"
            v-if="!this.$store.state.user.moysklad"
          >
            

            <li><a class="dropdown-item" href="/users/logout">Выход</a></li>
          </ul>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto navbar-list mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link py-0 d-flex align-items-center"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 6.5C12.5806 6.5 9.80645 9.1875 9.80645 12.5C9.80645 15.8125 12.5806 18.5 16 18.5C19.4194 18.5 22.1935 15.8125 22.1935 12.5C22.1935 9.1875 19.4194 6.5 16 6.5ZM16 15.5C14.2903 15.5 12.9032 14.1562 12.9032 12.5C12.9032 10.8438 14.2903 9.5 16 9.5C17.7097 9.5 19.0968 10.8438 19.0968 12.5C19.0968 14.1562 17.7097 15.5 16 15.5ZM16 0.5C7.16129 0.5 0 7.4375 0 16C0 24.5625 7.16129 31.5 16 31.5C24.8387 31.5 32 24.5625 32 16C32 7.4375 24.8387 0.5 16 0.5ZM16 28.5C12.7935 28.5 9.86452 27.3563 7.60645 25.475C8.56774 24.0375 10.2129 23.0625 12.0968 23.0063C13.4387 23.4062 14.7161 23.6063 16 23.6063C17.2839 23.6063 18.5613 23.4125 19.9032 23.0063C21.7871 23.0688 23.4323 24.0375 24.3935 25.475C22.1355 27.3563 19.2065 28.5 16 28.5ZM26.4968 23.2437C24.9226 21.2812 22.4903 20 19.7161 20C19.0581 20 18.0387 20.6 16 20.6C13.9677 20.6 12.9419 20 12.2839 20C9.51613 20 7.08387 21.2812 5.50323 23.2437C3.99355 21.2 3.09677 18.7 3.09677 16C3.09677 9.10625 8.88387 3.5 16 3.5C23.1161 3.5 28.9032 9.10625 28.9032 16C28.9032 18.7 28.0065 21.2 26.4968 23.2437Z"
                    fill="#23BB86"
                  />
                </svg>

                <div class="caption ms-3 d-flex align-items-center">
                  <p class="mb-0 caption-sub-title">
                    {{ this.$store.state.user.email }}
                  </p>
                   <span class="ms-2" v-if="!this.$store.state.user.moysklad">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 5.11771L1.70711 0.824815C1.31658 0.43429 0.683417 0.43429 0.292893 0.824815C-0.0976305 1.21534 -0.0976305 1.8485 0.292893 2.23903L5.29289 7.23903C5.68342 7.62955 6.31658 7.62955 6.70711 7.23903L11.7071 2.23903C12.0976 1.8485 12.0976 1.21534 11.7071 0.824815C11.3166 0.43429 10.6834 0.43429 10.2929 0.824815L6 5.11771Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
              </a>
           <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
                v-if="!this.$store.state.user.moysklad"
              >
               <!--   <li>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#accountpref-modal"
                    >Настройки</a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>-->
                <li><a id="user_logout" class="dropdown-item" :href="this.$store.state.amo_mode ? '/users/logout?embedded=true' : '/users/logout' ">Выход</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
 
</template>
<script>
export default {
  data() {
    return {
      user_email: "",
    };
  },
  methods: {
    side_toggle(mob) {
      this.$emit("side_toggle", mob);
    },
  },
  mounted() {
      // console.log("111");
      // console.log("this.$store.state.amo_mode");
      // console.log(this.$store.state.amo_mode);
    },
    // created() {
    //   window.addEventListener("message", (event) => {
    //   console.log("Полученo:", event.data);
    //       if (event.origin.indexOf("amocrm.ru") >= 0 || event.origin.indexOf("amocrm.com") >= 0) {
    //           document.getElementById('user_logout').setAttribute("href", document.getElementById('user_logout').getAttribute("href") + "?embedded=true");
    //       }
    //   });
    // },
};
</script>
