var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "responder_head_margin" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected_event,
                expression: "selected_event",
              },
            ],
            staticClass: "form-select",
            attrs: { name: "profile" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selected_event = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.toggleDocumentStatuses,
              ],
            },
          },
          _vm._l(_vm.events, function (t, i) {
            return _c("option", { domProps: { value: i } }, [
              t.active ? _c("span", [_vm._v("🟢")]) : _vm._e(),
              !t.active ? _c("span", [_vm._v("⚪")]) : _vm._e(),
              _vm._v(" " + _vm._s(t.name)),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm._m(2),
    ]),
    _vm._v(" "),
    _vm.events.length > 0
      ? _c("div", { staticClass: "responder_head_margin" }, [
          _c(
            "div",
            { staticClass: "d-flex template_buttons_ms" },
            [
              _vm._l(
                this.events[this.selected_event].dataInstance,
                function (template, i) {
                  return _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "selected_template_button",
                        class: { active: _vm.selected_template == i },
                        on: {
                          click: function ($event) {
                            _vm.selected_template = i
                          },
                        },
                      },
                      [_vm._v("Шаблон " + _vm._s(i + 1))]
                    ),
                  ])
                }
              ),
              _vm._v(" "),
              _c("div", { staticClass: "selected_template_add" }, [
                this.events[this.selected_event].dataInstance.length < 10
                  ? _c(
                      "a",
                      {
                        staticStyle: { "margin-top": "3px", display: "block" },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addTemplate()
                          },
                        },
                      },
                      [_vm._v("Добавить шаблон")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.events[this.selected_event].dataInstance.length > 9
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#D3D3D3",
                          "margin-top": "3px",
                          display: "block",
                        },
                      },
                      [_vm._v("Добавить шаблон")]
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.events.length > 0
      ? _c("div", { staticClass: "responder_head_margin" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("switch-toggle", {
                key:
                  _vm.selected_uuid +
                  "." +
                  _vm.events[_vm.selected_event].type +
                  "." +
                  _vm.events[_vm.selected_event].value +
                  "." +
                  _vm.key_iter +
                  "." +
                  _vm.selected_template,
                staticStyle: { display: "inline-block" },
                attrs: {
                  responder: null,
                  uid: "222",
                  model:
                    _vm.events[_vm.selected_event].dataInstance[
                      _vm.selected_template
                    ].active,
                },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(
                      _vm.events[_vm.selected_event].dataInstance[
                        _vm.selected_template
                      ],
                      "active",
                      $event
                    )
                  },
                  toggler: _vm.toggleStatus,
                },
              }),
              _vm._v(" "),
              _vm.events[_vm.selected_event].dataInstance[_vm.selected_template]
                .active
                ? _c(
                    "div",
                    {
                      staticClass:
                        "bot_mini_text always_visible d-inline-block initial active",
                    },
                    [_vm._v("\n            ВКЛЮЧЕН")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.events[_vm.selected_event].dataInstance[
                _vm.selected_template
              ].active
                ? _c(
                    "div",
                    {
                      staticClass:
                        "bot_mini_text always_visible d-inline-block initial",
                    },
                    [_vm._v("\n            ВЫКЛЮЧЕН")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.events.length > 0 && _vm.events[_vm.selected_event].type == "UPDATE"
      ? _c("div", { staticClass: "responder_head_margin" }, [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    label: "name",
                    "track-by": "code",
                    placeholder: "Выберите параметр",
                    options: _vm.events[_vm.selected_event].onFieldsParams,
                    multiple: true,
                    taggable: true,
                    "hide-selected": true,
                  },
                  model: {
                    value:
                      _vm.events[_vm.selected_event].dataInstance[
                        _vm.selected_template
                      ].onFieldsData,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.events[_vm.selected_event].dataInstance[
                          _vm.selected_template
                        ],
                        "onFieldsData",
                        $$v
                      )
                    },
                    expression:
                      "events[selected_event].dataInstance[selected_template].onFieldsData",
                  },
                },
                [
                  _c("span", {
                    staticStyle: { display: "none" },
                    attrs: { slot: "noResult" },
                    slot: "noResult",
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { attrs: { slot: "noOptions" }, slot: "noOptions" },
                    [
                      _vm._v(
                        "\n            Нет доступных параметров\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(5),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.events.length > 0 &&
    _vm.events[_vm.selected_event].type == "UPDATE" &&
    _vm.showDocumentStatuses
      ? _c("div", { staticClass: "responder_head_margin" }, [
          _vm._m(6),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    label: "name",
                    "track-by": "code",
                    placeholder: "Выберите статус",
                    options: _vm.events[_vm.selected_event].stateListParams,
                    multiple: true,
                    taggable: true,
                    "hide-selected": true,
                  },
                  model: {
                    value:
                      _vm.events[_vm.selected_event].dataInstance[
                        _vm.selected_template
                      ].stateListData,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.events[_vm.selected_event].dataInstance[
                          _vm.selected_template
                        ],
                        "stateListData",
                        $$v
                      )
                    },
                    expression:
                      "events[selected_event].dataInstance[selected_template].stateListData",
                  },
                },
                [
                  _c("span", {
                    staticStyle: { display: "none" },
                    attrs: { slot: "noResult" },
                    slot: "noResult",
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { attrs: { slot: "noOptions" }, slot: "noOptions" },
                    [
                      _vm._v(
                        "\n            Нет доступных статусов\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(7),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.events.length > 0
      ? _c("div", { staticClass: "responder_head_margin" }, [
          _vm._m(8),
          _vm._v(" "),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value:
                    _vm.events[_vm.selected_event].dataInstance[
                      _vm.selected_template
                    ].recipient,
                  expression:
                    "events[selected_event].dataInstance[selected_template].recipient",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.phone_error != "" },
              attrs: {
                type: "text",
                name: "name",
                placeholder: "Можно ввести несколько получателей через запятую",
                id: "reciever_input",
              },
              domProps: {
                value:
                  _vm.events[_vm.selected_event].dataInstance[
                    _vm.selected_template
                  ].recipient,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.events[_vm.selected_event].dataInstance[
                      _vm.selected_template
                    ],
                    "recipient",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c(
                "span",
                { staticClass: "input_desc" },
                [
                  _vm.phone_error != ""
                    ? _c("div", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.phone_error)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    _vm.events[_vm.selected_event].recipientParams.fields,
                    function (rval, rkey) {
                      return _c("div", [
                        _c("b", [_vm._v(_vm._s(rval) + ":")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "crm_var",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.insertTextAtCursor(rkey)
                              },
                            },
                          },
                          [_vm._v(_vm._s(rkey))]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _vm._m(9),
                ],
                2
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.events.length > 0
      ? _c(
          "div",
          { staticClass: "responder_head_margin" },
          [
            _vm._m(10),
            _vm._v(" "),
            _c("crm-textarea", {
              key:
                _vm.events[_vm.selected_event].type +
                "." +
                _vm.events[_vm.selected_event].value +
                "." +
                _vm.selected_template,
              attrs: {
                placeholder: "Введите текст шаблона",
                uid:
                  _vm.events[_vm.selected_event].type +
                  "_" +
                  _vm.events[_vm.selected_event].value +
                  "_" +
                  _vm.selected_template,
                text: _vm.events[_vm.selected_event].dataInstance[
                  _vm.selected_template
                ].textData,
                text_variables: _vm.events[_vm.selected_event].textParams,
              },
              on: {
                "update:text": function ($event) {
                  return _vm.$set(
                    _vm.events[_vm.selected_event].dataInstance[
                      _vm.selected_template
                    ],
                    "textData",
                    $event
                  )
                },
                "update:text_variables": function ($event) {
                  return _vm.$set(
                    _vm.events[_vm.selected_event],
                    "textParams",
                    $event
                  )
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "responder_head_margin responder_submit_buttons" },
      [
        !_vm.moysklad_sending
          ? _c(
              "a",
              {
                staticClass: "resp_button_green",
                class: { disable: !_vm.moysklad_edited_content },
                on: { click: _vm.submit_button },
              },
              [_vm._v("Сохранить")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.moysklad_sending
          ? _c("a", { staticClass: "resp_button_green disable" }, [_vm._m(11)])
          : _vm._e(),
        _vm._v(" "),
        _vm.events.length > 0 &&
        _vm.events[_vm.selected_event].dataInstance.length > 1
          ? _c(
              "a",
              {
                staticClass: "resp_button_red ms-2",
                on: {
                  click: function ($event) {
                    return _vm.reset_button(_vm.selected_template)
                  },
                },
              },
              [_vm._v("Удалить шаблон")]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "wtpl_subtitle_2 d-flex align-items-center justify-content-center",
      },
      [_c("div", [_vm._v("ШАБЛОНЫ")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("span", [_c("b", [_vm._v("Событие")])])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v("Выберите событие для настройки его шаблона"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("span", [_c("b", [_vm._v("Статус шаблона")])])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("b", [_vm._v("Параметры события")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Выберите параметры, на изменение которых будет отправлено уведомление"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("b", [_vm._v("Статусы документа")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Выберите параметры, на изменение которых будет отправлено уведомление"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("b", [_vm._v("Получатель")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("b", [_vm._v("номер телефона:")]),
      _vm._v(" 79005551213"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("span", [_c("b", [_vm._v("Текст сообщения")])])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }