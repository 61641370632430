<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <div>
                  <h1 class="c-content">Чат-боты</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row g-0 g-sm-2 g-md-4">
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="mass_title mb16">Ваши чат-боты</h2>
                  <div v-for="responder in responders" class="responder mb24" :class="{'resp_active': responder.status}">
                    <div class="bot_icon">
                      <svg viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4776_471)">
                        <path d="M47.5001 56.4062C44.071 56.4091 40.6946 55.5623 37.6728 53.9414C34.6509 52.3206 32.0778 49.9762 30.1833 47.1179L35.1293 43.8303C36.4836 45.8716 38.3219 47.546 40.4804 48.7042C42.6389 49.8625 45.0504 50.4687 47.5001 50.4687C49.9497 50.4687 52.3613 49.8625 54.5198 48.7042C56.6782 47.546 58.5166 45.8716 59.8708 43.8303L64.8168 47.1179C62.9224 49.9762 60.3492 52.3206 57.3274 53.9414C54.3055 55.5623 50.9292 56.4091 47.5001 56.4062Z" fill="currentColor"/>
                        <path d="M59.375 23.75C58.2007 23.75 57.0527 24.0983 56.0763 24.7507C55.0999 25.4031 54.3389 26.3304 53.8895 27.4154C53.4401 28.5003 53.3225 29.6941 53.5516 30.8459C53.7807 31.9977 54.3462 33.0556 55.1766 33.886C56.0069 34.7164 57.0649 35.2819 58.2167 35.511C59.3684 35.7401 60.5623 35.6225 61.6472 35.1731C62.7321 34.7237 63.6594 33.9627 64.3119 32.9862C64.9643 32.0098 65.3125 30.8619 65.3125 29.6875C65.3202 28.9057 65.1719 28.1301 64.8762 27.4063C64.5806 26.6824 64.1435 26.0248 63.5906 25.4719C63.0377 24.9191 62.3801 24.482 61.6563 24.1863C60.9324 23.8907 60.1569 23.7423 59.375 23.75Z" fill="currentColor"/>
                        <path d="M35.625 23.75C34.4507 23.75 33.3027 24.0983 32.3263 24.7507C31.3499 25.4031 30.5889 26.3304 30.1395 27.4154C29.6901 28.5003 29.5725 29.6941 29.8016 30.8459C30.0307 31.9977 30.5962 33.0556 31.4266 33.886C32.2569 34.7164 33.3149 35.2819 34.4667 35.511C35.6184 35.7401 36.8122 35.6225 37.8972 35.1731C38.9821 34.7237 39.9094 33.9627 40.5619 32.9862C41.2143 32.0098 41.5625 30.8619 41.5625 29.6875C41.5702 28.9057 41.4219 28.1301 41.1262 27.4063C40.8306 26.6824 40.3935 26.0248 39.8406 25.4719C39.2877 24.9191 38.6301 24.482 37.9063 24.1863C37.1824 23.8907 36.4069 23.7423 35.625 23.75Z" fill="currentColor"/>
                        <path d="M52.6532 89.0625L47.5 86.0938L59.375 65.3125H77.1875C77.9676 65.3138 78.7403 65.1612 79.4613 64.8632C80.1822 64.5653 80.8373 64.128 81.3889 63.5764C81.9405 63.0248 82.3778 62.3697 82.6757 61.6488C82.9737 60.9278 83.1263 60.1551 83.125 59.375V17.8125C83.1263 17.0324 82.9737 16.2597 82.6757 15.5387C82.3778 14.8178 81.9405 14.1627 81.3889 13.6111C80.8373 13.0595 80.1822 12.6222 79.4613 12.3243C78.7403 12.0263 77.9676 11.8737 77.1875 11.875H17.8125C17.0324 11.8737 16.2597 12.0263 15.5387 12.3243C14.8178 12.6222 14.1627 13.0595 13.6111 13.6111C13.0595 14.1627 12.6222 14.8178 12.3243 15.5387C12.0263 16.2597 11.8737 17.0324 11.875 17.8125V59.375C11.8737 60.1551 12.0263 60.9278 12.3243 61.6488C12.6222 62.3697 13.0595 63.0248 13.6111 63.5764C14.1627 64.128 14.8178 64.5653 15.5387 64.8632C16.2597 65.1612 17.0324 65.3138 17.8125 65.3125H44.5312V71.25H17.8125C16.253 71.2503 14.7087 70.9433 13.2678 70.3466C11.8269 69.7499 10.5177 68.8753 9.415 67.7725C8.31225 66.6698 7.43755 65.3605 6.84088 63.9197C6.2442 62.4788 5.93723 60.9345 5.9375 59.375V17.8125C5.93703 16.2529 6.24387 14.7085 6.84048 13.2676C7.43709 11.8266 8.31177 10.5174 9.41456 9.41456C10.5174 8.31177 11.8266 7.43709 13.2676 6.84048C14.7085 6.24387 16.2529 5.93703 17.8125 5.9375H77.1875C78.7471 5.93703 80.2915 6.24387 81.7324 6.84048C83.1734 7.43709 84.4827 8.31177 85.5854 9.41456C86.6882 10.5174 87.5629 11.8266 88.1595 13.2676C88.7561 14.7085 89.063 16.2529 89.0625 17.8125V59.375C89.0628 60.9345 88.7558 62.4788 88.1591 63.9197C87.5624 65.3605 86.6878 66.6698 85.585 67.7725C84.4823 68.8753 83.173 69.7499 81.7322 70.3466C80.2913 70.9433 78.747 71.2503 77.1875 71.25H62.8324L52.6532 89.0625Z" fill="currentColor"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4776_471">
                        <rect width="95" height="95" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div class="bot_content">
                      <div class="bot_name">{{ responder.name }}</div>
                      <div class="bot_profile">Профиль: <a :href="'/dashboard/' + responder.profile.uuid">{{ responder.profile.name }}</a></div>
                      <div>
                        <a class="bot_green mr24" :href="'/responder/' + responder.id">Редактировать</a>
                        <a class="bot_red" @click="bot_delete(responder.id, responder.profile.uuid)">Удалить</a>
                      </div>
                    </div>
                    <div class="resp_status">
                      <div v-if="responder.status" class="bot_mini_text d-inline-block initial active">
                        ВКЛЮЧЕН</div>
                      <div v-if="!responder.status" class="bot_mini_text d-inline-block initial">
                        ВЫКЛЮЧЕН</div>
                      <switch-toggle :responder="responder" :uid="responder.id" :model.sync="responder.status"
                        @toggler="toggleStatus"></switch-toggle>
                    </div>
                  </div>
                  <a href="/responder/new" class="responder_add">
                    <svg class="mr16" width="20" height="24" viewBox="0 0 20 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <g id="plus-solid 1">
                      <path id="Vector" d="M18.5714 9.75H12.1429V3C12.1429 2.17172 11.5031 1.5 10.7143 1.5H9.28571C8.49688 1.5 7.85714 2.17172 7.85714 3V9.75H1.42857C0.639732 9.75 0 10.4217 0 11.25V12.75C0 13.5783 0.639732 14.25 1.42857 14.25H7.85714V21C7.85714 21.8283 8.49688 22.5 9.28571 22.5H10.7143C11.5031 22.5 12.1429 21.8283 12.1429 21V14.25H18.5714C19.3603 14.25 20 13.5783 20 12.75V11.25C20 10.4217 19.3603 9.75 18.5714 9.75Z" fill="currentColor"/>
                    </g>
                    </svg>
                    <span>Добавить чат-бот</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card card_desc">
                <div class="card-body">
                  <h2 class="mass_title mb-3">Чат-бот - как это работает?</h2>
                  <div class="mb-4">
                    <p>Когда клиент обращается к вам в WhatsApp, чат-бот сразу реагирует на его сообщения в зависимости от ситуации. </p>
                    <p>Например, если это новый клиент, бот может отправить <b class="c-content">приветственное сообщение</b>, а в случае, если вы временно недоступны, бот уведомит, что вас <b class="c-content">нет на месте</b>. Кроме того, предусмотрена возможность использования <b class="c-content">интерактивного меню</b>, где клиент может вводить символы или слова, и получать соответствующие ответы от бота. Вы можете <b class="c-content">создать чат-бота</b>, который будет реагировать на определенные ключевые слова или фразы, которые будут служить триггерами для активации соответствующих ответов.</p>
                    <p>Для эффективной работы можно комбинировать несколько ботов, объединив их в умного Whatsapp помощника.</p>
                    <p>Создайте свое чат-бота или попробуйте готовые шаблоны ботов:</p>
                  </div>
                  <div class="mb-3 text-center mh3">Интерактивное меню</div>
                  <div class="mb-4">
                      <img src="/static/images/q2.png" class="img-fluid" />
                  </div>
                  <div class="mb-4">
                    <p>Клиент вводит символы или слова и получает соответствующие ответы от бота. Конструируйте свое собственное меню, добавляйте пункты. Вы можете настроить чат-бота на реагирование на определенные ключевые слова или фразы, которые будут служить триггерами для активации соответствующих ответов.</p>
                  </div>
                  <div class="mb-3 text-center mh3">Автоответчик</div>
                  <div class="mb-4">
                    <p>Простой автоответчик, можно настроить интервал повторного срабатывания, расписание.</p>
                  </div>
                  <div class="mb-3 text-center mh3">Приветствие новых клиентов</div>
                  <div class="mb-4">
                    <p>Шаблон для отправки приветственных сообщений исключительно новым клиентам.</p>
                  </div>
                  <div class="mb-3 text-center mh3">Нет на месте</div>
                  <div class="">
                    <p>Если вас нет на месте - настройте расписание и отправляйте сообщения о нерабочем времени.</p>
                  </div>
                  <div class="mb-3 text-center mh3">Пропущенный звонок</div>
                  <div class="">
                    <p>Отправляйте клиенту автоматическое сообщение, если не смогли ответить на звонок.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer></Footer>
      </div>
      <!-- 
        <div v-for="responder in responders" class="responder mb24" :class="{'resp_active': responder.status}">
          <div class="mb24">
            <span class="resp_title mr24">{{ responder.name }}</span>
            <span class="resp_profile"><a :href="'/dashboard/' + responder.profile.uuid">{{ responder.profile.name }}</a></span>
          </div>
          <div>
            <a class="resp_button_green mr24" :href="'/responder/' + responder.id">Редактировать</a>
            <a class="resp_button_grey" :href="'/responder/delete/' + responder.id">Удалить</a>
          </div>
          <div v-if="responder.status" class="resp_status active">ВКЛ<span>ЮЧЕН</span></div>
          <div v-if="!responder.status" class="resp_status">ВЫКЛ<span>ЮЧЕН</span></div>
        </div>
       -->
    </main>
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import Footer from "./Components/Footer.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';
import SwitchToggle from './Components/SwitchToggle.vue';

export default {
  data() {
    return {
        
    };
  },
  methods: {
    toggleStatus(responder) {      
      this.$axios.post(
          "https://" +
          window.location.host +
          "/bot/disable?id=" + responder.id + "&profile_id=" + responder.profile.uuid, { status: responder.status },
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        ).then(response => {
          if (response.data.status === 'done') {
            this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: response.data.detail,
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }).catch(error => {
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: error.response.data.detail,
              timerProgressBar: true,
              showConfirmButton: false,
              width: 400,
              timer: 2000,
            });
        });
    },
    bot_delete(id, profile_id) {
      this.$swal
        .fire({
          title: "Вы уверены?",
          text: "Все данные будут безвозвратно удалены!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Не удалять",
          confirmButtonColor: "#d33",
          cancelButtonColor: "#23BB86",
          confirmButtonText: "Удалить",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios.post(
                "https://" +
                window.location.host +
                "/bot/delete?id=" + id + "&profile_id=" + profile_id, {},
                {
                  headers: {
                    Authorization: this.$store.state.token,
                  },
                }
              ).then(response => {
                if (response.data.status === 'done') {
                  let index = this.responders.findIndex(responders => responders.id === id);
                  this.responders.splice(index, 1);
                  this.$swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "Бот удален",
                    timerProgressBar: true,
                    showConfirmButton: false,
                    timer: 3000,
                  });
                }
              }).catch(error => {
                this.$swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: error.response.data.detail,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    width: 400,
                    timer: 2000,
                  });
              });
            }
        });
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          } 
          
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            this.$swal.close();
          }
          // if (data.data === 'open') {
          //   let restore_on_open = document.querySelectorAll('.restore_on_open');
          //   restore_on_open.forEach(res => {
          //     res.classList.remove('disabled')
          //   })
          // }
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  props: ["token", "user", "responders"],
  watch: {
    countAllProfiles(newCount, oldCount) {
      this.helloModal();
    },
  },
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted(){
    const sidebar = document.querySelector(".sidebar");
    if(window.screen.width < 768){
      sidebar.classList.add("sidebar-mini");
    }
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  components: {
    Sidebar,
    Topbar,
    SwitchToggle,
    Footer
  },
};
</script>
