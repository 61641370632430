<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <h1 class="c-content">История сообщений</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row">
            <div class="col-sm -lg-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="h4 mb-3">{{ profile.name }}</h4>

                  <div class="row align-items-center mb-3">
                    <div class="col-12 col-xl-6">
                      <div style="display: flex; align-items: center;">
                        <inertia-link :href="route('dashboard.instance', profile.uuid)" style="line-height: 12px;">
                          <svg style="width:10px;margin-bottom: 2px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" fill="currentColor"/></svg> Назад
                        </inertia-link>
                      </div>
                    </div>
                    <div class="col-12 col-xl-6 d-flex justify-content-end" style="align-items: center;">
                      <div>
                        <a @click="downloadCsv" href="#" style="display: flex;align-items: center;justify-content: center;margin-right: 24px;">
                          <svg style="width: 16px; margin-right: 4px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="#FFF" stroke-miterlimit="10" stroke-width="2">
                            <path stroke="#979593" d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z"/>
                            <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18"/>
                            <path fill="#C8C6C4" d="M51 61H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 60.5523 51.5523 61 51 61zM51 55H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 54.5523 51.5523 55 51 55zM51 49H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 48.5523 51.5523 49 51 49zM51 43H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 42.5523 51.5523 43 51 43zM51 67H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 66.5523 51.5523 67 51 67zM79 61H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 67H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67zM79 55H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM65 61H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 60.5523 65.5523 61 65 61zM65 67H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 66.5523 65.5523 67 65 67zM65 55H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 54.5523 65.5523 55 65 55zM65 49H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 48.5523 65.5523 49 65 49zM65 43H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 42.5523 65.5523 43 65 43z"/>
                            <path fill="#107C41" d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z"/>
                            <path d="M16.9492,66l7.8848-12.0337L17.6123,42h5.8115l3.9424,7.6486c0.3623,0.7252,0.6113,1.2668,0.7471,1.6236 h0.0508c0.2617-0.58,0.5332-1.1436,0.8164-1.69L33.1943,42h5.335l-7.4082,11.9L38.7168,66H33.041l-4.5537-8.4017 c-0.1924-0.3116-0.374-0.6858-0.5439-1.1215H27.876c-0.0791,0.2684-0.2549,0.631-0.5264,1.0878L22.6592,66H16.9492z"/>
                          </svg>
                          Скачать xlsx
                        </a>
                      </div>
                      <form autocomplete="off" @submit.prevent="" class="form100">
                        <input class="form-control" name="sidebar_search" placeholder="Поиск по телефону" autocomplete="off"
                          v-model="search_name" />
                      </form>
                    </div>
                  </div>
                  <div class="table-responsive mt-2">
                    <table class="table table-hover table-bordered" v-if="filterMessages.length > 0">
                      <tbody>
                        <tr class="thead text-center text-uppercase table-secondary c-title">
                          <th scope="col" class="tw1">Дата</th>
                          <th scope="col" class="tw2">Получатель</th>
                          <th scope="col" class="tw1">Тип</th>
                          <th scope="col">Сообщение</th>
                          <th scope="col" class="tw3">Статус</th>
                        </tr>
                        <tr v-for="fp in filterMessages">
                          <td class="text-center">{{ fp.created_at | wtz }}</td>
                          <td class="text-center">{{ fp.recipient | cus }}</td>
                          <td class="text-center">{{ fp.task_name | rs }}</td>
                          <td class="text-wrap">
                            <span v-if="fp.message !== null && fp.message.length > 0">{{ fp.message }}</span>
                            <span v-if="fp.caption !== null && fp.caption.length > 0">{{ fp.caption }}</span>
                          </td>
                          <td class="text-center">
                            <span class="c-badge green" v-if="fp !== null && fp.delivered === 'success'">отправлено</span>
                            <span class="c-badge red" v-if="fp !== null && fp.delivered === 'failed'">ошибка</span>
                            <span class="c-badge gray" v-if="fp !== null && fp.delivered !== 'success' && fp.delivered !== 'failed'">не отправлено</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="filterMessages.length === 0" style="color: #6c757d" class="mb-4">Сообщений нет</div>
                  </div>

                  <v-pagination v-if="all_pages > 0" v-model="now_page" :page-count="all_pages" :classes="bootstrapPaginationClasses"></v-pagination>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import Footer from './Components/Footer.vue';
// import _ from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';
import moment from "moment";
import vPagination from 'vue-plain-pagination'

export default {
  data() {
    return {
      filtered: [],
      messages_on_page: 100,
      all_pages: 1,
      now_page: 1,
      search_name: "",
      order: 'desc',
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
    };
  },
  methods: {
    downloadCsv() {
      this.$axios
      .get(
        "https://" +
        window.location.host +
        "/api/xls/download?profile_id=" + this.profile.uuid,
        {
          headers: {
            Authorization: user_token,
          },
          responseType: 'blob',
        }
      )
      .then(response  => {
        let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'messages_'+ this.mass_posting_id +'.xlsx');
        document.body.appendChild(link);
        link.click();
      });
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          } 
          
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            this.$swal.close();
          }
          // if (data.data === 'open') {
          //   let restore_on_open = document.querySelectorAll('.restore_on_open');
          //   restore_on_open.forEach(res => {
          //     res.classList.remove('disabled')
          //   })
          // }
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  props: ["user", "all_profiles", "profile", "token", "messages", "all_containers"],
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted() {
    let host;
    if(this.profile.platform == "tg") {
      host = "/tapi/sync/messages/history";
    } else {
      host = "/api/sync/messages/history";
    }
    this.$axios.get(host + "?profile_id=" + this.profile.uuid + "&limit="+ this.messages_on_page +"&offset=0",
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((res) => {
        let data = res.data;
        if (data.status == "done") {
          if (data.all_messages_count > 0) {
            this.all_pages = Math.ceil(data.all_messages_count / this.messages_on_page);
            data.detail.forEach(msg => {
              this.filtered.push(msg);
            })
          } 
        } 
      })

    const sidebar = document.querySelector(".sidebar");
    if (window.screen.width < 768) {
      sidebar.classList.add("sidebar-mini");
    }

    // console.log(this.user);
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  filters: {
    moment: function (date) {
      return moment.unix(date).format("LLL");
    },
    short: function (date) {
      return moment(date).format("L");
    },
    cus: function (jid) {
      return jid.replace("@c.us", "")
    },
    wtz: function (date) {
      console.log(date);
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    rs: function (name) {
      return name.replace('s_', '');
    },
  },
  computed: {
    filterMessages: {
      get() {
        return this.filtered.filter(message => message.recipient.toLowerCase().indexOf(this.search_name.toLowerCase()) >= 0);
      }
    },

  },
  watch: {
    now_page: {
       handler(val){
         if (parseInt(val) > 0) {
            let offset = (val * this.messages_on_page) - this.messages_on_page;
            let host;
            if(this.profile.platform == "tg") {
              host = "/tapi/sync/messages/history";
            } else {
              host = "/api/sync/messages/history";
            }
            this.$axios.get(host + "?profile_id=" + this.profile.uuid + "&limit=" + this.messages_on_page + "&offset=" + offset,
            {
              headers: {
                Authorization: this.token,
              },
            }
          )
          .then((res) => {
            let data = res.data;
            if (data.status == "done") {
              if (data.all_messages_count > 0) {
                this.filtered = [];
                data.detail.forEach(msg => {
                  this.filtered.push(msg);
                })
                window.scrollTo(0, 0);
              } 
            } 
          })
          
         }
       },
    }
  },
  components: {
    Sidebar,
    Topbar,
    vPagination,
    Footer
  },
};
</script>
