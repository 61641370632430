<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col -md-12">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <div>
                  <h1>Генератор промокодов</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
              <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-12 text-center">
                            <h3 class="c-content">Создание промокода</h3>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <div class="mt-2 text-center">
                                <label class="c-title" for="amount_use">Количество активаций</label>
                                <input class="form-control" id="amount_use" type="text" v-model="amount_use">
                            </div>
                            <div class="mt-2 text-center">
                                <label class="c-title" for="amount_use">Когда истекает</label>
                                <input  class="form-control" placeholder="YYYY-mm-dd" type="text" v-model="expired_at">
                            </div>
                            <div class="mt-2 text-center">
                                    <label class="c-title" for="amount_use">Скидка в рублях</label>
                                    <input  class="form-control" type="text" v-model="discount">
                            </div>
                            <div class="mt-2 text-center">
                                <label class="c-title" for="amount_use">Выбери тарифы</label>
                                <select class="form-select" v-model="tariff_ids" multiple>
                                    <option value="1">1 - 700</option>
                                    <option value="2">2 - 1800</option>
                                    <option value="3">3 - 3300</option>
                                    <option value="4">4 - 6000</option>
                                </select>
                            </div>
                            <div class="mt-2 d-flex justify-content-center">
                                    <button class="btn btn-success mt-2" @click="sendGenerate">Сгенерировать</button>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-6 col-sm-12 col-xs-12">
                            <div class="d-flex mt-2 display-4 h-100 align-items-center justify-content-center">
                                <div @click="copyPromo" v-if="new_promo != undefined" class="alert alert-success pointer" role="alert">{{new_promo}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="card w-100">
                    <div class="card-body ">
                        <div class="col-12 mt-4 text-center">
                            <h3 class="c-content">Список промокодов</h3>
                        </div>
                        <table class="table table-striped">
                            <thead>
                                <tr mt-4>
                                    <th scope="col">Промокод</th>
                                    <th scope="col">Количество</th>
                                    <th scope="col">Истекает</th>
                                    <th scope="col">Использовано</th>
                                    <th scope="col">Скидка</th>
                                    <th scope="col">Тарифы</th>
                                    <th scope="col">Создал</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="promo in all_promos" :key="promo.id">
                                    <td>{{ promo.code }}</td>
                                    <td>{{promo.amount_use}}</td>
                                    <td>{{promo.expired_at}}</td>
                                    <td>{{promo.uses}}</td>
                                    <td>{{promo.discount}}</td>
                                    <td><p v-for="(tarif,index) in promo.tariff_ids" :key="index">{{tarif}}</p></td>
                                    <td>{{promo.user_gen.email}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
      <div
        class="modal fade"
        id="accountpref-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div
              class="
                modal-head
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <h5 class="modal-title" id="accountpref-modal-label">
                Настройки аккаунта
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                    fill="#6C757D"
                  />
                </svg>
              </button>
            </div>
            <div class="content-text mt-6 text-center p-33">
              Здесь будут настройки какие нибудь
            </div>
          </div>
        </div>
      </div>
			</div>
      <!-- Modal Account Preferences end -->
    </main>
  </div>
</template>
<script>
import Sidebar from "./Sidebar.vue";
import Topbar from "./Topbar.vue";

export default {
  data() {
    return {
        amount_use: "",
        expired_at: "",
        discount: "",
        tariff_ids: [],
				promocode: "",
    };
  },
  methods: {
		copyPromo(){
			navigator.clipboard.writeText(this.new_promo);
			this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Промокод был скопирован",
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 2000,
      });
		},
    sendGenerate(){
			this.$inertia.post("https://" + window.location.host + "/genpromo",
				{
					amount_use: this.amount_use,
					expired_at: this.expired_at,
					discount: this.discount,
					tariff_ids: this.tariff_ids,
					user: this.$store.state.user.id
				}
			)},
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
      let brand = document.querySelector(".navbar-brand");
      let picture = brand.querySelector("picture");
      if (picture) {
        brand.innerHTML =
          '<img src="/static/images/favicon-wappi.png" alt="Wappi Logo" class="mini-logo">';
      } else {
        setTimeout(() => {
          brand.innerHTML =
            '<picture><source srcset="/static/index/img/logo.webp" type="image/webp"><img src="/static/index/img/logo.png" alt="Wappi Logo" width="125"></picture>';
        }, 200);
      }
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws/test/?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.type === "event") {
          console.log(data)
        }
        if (data.type === "update_qr_code") {
          if (typeof this.$store.state.current_profile !== 'undefined' && data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.type === "update_auth") {
          this.$store.commit("set_status", { auth: data.data, profile_id: data.profile_id });
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.type === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.type === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'open') {
            let restore_on_open = document.querySelectorAll('.restore_on_open');
            restore_on_open.forEach(res => {
              res.classList.remove('disabled')
            })
          }
        }
      };
    },
  },
  props: ["new_promo","all_promos","profile", "token", "all_profiles", "user", "payments", "tariffs"],
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted() {
    const sidebar = document.querySelector(".sidebar");
      if(window.screen.width < 768){
        sidebar.classList.add("sidebar-mini");
      }
      let Scrollbar;
      if ($(".data-scrollbar").length) {

        let scr = document.querySelector('.data-scrollbar');

        Scrollbar = window.Scrollbar;
        let scrollbar = Scrollbar.init(scr, {
          continuousScrolling: true,
        });


        window.addEventListener("wheel", event => {
          if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
            localStorage['transform'] = scrollbar.scrollTop;
            // console.log(localStorage['transform']);
          }
        });

        if (localStorage['transform']) {
          // console.log(localStorage['transform']);
          scrollbar.scrollTop = localStorage['transform'];
        }

      }
  },
  computed: {},
  components: {
    Sidebar,
    Topbar,
  },
};
</script>
