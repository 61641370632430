var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "switch-toggle" }, [
    _c("input", {
      staticClass: "d-none",
      attrs: { type: "checkbox", id: "switch_" + _vm.uid },
    }),
    _vm._v(" "),
    _c(
      "label",
      { attrs: { for: "switch_" + _vm.uid }, on: { click: _vm.toggle } },
      [_c("div", { staticClass: "knob" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }