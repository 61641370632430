<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <div>
                  <h1 class="c-content">Поддержка</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5" :style="this.$store.state.user.moysklad ? 'min-height:initial !important' : ''">
          <!--      account block begin-->
          <div class="row g-0 g-sm-2 g-md-4">
            <!-- <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="card">
                <div class="card-body">
                    <h4>Форма обратной связи</h4>
                    <div class="contacts__text mt-4">Любые вопросы вы можете задать через эту форму. </div>
                    <div class="contacts__text">Ответ придет на ваш email. Поддержка отвечает в максимально короткие сроки.</div>
                    <div class="form-group">
                        <div class="col-md-12 mt-4">
                            <input id="name" name="name" placeholder="Ваше имя" type="text" v-model="name" class="form-control">
                        </div>
                    </div>
                    <div class="form-group mt-4">
                        <div class="col-md-12 ">
                            <input id="email" name="email" placeholder="Ваше адрес Email" type="text" v-model="email"  class="form-control">
                        </div>
                    </div>
                    
                    <div class="form-group mt-2">
                        <div class="col-md-12 mt-4">
                            <textarea class="form-control" id="message" placeholder="Ваше сообщение" v-model="message" name="message" rows="5"></textarea>
                        </div>
                    </div>
                                
                      <div class="error">{{error}}</div>
                          <div class="col-md-12 text-center contacts__button">
                              <button @click="sendMessage" class="btn btn-billing">Отправить</button>
                      </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-md-12">
                    <div class="contacts__info">
                        <h4>Контакты</h4>
                        <div class="contacts__text mt-4">
                            Вы можете связаться с нами любым удобным вам способом.
                        </div>
                        <ul class="contacts__list mt-4">
                            <li class="contacts__list-item">
                                <a href="mailto:support@wappi.pro" class="contacts__list-link d-flex align-items-center">
                                    <img style="width: 30px;" src="/static/index/img/icons/mail-icon.svg" aria-hidden="true" alt="email">
                                    <div class="support__contact">
                                         <span class="contact_name">E-mail:</span> support@wappi.pro
                                    </div>
                                </a>
                            </li>
                           <li class="contacts__list-item">
                                <a href="https://t.me/wappi_support" class="contacts__list-link d-flex align-items-center" target="_blank">
                                    <img style="width: 30px;" src="/static/index/img/icons/tg-icon.svg" aria-hidden="true" alt="telegram">
                                    <div class="support__contact">
                                        <span class="contact_name">Telegram:</span> @wappi_support
                                    </div>
                                </a>
                            </li>
                           <!--  <li class="contacts__list-item">
                                <a href="https://wa.me/79218221212" class="contacts__list-link d-flex flex-wrap align-items-center" target="_blank">
                                    <img style="width: 30px;" src="/static/index/img/icons/whatsapp-icon.svg" aria-hidden="true" alt="whatsapp">
                                    <div class="support__contact">
                                         <span class="contact_name">Пишите Whatsapp:</span>
                                    </div>
                                    <div class="support__contact">+7 921 822 12 12</div>
                                </a>
                            </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer v-if="!this.$store.state.user.moysklad"></Footer>
      </div>
      <!-- Modal Account Preferences -->
      <div
        class="modal fade"
        id="accountpref-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div
              class="
                modal-head
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <h5 class="modal-title" id="accountpref-modal-label">
                Настройки аккаунта
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                    fill="#6C757D"
                  />
                </svg>
              </button>
            </div>
            <div class="content-text mt-6 text-center p-33">
              Здесь будут настройки какие нибудь
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Account Preferences end -->
    </main>
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import Footer from "./Components/Footer.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';

export default {
  data() {
    return {
        error:'',
        name: '',
        email: '',
        message:''
    };
  },
  methods: {
      sendMessage(){
          if(this.name && this.email && this.message){
            this.$axios.post(this.route('dashboard.support'),
            {
                name: this.name, email: this.email, message: this.message, user: this.$store.state.user
            }
        ).then(res => {
            if (res.data.status == true){
                this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Ваше сообщение доставлено",
                    showConfirmButton: false,
                    timer: 1500,
                    });
                this.name = '',
                this.email = '',
                this.message = ''
            }
        });
          }else if (this.name == ''){
              this.error="Имя не заполнено"
          }else if (this.email == ''){
              this.error="Почта не заполнена"
          }else if (this.message == ''){
              this.error="Сообщение не заполнено"
          }
      },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          } 
          
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            this.$swal.close();
          }
          // if (data.data === 'open') {
          //   let restore_on_open = document.querySelectorAll('.restore_on_open');
          //   restore_on_open.forEach(res => {
          //     res.classList.remove('disabled')
          //   })
          // }
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  props: ["profile", "token", "all_profiles", "all_containers", "user", "payments", "tariffs"],
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted(){
    const sidebar = document.querySelector(".sidebar");
    if(window.screen.width < 768){
      sidebar.classList.add("sidebar-mini");
    }
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });


      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  computed: {},
  components: {
    Sidebar,
    Topbar,
    Footer
  },
};
</script>
