var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "nav navbar navbar-expand-lg navbar-light iq-navbar" },
    [
      _c("div", { staticClass: "container-fluid navbar-inner" }, [
        this.$store.state.amo_mode
          ? _c(
              "a",
              { staticClass: "navbar-brand", attrs: { href: "/dashboard" } },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        !this.$store.state.amo_mode
          ? _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } }, [
              _vm._m(1),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "sidebar-toggle mini",
            attrs: { "data-toggle": "sidebar", "data-active": "true" },
            on: { click: _vm.side_toggle },
          },
          [
            _c("i", { staticClass: "icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "24px",
                    height: "24px",
                    viewBox: "0 0 24 24",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d: "M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d: "M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      fill: "currentColor",
                      d: "M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z",
                    },
                  }),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "nav-link py-0 d-flex d-sm-flex d-md-flex d-lg-none align-items-center",
            attrs: {
              href: "#",
              id: "navbarDropdown_mob",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false",
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "32",
                  height: "32",
                  viewBox: "0 0 32 32",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M16 6.5C12.5806 6.5 9.80645 9.1875 9.80645 12.5C9.80645 15.8125 12.5806 18.5 16 18.5C19.4194 18.5 22.1935 15.8125 22.1935 12.5C22.1935 9.1875 19.4194 6.5 16 6.5ZM16 15.5C14.2903 15.5 12.9032 14.1562 12.9032 12.5C12.9032 10.8438 14.2903 9.5 16 9.5C17.7097 9.5 19.0968 10.8438 19.0968 12.5C19.0968 14.1562 17.7097 15.5 16 15.5ZM16 0.5C7.16129 0.5 0 7.4375 0 16C0 24.5625 7.16129 31.5 16 31.5C24.8387 31.5 32 24.5625 32 16C32 7.4375 24.8387 0.5 16 0.5ZM16 28.5C12.7935 28.5 9.86452 27.3563 7.60645 25.475C8.56774 24.0375 10.2129 23.0625 12.0968 23.0063C13.4387 23.4062 14.7161 23.6063 16 23.6063C17.2839 23.6063 18.5613 23.4125 19.9032 23.0063C21.7871 23.0688 23.4323 24.0375 24.3935 25.475C22.1355 27.3563 19.2065 28.5 16 28.5ZM26.4968 23.2437C24.9226 21.2812 22.4903 20 19.7161 20C19.0581 20 18.0387 20.6 16 20.6C13.9677 20.6 12.9419 20 12.2839 20C9.51613 20 7.08387 21.2812 5.50323 23.2437C3.99355 21.2 3.09677 18.7 3.09677 16C3.09677 9.10625 8.88387 3.5 16 3.5C23.1161 3.5 28.9032 9.10625 28.9032 16C28.9032 18.7 28.0065 21.2 26.4968 23.2437Z",
                    fill: "#23BB86",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "ms-2 mb-0 caption-sub-title d-none d-sm-inline" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(this.$store.state.user.email) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "ms-2 d-none d-sm-inline" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "12",
                    height: "12",
                    viewBox: "0 0 12 8",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M6 5.11771L1.70711 0.824815C1.31658 0.43429 0.683417 0.43429 0.292893 0.824815C-0.0976305 1.21534 -0.0976305 1.8485 0.292893 2.23903L5.29289 7.23903C5.68342 7.62955 6.31658 7.62955 6.70711 7.23903L11.7071 2.23903C12.0976 1.8485 12.0976 1.21534 11.7071 0.824815C11.3166 0.43429 10.6834 0.43429 10.2929 0.824815L6 5.11771Z",
                      fill: "#6c757d",
                    },
                  }),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        !this.$store.state.user.moysklad
          ? _c(
              "ul",
              {
                staticClass: "dropdown-menu dropdown-menu-end",
                attrs: { "aria-labelledby": "navbarDropdown_mob" },
              },
              [_vm._m(2)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarSupportedContent" },
          },
          [
            _c(
              "ul",
              { staticClass: "navbar-nav ms-auto navbar-list mb-2 mb-lg-0" },
              [
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link py-0 d-flex align-items-center",
                      attrs: {
                        href: "#",
                        id: "navbarDropdown",
                        role: "button",
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "32",
                            height: "32",
                            viewBox: "0 0 32 32",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M16 6.5C12.5806 6.5 9.80645 9.1875 9.80645 12.5C9.80645 15.8125 12.5806 18.5 16 18.5C19.4194 18.5 22.1935 15.8125 22.1935 12.5C22.1935 9.1875 19.4194 6.5 16 6.5ZM16 15.5C14.2903 15.5 12.9032 14.1562 12.9032 12.5C12.9032 10.8438 14.2903 9.5 16 9.5C17.7097 9.5 19.0968 10.8438 19.0968 12.5C19.0968 14.1562 17.7097 15.5 16 15.5ZM16 0.5C7.16129 0.5 0 7.4375 0 16C0 24.5625 7.16129 31.5 16 31.5C24.8387 31.5 32 24.5625 32 16C32 7.4375 24.8387 0.5 16 0.5ZM16 28.5C12.7935 28.5 9.86452 27.3563 7.60645 25.475C8.56774 24.0375 10.2129 23.0625 12.0968 23.0063C13.4387 23.4062 14.7161 23.6063 16 23.6063C17.2839 23.6063 18.5613 23.4125 19.9032 23.0063C21.7871 23.0688 23.4323 24.0375 24.3935 25.475C22.1355 27.3563 19.2065 28.5 16 28.5ZM26.4968 23.2437C24.9226 21.2812 22.4903 20 19.7161 20C19.0581 20 18.0387 20.6 16 20.6C13.9677 20.6 12.9419 20 12.2839 20C9.51613 20 7.08387 21.2812 5.50323 23.2437C3.99355 21.2 3.09677 18.7 3.09677 16C3.09677 9.10625 8.88387 3.5 16 3.5C23.1161 3.5 28.9032 9.10625 28.9032 16C28.9032 18.7 28.0065 21.2 26.4968 23.2437Z",
                              fill: "#23BB86",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "caption ms-3 d-flex align-items-center",
                        },
                        [
                          _c("p", { staticClass: "mb-0 caption-sub-title" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(this.$store.state.user.email) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          !this.$store.state.user.moysklad
                            ? _c("span", { staticClass: "ms-2" }, [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "12",
                                      height: "12",
                                      viewBox: "0 0 12 8",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "fill-rule": "evenodd",
                                        "clip-rule": "evenodd",
                                        d: "M6 5.11771L1.70711 0.824815C1.31658 0.43429 0.683417 0.43429 0.292893 0.824815C-0.0976305 1.21534 -0.0976305 1.8485 0.292893 2.23903L5.29289 7.23903C5.68342 7.62955 6.31658 7.62955 6.70711 7.23903L11.7071 2.23903C12.0976 1.8485 12.0976 1.21534 11.7071 0.824815C11.3166 0.43429 10.6834 0.43429 10.2929 0.824815L6 5.11771Z",
                                        fill: "currentColor",
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !this.$store.state.user.moysklad
                    ? _c(
                        "ul",
                        {
                          staticClass: "dropdown-menu dropdown-menu-end",
                          attrs: { "aria-labelledby": "navbarDropdown" },
                        },
                        [
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  id: "user_logout",
                                  href: this.$store.state.amo_mode
                                    ? "/users/logout?embedded=true"
                                    : "/users/logout",
                                },
                              },
                              [_vm._v("Выход")]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: { srcset: "/static/index/img/logo.webp", type: "image/webp" },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/static/index/img/logo.png",
          alt: "Wappi Logo",
          width: "100",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: { srcset: "/static/index/img/logo.webp", type: "image/webp" },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/static/index/img/logo.png",
          alt: "Wappi Logo",
          width: "100",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        { staticClass: "dropdown-item", attrs: { href: "/users/logout" } },
        [_vm._v("Выход")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }