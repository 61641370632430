import { App, plugin } from "@inertiajs/inertia-vue";
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import PortalVue from "portal-vue";
import { InertiaProgress } from "@inertiajs/progress/src";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import NProgress from 'nprogress'
import { Inertia } from '@inertiajs/inertia'

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.component('multiselect', Multiselect);

Vue.prototype.moment = moment;
moment.locale("ru");
Vue.use(VueSweetalert2);

Vue.use(Vuex);
Vue.prototype.$axios = axios;

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

Vue.config.productionTip = false;

Vue.use(plugin);
Vue.use(PortalVue);

let customRoute = (...args) => {
  args[0] = "wapi:" + args[0];
  return window.reverseUrl(...args);
};
Vue.mixin({ methods: { route: customRoute } });

InertiaProgress.init({
  delay: 250,
  color: '#58caa3',
  includeCSS: true,
  showSpinner: true,
});

Inertia.on('start', () => {
  NProgress.start();
  document.querySelector('#body_overflow').classList.add('show')
});
Inertia.on('finish', () => {
  NProgress.done();
  document.querySelector('#body_overflow').classList.remove('show');
  document.querySelector('.spinner-icon').style.display = 'none';
});

const app = document.getElementById("app");
// we are getting the initialPage from a rendered json_script
const page = JSON.parse(document.getElementById("page").textContent);

import Index from "./vue/Index.vue";
import Support from "./vue/Support.vue";
import Partner from "./vue/Partner.vue";
import GenSdk from "./vue/GenSdk.vue";
import Admin from "./vue/Components/Admin.vue";
import UsersDash from "./vue/UsersDash.vue";
import MessagesHistory from "./vue/MessagesHistory.vue";
import MassMessagesHistory from "./vue/MassMessagesHistory.vue";
import SpamMessagesHistory from "./vue/SpamMessagesHistory.vue";
import MassPosting from "./vue/MassPosting.vue";
import Spam from "./vue/Spam.vue";
import GPTList from "./vue/GPTList.vue";
import GPTNew from "./vue/GPTNew.vue";
import GPTDetail from "./vue/GPTDetail.vue";
import ResponderList from "./vue/ResponderList.vue";
import ResponderNew from "./vue/ResponderNew.vue";
import ResponderDetail from "./vue/ResponderDetail.vue";
import WabaNew from "./vue/WabaNew.vue";
import WabaList from "./vue/WabaList.vue";
import WabaDetail from "./vue/WabaDetail.vue";
import CRMPage from "./vue/CRMPage.vue";
import CascadesList from "./vue/CascadesList.vue";
import CascadeNew from "./vue/CascadeNew.vue";
import CascadeDetail from "./vue/CascadeDetail.vue";

const pages = {
  Index: Index,
  Support,
  GenSdk,
  Admin,
  UsersDash,
  MessagesHistory,
  MassMessagesHistory,
  MassPosting,
  SpamMessagesHistory,
  Spam,
  ResponderList,
  ResponderNew,
  ResponderDetail,
  Partner,
  WabaNew,
  WabaList,
  WabaDetail,
  CRMPage,
  GPTList,
  GPTNew,
  GPTDetail,
  CascadesList,
  CascadeNew,
  CascadeDetail,
};

const store = new Vuex.Store({
  state: {
    tariffs: {},
    payments: {},
    user: {},
    ws_connection: Boolean,
    wa_status: "",
    current_profile: {},
    token: "",
    profile: {},
    all_wa_profiles: [],
    all_wa_containers: [],
    all_avito_containers: [],
    all_tg_profiles: [],
    all_avito_profiles: [],
    all_tg_containers: [],
    today_tasks_count: 0,
    pending_tasks_count: 0,
    amo_mode: false,
    amo_id: '',
    bitrix_mode: false,
  },
  getters: {
    DATA: (state) => {
      return {
        token: state.token,
        profile: state.profile,
      };
    },
  },
  mutations: {
    set_counts(state, payload) {
      if (payload.today !== null) {
        state.today_tasks_count = payload.today;
      }
      if (payload.pending !== null) {
        state.pending_tasks_count = payload.pending;
      }
    },
    set_amocrm(state, payload) {
      state.amo_mode = true;
      state.amo_id = payload;
    },
    set_bitrix24(state, payload) {
      state.bitrix_mode = true;
    },
    set_pending(state, payload) {
      state.pending_tasks_count = 0;
    },
    set_tariffs(state, payload) {
      state.tariffs = payload;
    },
    set_current_profile(state, payload) {
      state.current_profile = payload;
    },
    set_wa_status(state, payload) {
      if(state.current_profile.id === payload.profile_id) {
        state.wa_status = payload.data;
      }
      let cont = state.all_wa_containers.filter(container => container.profile_id === payload.profile_id)[0];
      if(cont) {
        cont.wa_status = payload.data;
      } else {
        let cont2 = state.all_tg_containers.filter(container => container.profile_id === payload.profile_id)[0];
        if(cont2) {
          cont2.wa_status = payload.data;
        } else {
          let cont3 = state.all_avito_containers.filter(container => container.profile_id === payload.profile_id)[0];
          if(cont3) {
            cont3.wa_status = payload.data;
          }
        }
      }
    },
    set_payments(state, payload) {
      state.payments = payload;
    },
    set_user(state, payload) {
      state.user = payload;
      if (state.all_wa_profiles.length === 0 && state.all_tg_profiles.length === 0) {
        let user_token = document.querySelector("#user_token").innerHTML;
        axios.get("/start/data", {
          headers: {
            Authorization: user_token,
          },
        }).then((res) => {
          state.all_wa_profiles = res.data.all_wa_profiles;
          state.all_wa_containers = res.data.all_wa_containers;
          state.all_tg_profiles = res.data.all_tg_profiles;
          state.all_avito_profiles = res.data.all_avito_profiles;
          state.all_tg_containers = res.data.all_tg_containers;
          state.all_avito_containers = res.data.all_avito_containers;
        });
      }
    },
    set_token(state, payload) {
      state.token = payload;
    },
    set_name(state, payload) {
      if (payload.platform == "tg") {
        let index = state.all_tg_profiles
          .map((profile) => profile.id)
          .indexOf(payload.id);
        state.all_tg_profiles[index].name = payload.name;
      } else if(payload.platform == "wz") {
        let index = state.all_wa_profiles
          .map((profile) => profile.id)
          .indexOf(payload.id);
        state.all_wa_profiles[index].name = payload.name;
      } else if(payload.platform == "av") {
        let index = state.all_avito_profiles
          .map((profile) => profile.id)
          .indexOf(payload.id);
        state.all_avito_profiles[index].name = payload.name;
      }
    },
    set_wha(state, payload) {
      state.current_profile.webhook_auth = payload.webhook_auth;
    },
    set_wz_status(state, payload) {
      let index = state.all_wa_profiles
        .map((profile) => profile.id)
        .indexOf(payload.profile_id);
      if (payload.auth === 1) {
        let prof = state.all_wa_profiles[index];
        prof.authorized = true;
        if(state.current_profile.id === prof.id) {
          state.current_profile.authorized = true;
        }
      } else if (payload.auth === 0) {
        state.all_wa_profiles[index].authorized = false;
        if(state.current_profile.id === state.all_wa_profiles[index].id) {
          state.current_profile.authorized = false;
        }
      }

    },
    set_avito_status(state, payload) {
      let index = state.all_avito_profiles
        .map((profile) => profile.id)
        .indexOf(payload.profile_id);
      if (payload.auth === 1) {
        let prof = state.all_avito_profiles[index];
        prof.authorized = true;
        if(state.current_profile.id === prof.id) {
          state.current_profile.authorized = true;
        }
      } else if (payload.auth === 0) {
        state.all_avito_profiles[index].authorized = false;
        if(state.current_profile.id === state.all_avito_profiles[index].id) {
          state.current_profile.authorized = false;
        }
      }

    },
    set_tg_status(state, payload) {
      let index = state.all_tg_profiles
        .map((profile) => profile.id)
        .indexOf(payload.profile_id);
      if (payload.auth === 1) {
        let prof = state.all_tg_profiles[index];
        prof.authorized = true;
        if(state.current_profile.id === prof.id) {
          state.current_profile.authorized = true;
        }
      } else if (payload.auth === 0) {
        state.all_tg_profiles[index].authorized = false;
        if(state.current_profile.id === state.all_tg_profiles[index].id) {
          state.current_profile.authorized = false;
        }
      }

    },
    set_profile(state, payload) {
      state.profile = payload;
    },
    set_all_wa_profiles(state, payload) {
      state.all_wa_profiles = payload;
    },
    set_all_avito_profiles(state, payload) {
      state.all_avito_profiles = payload;
    },
    set_all_wa_containers(state, payload) {
      state.all_wa_containers = payload;
    },
    set_all_tg_profiles(state, payload) {
      state.all_tg_profiles = payload;
    },
    set_all_tg_containers(state, payload) {
      state.all_tg_containers = payload;
    },
    set_all_avito_containers(state, payload) {
      state.all_avito_containers = payload;
    },
  },
});

new Vue({
  store,
  render: (h) =>
    h(App, {
      props: {
        initialPage: page,
        resolveComponent: (name) => {
          return pages[name];
        },
      },
    }),
}).$mount(app);
