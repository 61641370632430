<template>
  <div>
    <div style="text-align: center;font-size: 40px;font-weight: 700;"><span class="c-content">{{ code }}</span></div>
    <div class="col-12 mt-2 text-center" id="timerCode">02:40</div>
    <div class="col-12 mt-3 text-left">
      <span class="c-title mb-sm-2">Связывание аккаунта Whatsapp <span class="c-content">{{ phone }}</span></span>
      <div class="c-title mt-2">1. Откройте Whatsapp на телефоне.</div>
      <div class="c-title">
      2. Нажмите <span class="c-content">Меню</span> или
      <span class="c-content">Настройки.</span>
      </div>
      <div class="c-title">
      3. Нажмите
      <span class="c-content">Связанные устройства.</span>
      </div>
      <div class="c-title">
      4. Нажмите
      <span class="c-content">Привязка устройства.</span>
      </div>
      <div class="c-title">
      5. Нажмите <span class="c-content">Связать по номеру телефона</span>
      </div>
      <div class="c-title">6. Введите код.</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  props: ["code", "time_left", "phone"],
  methods: {
    
  },
  mounted() {
    // Установите начальное время в миллисекундах (3 минуты = 180 секунд)
    let timeLeft = this.time_left * 1000; 

    const timerElement = document.getElementById('timerCode'); // Элемент, в который будет выводиться таймер

    const intervalId = setInterval(() => {
      // Уменьшаем оставшееся время
      timeLeft -= 1000;

      // Переводим время в минуты и секунды
      const minutes = Math.floor(timeLeft / 60000);
      const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

      // Обновляем текст элемента таймера
      timerElement.textContent = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

      // Останавливаем таймер, когда время истекло
      if (timeLeft <= 0) {
        clearInterval(intervalId);
        timerElement.textContent = "00:00";
      }
    }, 1000);

  }
}
</script>