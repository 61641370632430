var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iq-navbar-header" },
    [
      _c("div", { staticClass: "container-fluid iq-container" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "container-fluid content-inner py-0 my-mt-5",
        style: this.$store.state.user.moysklad
          ? "min-height:initial !important"
          : "",
      }),
      _vm._v(" "),
      !this.$store.state.user.moysklad ? _c("Footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }