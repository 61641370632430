<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                ">
                <div>
                  <h1 class="c-content">ChatGPT ассистент</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row g-0 g-sm-2 g-md-4">
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="mass_title responder_head_margin">{{ gpt.name }}</h2>
                  
                  <div class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <b>Название</b>
                    </div>
                    <div>
                      <input type="text" name="name" class="form-control" v-model="gpt.name">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Введите название для вашего ассистента</span>
                    </div>
                  </div>

                  <div class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <b>API Токен OpenAI</b>
                      <span class="" data-toggle="tooltip" data-placement="right"
                          title="Токен OpenAI"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                      </span>
                    </div>
                    <div class="row gx-2">
                      <div class="col-12 col-xl-7 col-xxl-8 col-xxxxl-9"><input type="text" name="name" class="form-control" placeholder="Введите токен" v-model="openAIToken"></div>
                      <div class="col-12 col-xl-5 col-xxl-4 col-xxxxl-3"><button class="resp_button_green ms-xl-2 mt-2 mt-xl-0" @click="save_token()" style="width:100%">Применить</button></div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Токен от вашего аккаунта OpenAI, в котором находятся ассистенты</span>
                    </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <b>Ассистент ChatGPT</b>
                      <span class="" data-toggle="tooltip" data-placement="right"
                          title="Ассистент ChatGPT"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                      </span>
                    </div>
                    <div>
                      <select class="form-select" v-model="gpt.ai_asisstent_id">
                        <option v-for="asisstent in asisstents" :value="asisstent.id">{{ asisstent.name }}</option>
                      </select>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Выберите из списка необходимого ассистента</span>
                    </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <b>Прикрепить к профилю</b>
                    </div>
                    <div>
                      <select class="form-select" v-model="gpt.profile_uuid">
                        <option v-for="profile in profiles" :value="profile.uuid">{{ profile.name }}</option>
                      </select>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Выберите профиль Wappi, на котором будет активирован ChatGPT ассистент</span>
                    </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="wtpl_subtitle_2 d-flex align-items-center justify-content-center">
                    <div>НАСТРОЙКИ</div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                      <div class="form-label d-flex justify-content-between align-items-center">
                        <span><b>Статус</b></span>
                      </div>
                      <div class="d-flex align-items-center">
                        <switch-toggle uid="test_gpt_enable" :model.sync="gpt.enabled"
                          @toggler="toggleStatus" style="display: inline-block;"></switch-toggle>
                        <div v-if="gpt.enabled" class="bot_mini_text always_visible d-inline-block initial active">
                            ВКЛЮЧЕН</div>
                        <div v-if="!gpt.enabled" class="bot_mini_text always_visible d-inline-block initial">
                            ВЫКЛЮЧЕН</div>
                      </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <div class="form_check_flex align-items-start">
                        <div class="me-2" style="height: 22px;">
                          <input type="checkbox" v-model="gpt.is_stop_from_me" class="form-check-input check2" id="flexRadioDefault1" />
                        </div>
                        <label for="flexRadioDefault1">Отключать ассистента в чате в случае прерывания его владельцем</label>
                      </div>
                      <span class="" data-toggle="tooltip" data-placement="right"
                          title="Отключать ассистента в чате в случае прерывания его владельцем"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                      </span>
                    </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <div class="form_check_flex align-items-start">
                        <div class="me-2" style="height: 22px;">
                          <input type="checkbox" v-model="gpt.is_owner_stop_trigers" class="form-check-input check2" id="flexRadioDefault2" />
                        </div>
                        <label for="flexRadioDefault2">Триггер  от владельца на остановку ассистента</label>
                      </div>
                      <span class="" data-toggle="tooltip" data-placement="right"
                          title="Триггер  от владельца на остановку ассистента"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                      </span>
                    </div>
                    <div>
                      <input type="text" name="name" class="form-control" placeholder="Символ, слово или фраза" v-model="gpt.owner_stop_trigers[0]">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Если владелец введет этот триггер в чате - ассистент прервет работу в этом диалоге</span>
                    </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <div class="form_check_flex align-items-start">
                        <div class="me-2" style="height: 22px;">
                          <input type="checkbox" v-model="gpt.is_owner_start_trigers" class="form-check-input check2" id="flexRadioDefault3" />
                        </div>
                        <label for="flexRadioDefault3">Триггер от владельца на старт ассистента</label>
                      </div>
                      <span class="" data-toggle="tooltip" data-placement="right"
                          title="Триггер от владельца на старт ассистента"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                      </span>
                    </div>
                    <div>
                      <input type="text" name="name" class="form-control" placeholder="Символ, слово или фраза" v-model="gpt.owner_start_trigers[0]">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Если владелец введет этот триггер в чате - ассистент начнет работу в этом диалоге</span>
                    </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <div class="form_check_flex align-items-start">
                        <div class="me-2" style="height: 22px;">
                          <input type="checkbox" v-model="gpt.is_client_stop_trigers" class="form-check-input check2" id="flexRadioDefault4" />
                        </div>
                        <label for="flexRadioDefault4">Триггер от собеседника на остановку ассистента</label>
                      </div>
                      <span class="" data-toggle="tooltip" data-placement="right"
                          title="Триггер от собеседника на остановку ассистента"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                      </span>
                    </div>
                    <div>
                      <input type="text" name="name" class="form-control" placeholder="Символ, слово или фраза" v-model="gpt.client_stop_trigers[0]">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Если собеседник введет этот триггер в чате - ассистент прервет работу в этом диалоге</span>
                    </div>
                  </div>

                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <div class="form_check_flex align-items-start">
                        <div class="me-2" style="height: 22px;">
                          <input type="checkbox" v-model="gpt.is_client_triger_handlers" class="form-check-input check2" id="flexRadioDefault5" />
                        </div>
                        <label for="flexRadioDefault5">Триггер от собеседника на http запрос</label>
                      </div>
                      <span class="" data-toggle="tooltip" data-placement="right"
                          title="Использовать триггер от собеседника на остановку ассистента"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                      </span>
                    </div>
                    <div>
                      <input type="text" name="name" class="form-control" placeholder="Символ, слово или фраза" v-model="gpt.client_triger_handlers[0].value">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Если собеседник введет этот триггер в чате - ассистент отправит http запрос</span>
                    </div>
                    <div class="mt-2">
                      <input type="text" name="name" class="form-control" placeholder="Введите url (http://test.ru/test)" v-model="gpt.client_triger_handlers[0].url">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">По этому URL будет отправлен запрос</span>
                    </div>
                    <div class="mt-2">
                      <input type="text" name="name" class="form-control" placeholder="Введите запрос" v-model="gpt.client_triger_handlers[0].json">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="input_desc">Запрос в формате json</span>
                    </div>
                  </div>
                  
                  <div v-if="gpt.ai_token.length > 0" class="responder_head_margin responder_submit_buttons">
                    <button v-if="!sending" class="resp_button_green mr24" @click="save_gpt()">Сохранить</button>
                    <button v-if="sending" class="resp_button_green mr24" disabled><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></button>
                    <inertia-link class="resp_button_grey2" :href="route('gpt.index')">Отменить</inertia-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card card_desc">
                <div class="card-body">
                  <h2 class="mass_title">Что такое ChatGPT?</h2>
                  <div class="mb-4">
                    <p>ChatGPT - это умный чатбот от OpenAI, который умеет вести разговоры почти как человек. Он может использоваться для самых разных задач. </p>
                    <p>ChatGPT ассистенты - это индивидуальные версии ChatGPT, которые вы можете обучить для решения конкретных задач, например, <span class="c-content">отвечать на вопросы о вашей работе или компании</span>.</p>
                  </div>
                  <h2 class="mass_title mb-3">Индивидуальный чатбот ChatGPT для службы поддержки</h2>
                  <p>Замените рутинную службу поддержки на умного чат-бота ChatGPT. <span class="c-content">Обучите его вашим знаниям</span>, и он станет круглосуточным помощником, отвечая на вопросы клиентов, автоматизируя:</p>
                  <ul class="mt-4 graydotul">
                    <li>Ответы на частые вопросы</li>
                    <li>Решение проблем и предоставление инструкций</li>
                    <li>Обработку заявок, запросов, бронирований</li>
                    <li>Сбор обратной связи от клиентов</li>
                    <li>Поддержку на разных языках</li>
                  </ul>
                  <h2 class="mass_title mt-4">Возможности Wappi и ChatGPT</h2>
                  <ul class="green_list mt-4">
                    <li class="mb-4">
                      <div class="green_list_title">ChatGPT бот в Whatsapp или Telegram</div>
                      <div class="green_list_desc">
                        Подключение обученного под вашу специфику ChatGPT ассистента в качестве чат-бота Whatsapp или Telegram
                      </div>
                    </li>
                    <li class="mb-4">
                      <div class="green_list_title">Управление ChatGPT ботом прямо из чата мессенджера</div>
                      <div class="green_list_desc">
                        Настройка триггеров для остановки и запуска бота прямо из чата Whatsapp или Telegram
                      </div>
                    </li>
                    <li class="mb-4">
                      <div class="green_list_title">Уведомление, если клиент позовет оператора</div>
                      <div class="green_list_desc">
                        Настройка уведомлений в случае, когда собеседник хочет поговорить с живым оператором
                      </div>
                    </li>
                    <li class="mb-4">
                      <div class="green_list_title">Взаимодействие с внешними системами</div>
                      <div class="green_list_desc">
                        Настройка взаимодействия с вашей системой через отправку любого json запроса по вашему url
                      </div>
                    </li>
                  </ul>
                  <h2 class="mass_title mt-4">Инструкции по использованию</h2>

                  <div class="mt-2">
                    <div class="my_collapse">
                      <button @click="toggleRotate(1)" id="collapsed1" data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1" class="button_collapse mb_36" data-clear="true">
                        <h2 class="mass_title_green">Как обучить персонального ChatGPT-ассистента на своих данных?   <svg class="borg__str"  xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.58579 6L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6Z" fill="#23BB86"/>
                            </svg></h2>
                      </button>
                      <div class="collapse" id="collapseExample1">
                        <div class="mt-2">
                            <!--  -->
                            <p>Для того, чтобы дообучить ChatGPT на основе вашей базы знаний работать в качестве ассистента, необходимо:</p>
                            <ul class="ulnumeric mt-4">
                              <li>Зарегистрируйтесь на <a href="https://platform.openai.com" target="_blank" class="text_green">https://platform.openai.com</a> (используйте VPN).</li>
                              <li>Пополните баланс в вашем аккаунте на <a href="https://platform.openai.com" target="_blank" class="text_green">https://platform.openai.com</a> (не принимают РФ карты).</li>
                              <li>Нажмите <span class="text_green">Assistants</span>, далее выберите <span class="text_green">+ Create assistant</span></li>
                              <li>Добавьте промт (инструкцию). В нём должны быть описаны основные инструкции, например задача ассистента, стиль общения, как отвечать на вопросы, конфиденциальность. Чем подробнее, тем лучше.</li>
                              <li>Выберите модель chatGPT. Рекомендуем <span class="text_green">gpt-4o</span></li>
                              <li>Для дообучения ChatGPT соберите все релевантные данные о вашей компании. Это могут быть часто задаваемые вопросы (FAQ), документы по продуктам, инструкции, записи прошлых разговоров с клиентами и другие материалы, которые могут быть полезны для ответов на запросы пользователей. Убедитесь, что собранные данные актуальны и не содержат конфиденциальной информации, которую не следует разглашать.</li>
                              <li>Отформатируйте данные. Приведите данные в структурированный формат, удобный для обучения модели. Например, можно создать таблицы с вопросами и ответами, или документы с чёткими заголовками и секциями. Пометьте данные так, чтобы модель могла понимать, какие части текста являются вопросами, а какие ответами.</li>
                              <li>Загрузите приготовленные файлы в вашего ассистента, активировав <span class="text_green">File search</span> и нажав <span class="text_green">+ Files</span></li>
                              <li>Настройте остальные опции в зависимости от ваших задач. Сохраните ассистента.</li>
                              <li>ChatGPT ассистент прошел обучение на ваших данных и готов к работе!</li>
                            </ul>
                            <!--  -->
                        </div>
                      </div>
                    </div>

                    <!-- <div class="my_collapse">
                      <button @click="toggleRotate(2)" id="collapsed2" data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2" class="button_collapse mb_36" data-clear="true">
                        <h2 class="mass_title_green">Пример создания Whatsapp ChatGPT чат-бота службы поддержки Wappi   <svg class="borg__str"  xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.58579 6L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6Z" fill="#23BB86"/>
                            </svg></h2>
                      </button>
                      <div class="collapse" id="collapseExample2">
                        <div class="mt-2">
                            
                            <div class="row gx-2 gx-lg-4 gx-xxl-5 gy-4 mb_46 align_items_center">
                              <div class="col-12" style="text-align:center;font-size:21px;">Видеоинструкция</div>
                              <div class="col-12 big_instruction i_flex">
                                <div class="youtube" id="vg4L6XIudcM" data-thumbnail="/static/images/video1.jpg">
                                  <div class="play"></div>
                                </div>
                              </div>
                            </div>
                            
                        </div>
                      </div>
                    </div> -->

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer></Footer>
      </div>

    </main>
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import Footer from "./Components/Footer.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';
import SwitchToggle from './Components/SwitchToggle.vue';


export default {
  data() {
    return {
      gpt: {
        "enabled": false,
        "profile_uuid": "",
        "ai_token": "",
        "name": "Мое название ассистента",
        "ai_asisstent_id": "",
        "is_stop_from_me": true,
        "user_id": this.user.id,
        "owner_stop_trigers": [""],
        "owner_start_trigers": [""],
        "client_stop_trigers": [""],
        "client_triger_handlers": [
            {
              value:"",
              url:"",
              json:""
            }
        ],
        is_owner_stop_trigers: false,
        is_owner_start_trigers: false,
        is_client_stop_trigers: false,
        is_client_triger_handlers: false,
      },
      openAIToken: "",
      asisstents: [],
      sending: false,
    };
  },
  props: ["token", "user", "profiles"],
  methods: {
    save_token() {
      if (this.openAIToken.length > 0) {
        this.$axios.post(
          "https://" +
          window.location.host +
          "/asisstent/ai/get", 
          { 
            profile_uuid:  this.gpt.profile_uuid, // выбор профиля появляется тока после этого запроса, а нужно типа до
            ai_token: this.openAIToken
          },
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        ).then(response => {
          if (response.data.status === 'ok') {
            this.gpt.ai_token = this.openAIToken;
            this.asisstents = response.data.asisstents.data;
            this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Токен сохранен",
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }).catch(error => {
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: error.response.data.error,
              timerProgressBar: true,
              showConfirmButton: false,
              width: 400,
              timer: 2000,
            });
        });
        
      }
    },
    save_gpt() {
      this.sending = true;
      this.$axios
        .post(
          "https://" + window.location.host + "/asisstent/create",
          this.gpt,
          {
            headers: {
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          this.sending = false;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Ассистент сохранен",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 3000,
          });
          this.$inertia.visit(this.route("gpt.index")).then(()=> {
            window.scrollTo(0, 0);
          });
        });
    },
    toggleStatus() {
      console.log(this.gpt);
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          } 
          
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            this.$swal.close();
          }
          // if (data.data === 'open') {
          //   let restore_on_open = document.querySelectorAll('.restore_on_open');
          //   restore_on_open.forEach(res => {
          //     res.classList.remove('disabled')
          //   })
          // }
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  created() {
    this.gpt.profile_uuid = this.profiles[0].uuid;
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted() {
    const sidebar = document.querySelector(".sidebar");
    if (window.screen.width < 768) {
      sidebar.classList.add("sidebar-mini");
    }
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  components: {
    Sidebar,
    Topbar,
    SwitchToggle,
    Footer
  },
};
</script>