<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <h1 class="c-content">История спам рассылок</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row">
            <div class="col-sm -lg-12">
              <div class="card">
                <div class="card-body">


                  <div class="row align-items-center mb-3">
                    <div class="col-12 col-xl-6">
                      <div style="display: flex; align-items: center;">
                        <inertia-link :href="route('mass_spam')" style="line-height: 12px;">
                          <svg style="width:10px;margin-bottom: 2px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" fill="currentColor"/></svg> Назад
                        </inertia-link>
                      </div>
                    </div>
                    <div class="col-12 col-xl-6 d-flex justify-content-end" style="align-items: center;">
                      <div>
                        <a @click="downloadCsv" href="#" style="display: flex;align-items: center;justify-content: center;margin-right: 24px;">
                          <svg style="width: 16px; margin-right: 4px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                            <path fill="currentColor" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM200 352h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40zm133.1 0H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H333.1c-7.2 0-13.1 5.9-13.1 13.1c0 5.2 3 9.9 7.8 12l37.4 16.6c16.3 7.2 26.8 23.4 26.8 41.2c0 24.9-20.2 45.1-45.1 45.1H304c-8.8 0-16-7.2-16-16s7.2-16 16-16h42.9c7.2 0 13.1-5.9 13.1-13.1c0-5.2-3-9.9-7.8-12l-37.4-16.6c-16.3-7.2-26.8-23.4-26.8-41.2c0-24.9 20.2-45.1 45.1-45.1zm98.9 0c8.8 0 16 7.2 16 16v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V368c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V368c0-8.8 7.2-16 16-16z"/>
                          </svg>
                          Скачать csv
                        </a>
                      </div>
                      <form autocomplete="off" @submit.prevent="" class="form100">
                        <input class="form-control" name="sidebar_search" placeholder="Поиск по телефону" autocomplete="off"
                          v-model="search_name" />
                      </form>
                    </div>
                  </div>
                  <div class="table-responsive mt-2">
                    <table class="table table-hover table-bordered" v-if="filterMessages.length > 0">
                      <tbody>
                        <tr class="thead text-center text-uppercase table-secondary c-title">
                          <th scope="col" class="tw1">Дата</th>
                          <th scope="col" class="tw2">Получатель</th>
                          <th scope="col" class="tw1">Профиль</th>
                          <th scope="col">Сообщение</th>
                          <th scope="col" class="tw3">Статус</th>
                        </tr>
                        <tr v-for="fp in filterMessages">
                          <td class="text-center">{{ fp.created_at | wtz }}</td>
                          <td class="text-center">{{ fp.recipient | cus }}</td>
                          <td class="text-center">
                            <inertia-link :href="route('dashboard.instance', fp.profile.uuid)" style="line-height: 12px;">{{ fp.profile.name }}</inertia-link>
                          </td>
                          <td class="text-wrap">
                            <span v-if="fp.message !== null && fp.message.length > 0">{{ fp.message }}</span>
                            <span v-if="fp.caption !== null && fp.caption.length > 0">{{ fp.caption }}</span>
                          </td>
                          <td class="text-center">
                            <span class="c-badge green" v-if="fp !== null && fp.delivered === 'success'">отправлено</span>
                            <span class="c-badge red" v-if="fp !== null && fp.delivered === 'failed'">ошибка</span>
                            <span class="c-badge gray" v-if="fp !== null && fp.delivered !== 'success' && fp.delivered !== 'failed'">не отправлено</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="filterMessages.length === 0" style="color: #6c757d" class="mb-4">Сообщений нет</div>
                  </div>

                  <v-pagination v-if="all_pages > 0" v-model="now_page" :page-count="all_pages" :classes="bootstrapPaginationClasses"></v-pagination>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
// import _ from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';
import moment from "moment";
import vPagination from 'vue-plain-pagination'

export default {
  data() {
    return {
      search_name: "",
      order: 'desc',
      filtered: this.messages,
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
    };
  },
  methods: {
    downloadCsv() {
      this.$axios
      .get(
        "https://" +
        window.location.host +
        "/api/csv/download?profile_id="+ this.mass_profile_id +"&mass_posting_id=" + this.mass_posting_id,
        {
          headers: {
            Authorization: user_token,
          },
        }
      )
      .then(response  => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'messages_'+ this.mass_posting_id +'.csv');
        document.body.appendChild(link);
        link.click();
      });
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (typeof this.$store.state.current_profile !== 'undefined' && data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          this.$store.commit("set_status", { auth: data.data, profile_id: data.profile_id });
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'open') {
            let restore_on_open = document.querySelectorAll('.restore_on_open');
            restore_on_open.forEach(res => {
              res.classList.remove('disabled')
            })
          }
        } 
      };
    },
  },
  props: ["user", "all_profiles", "profile", "token", "messages", "all_containers", "now_page", "all_pages", "mass_posting_id", "mass_profile_id"],
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted() {
    // console.log(this.mass_profile_id);
    // console.log(this.messages);
    const sidebar = document.querySelector(".sidebar");
    if (window.screen.width < 768) {
      sidebar.classList.add("sidebar-mini");
    }

    // console.log(this.user);
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  filters: {
    moment: function (date) {
      return moment.unix(date).format("LLL");
    },
    short: function (date) {
      return moment(date).format("L");
    },
    cus: function (jid) {
      return jid.replace("@c.us", "")
    },
    wtz: function (date) {
      if (date !== null) {
        return moment(date).format("DD.MM.YYYY HH:mm:ss");
      } else {
        return "-";
      }
       
    },
    rs: function (name) {
      return name.replace('s_', '');
    },
  },
  computed: {
    filterMessages: {
      get() {
        return this.filtered.filter(message => message.recipient.toLowerCase().indexOf(this.search_name.toLowerCase()) >= 0);
      }
    },
  },
  watch: {
    now_page: {
       handler(val){
         if (parseInt(val) > 0) {
           this.$inertia.get(this.route('mass_messages_history') + '?page=' + val + "&id=" + this.mass_posting_id + "&profile_id=" + this.mass_profile_id)
         }
       },
    }
  },
  components: {
    Sidebar,
    Topbar,
    vPagination
  },
};
</script>
