<template>
    <div class="switch-toggle">
        <input type="checkbox" class="d-none" :id="'switch_' + uid">
        <label :for="'switch_' + uid" @click="toggle">
            <div class="knob"></div>
        </label>
    </div>
</template>


<script>
    export default {
        props: ['uid', 'model', 'responder'],
        methods: {
            toggle(){
                let sw = document.querySelector('#switch_' + this.uid);
                sw.parentNode.classList.toggle('active');
                let output = !!sw.parentNode.classList.contains('active');
                this.$emit('update:model', output);
                this.$emit('toggler', this.responder);
            },
        },
        mounted() {
            let sw = document.querySelector('#switch_' + this.uid);
            if(this.uid === "222") {
                console.log("this.model");
                console.log(this.model);
            }
            
            if(this.model){
                sw.parentNode.classList.add('active');
            } else {
                sw.parentNode.classList.remove('active');
            }
        },
    }
</script>
