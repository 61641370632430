var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block", staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        {
          staticClass: "em_ico",
          attrs: { id: "em_ico_" + _vm.uid },
          on: {
            click: function ($event) {
              return _vm.open()
            },
          },
        },
        [_vm._v("😀")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "em_list my_modal emlistModal",
          class: { show: _vm.opened },
          attrs: { id: "emoji_modal_" + _vm.uid },
        },
        [
          _c(
            "div",
            { staticClass: "em_sections" },
            _vm._l(_vm.sections, function (section, i) {
              return _c("div", {
                staticClass: "em_section",
                domProps: { innerHTML: _vm._s(section.title) },
                on: {
                  click: function ($event) {
                    _vm.selected = i
                  },
                },
              })
            }),
            0
          ),
          _vm._v(" "),
          _vm._l(_vm.sections, function (section, index) {
            return _c(
              "div",
              {
                staticClass: "emoji_section_block emoji_section_10",
                class: { "d-flex": index == _vm.selected },
                attrs: { id: "emoji_section_10_" + index },
              },
              _vm._l(section.emoji, function (emoji) {
                return _c("span", {
                  staticClass: "em_button",
                  staticStyle: { cursor: "pointer", "font-size": "20px" },
                  domProps: { innerHTML: _vm._s(emoji) },
                  on: {
                    click: function ($event) {
                      return _vm.insertAtCaret(emoji)
                    },
                  },
                })
              }),
              0
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }