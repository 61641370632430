<template>
    <div>
    <div class="input_wrapper">
        <div class="form min_h110">
            <textarea :placeholder="placeholder" 
            ref="waba_textarea"
            :id="'textarea_' + uid"
            autocomplete="off"  
            :class="{'oy_scroll': check_height()}"
            class="bugs_textarea emoji_title" 
            v-model="local_text" 
            maxlength="1028" 
            minlength="1" 
            @change="resText" @drop="resText" @cut="deResText" @paste="deResText" @keydown="doDown" @scroll="scrollStop">
            </textarea>
        </div>
        <div class="upload_files">
            <div class="d-flex align-items-center">
                <a @click.prevent="addVariable()" href="#" class="button waba_button" :class="{'disabled': text_variables.length == text_variables_max}">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 4.375V15.625M16.125 10H4.875" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="d-inline d-sm-none waba_textarea_var_short" :class="'bot_attach_text_' + uid">Переменная</span>
                    <span class="d-none d-sm-inline waba_textarea_var_long" :class="'bot_attach_text_' + uid">Добавить переменную</span>
                </a>
                <span @click="wrapSelectedText('bold')" class="waba_font_pref waba_font_pref_margin">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5989 22.3413V21.865C20.8984 21.2205 20.7022 20.8002 20.7022 20.0156V11.6095C20.7022 8.52722 18.6007 6.95807 14.5097 6.95807C10.4187 6.95807 8.28911 8.69535 8.03693 12.1979H11.8197C12.0158 10.6288 12.6603 10.1244 14.5937 10.1244C16.1068 10.1244 16.8634 10.6288 16.8634 11.6375C16.8634 13.2347 15.6865 13.0665 13.7251 13.4028L12.156 13.683C9.15775 14.2154 7.70068 15.5043 7.70068 18.2503C7.70068 21.1925 9.77421 22.5935 12.2961 22.5935C13.9773 22.5935 15.5184 21.865 16.8914 20.436C16.8914 21.2205 16.9755 21.893 17.3398 22.3413H21.5989ZM16.8634 15.8686C16.8634 18.1383 15.7426 19.4272 13.7531 19.4272C12.4362 19.4272 11.6236 18.9228 11.6236 17.802C11.6236 16.6532 12.24 16.2329 13.8652 15.9246L15.2102 15.6725C16.2469 15.4763 16.4151 15.4203 16.8634 15.1961V15.8686Z" fill="currentColor"/>
                    </svg>
                </span>
                <span @click="wrapSelectedText('italic')" class="waba_font_pref waba_font_pref_margin">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.3132 17.4238L21.4446 16.9474C20.3798 19.077 19.3991 20.2539 18.6706 20.2539C18.3624 20.2539 18.0821 19.9456 18.0821 19.5533C18.0821 19.2731 18.1942 18.7407 18.3904 18.0402L21.1924 8.62532H18.7546L17.9981 11.1472C17.8019 9.4099 16.4009 8.20502 14.5796 8.20502C12.8703 8.20502 11.2732 9.10168 9.95619 10.7829C8.52714 12.6323 7.68652 14.986 7.68652 17.2276C7.68652 19.9736 9.33974 21.795 11.8336 21.795C13.5708 21.795 14.6917 21.0945 16.0927 19.077C15.9806 19.4973 15.9526 19.6934 15.9526 19.9736C15.9526 21.0664 16.6531 21.795 17.6618 21.795C18.8947 21.795 19.9595 21.1225 20.9402 19.7495C21.5007 18.9649 21.977 18.1803 22.3132 17.4238ZM15.7564 17.8441C14.8878 19.5814 13.8791 20.3659 12.478 20.3659C11.077 20.3659 10.2364 19.3292 10.2364 17.6199C10.2364 15.1541 11.3292 12.24 12.8143 10.7269C13.4307 10.0824 14.2153 9.74615 15.0559 9.74615C16.3729 9.74615 17.1855 10.6988 17.1855 12.24C17.1855 13.8371 16.6251 16.0788 15.7564 17.8441Z" fill="currentColor"/>
                    </svg>
                </span>
                <span @click="wrapSelectedText('strike')" class="waba_font_pref waba_font_pref_margin">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.6191 7.23633C11.0605 7.23633 8.8779 8.61391 8.73779 12.0044H11.0889C11.285 10.2111 12.353 9.39697 14.5386 9.39697C16.6401 9.39697 17.8125 10.1786 17.8125 11.5796V12.1948C17.8125 13.1755 17.2302 13.5966 15.3809 13.8208C12.0744 14.2411 11.568 14.3574 10.6714 14.7217C9.9808 15.0047 9.43041 15.3892 9.01611 15.8643H4.86328V17.5854H8.18115C8.12214 17.8831 8.09326 18.1993 8.09326 18.5303C8.09326 21.1922 9.94242 22.7637 12.9126 22.7637C14.79 22.7637 16.6114 21.976 17.9004 20.603C18.1526 21.7238 19.1612 22.5366 20.3101 22.5366C20.7864 22.5366 21.1502 22.4837 21.9067 22.2876V20.5225C21.6546 20.5785 21.5415 20.5737 21.4014 20.5737C20.5888 20.5737 20.1416 20.1524 20.1416 19.4238V17.5854H25.1367V15.8643H20.1416V11.2427C20.1416 8.63677 18.2338 7.23633 14.6191 7.23633ZM17.8125 15.0806V15.8643H14.2749C16.5245 15.5544 17.0554 15.4321 17.8125 15.0806ZM10.686 17.5854H17.7979C17.5941 19.5027 15.5703 20.7129 13.418 20.7129C11.5966 20.7129 10.5322 20.0688 10.5322 18.4717C10.5322 18.1346 10.5824 17.8453 10.686 17.5854Z" fill="currentColor"/>
                    </svg>
                </span>
                <span class="waba_font_pref_margin">
                    <Emoji :uid="uid" :text.sync="local_text" @addEmoji="addEmoji"/>
                </span>
            </div>
            <div class="symbol_length symbol_length_waba">
                <span class="d-none d-sm-inline waba_textarea_symbol_desc">Символы: </span>
                <span>{{ text_length_comp() }}/1028</span>
            </div>
        </div>
    </div>
    
    </div>
    </template>
    
    
    <script>
    import Emoji from "./EmojiWaba.vue";
    
    export default {
        data() {
            return {
                text_variables_max: 5,
                local_text: this.text,
                isTextUpdating: false
            }
        },
        props: ['uid', 'text', 'placeholder', 'exec_area_height', 'text_variables'],
        computed: {
            text_length: function() {
                return function() { 
                    return this.text !== null ? this.text.length : 0; 
                }
            },
        },
        methods: {
            addVariable() {
                let len = this.text_variables.length;
                if (len < this.text_variables_max) {
                    let trigger = "{{" + (len + 1) + "}}";
                    this.insertAtCaret(trigger);
                    this.text_variables.push({
                        trigger: trigger,
                        index: (len + 1),
                        value: "",
                    });
                    this.$emit('update:text_variables', this.text_variables);
                }
            },
            check_height() {
                let ta = document.querySelector('#textarea_' + this.uid);
                if(ta === null) {
                    return false;
                }
                return ta.scrollHeight < 1000 ? false : true;
            },
            text_length_comp() {
                return this.text !== null ? this.text.length : 0; 
            },
            toggle_modal(id) {
                document.getElementById(id).classList.toggle('active');
                document.querySelector('.modal_shadow').classList.toggle('active');
            },
            close_all_modals() {
                let modals = document.querySelectorAll('.my_modal');
                modals.forEach(modal => {
                    modal.classList.remove('active')
                });
                document.querySelector('.modal_shadow').classList.remove('active');
            },
            scrollStop(e) {
                let area = e.target;
                if(area.scrollHeight < 1000) {
                    e.preventDefault();
                    area.scrollTop = 0;
                }
            },
            addEmoji(emoji){
                let doc = new DOMParser().parseFromString(emoji, "text/html");
                console.log(doc.documentElement.textContent);
                this.insertAtCaret(doc.documentElement.textContent);
                this.$emit('update:text', this.local_text);
            },
            resText(e) {
                let area = e.target;
                this.$emit('update:text', area.value);
                e.stopPropagation();
                if(area.scrollHeight < 1000) {
                    area.style.height = 'auto';
                    area.style.height = (area.scrollHeight) + 'px';
                } 
            },
            doDown(event) {
                const textarea = event.target;
                const key = event.key;
                const cursorPosition = textarea.selectionStart;

                // Проверка, является ли введенный символ цифрой
                if (!/\d/.test(key) && key !== '}') {
                    return;
                }

                // Расчет индексов для проверки символов перед и после
                let beforeText = textarea.value.substring(cursorPosition - 2, cursorPosition);
                let afterText = textarea.value.substring(cursorPosition, cursorPosition + 2);

                let beforeText2 = textarea.value.substring(cursorPosition - 4, cursorPosition);

                // Проверка на соответствие шаблону "{{цифра}}"
                if (beforeText === '{{' && afterText === '}}') {
                    // Отменяем стандартное поведение, чтобы предотвратить ввод символа
                    event.preventDefault();
                    
                    // Замена введенной цифры на '9'
                    const updatedText = textarea.value.substring(0, cursorPosition) + '9' + textarea.value.substring(cursorPosition);

                    // Установка позиции курсора после измененного символа
                    this.$nextTick(() => {
                        textarea.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
                    });

                    // Эмитируем событие input, так как программа изменяет значение поля
                    this.$emit('input', updatedText);
                    this.updateText(updatedText);
                } else if (key === '}' && beforeText2.match(/\{\{\d\}/)) {
                    
                    // Проверяем, закрывается ли конструкция "{{цифра"
                    event.preventDefault();

                    // Извлекаем последний символ перед курсором, который должен быть цифрой
                    const digit = beforeText2[beforeText.length];

                    const updatedText = textarea.value.substring(0, cursorPosition - 2) + '9}' + textarea.value.substring(cursorPosition - 1);
                    
                    // Установка позиции курсора после измененного символа
                    this.$nextTick(() => {
                        textarea.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
                    });

                    // Эмитируем событие input, так как программа изменяет значение поля
                    this.$emit('input', updatedText);
                    this.updateText(updatedText);
                }
            },
            deResText(e) {
                let text = e.target.value;
                window.setTimeout(() => {
                    this.resText(e, text);
                }, 0);
            },
            wrapSelectedText(type) {
                let textarea;
                textarea = document.querySelector('#textarea_' + this.uid);
                const value = textarea.value; // Текущий текст в textarea
                const start = textarea.selectionStart; // Начальная позиция выделенного текста
                const end = textarea.selectionEnd; // Конечная позиция выделенного текста

                // Проверяем, что есть выделение
                if (start !== end) {
                    const selectedText = value.substring(start, end); // Выделенный текст
                    const beforeText = value.substring(0, start); // Текст до выделения
                    const afterText = value.substring(end); // Текст после выделения

                    // Оборачиваем выделенный текст в квадратные скобки
                    if (type === 'bold') {
                        this.local_text = beforeText + '*' + selectedText + '*' + afterText;
                    } else if (type === 'italic') {
                        this.local_text = beforeText + '_' + selectedText + '_' + afterText;
                    } else if (type === 'strike') {
                        this.local_text = beforeText + '~' + selectedText + '~' + afterText;
                    }

                    this.$emit('update:text', this.local_text);
                    
                    // Перемещаем курсор сразу за закрывающую скобку
                    textarea.selectionStart = textarea.selectionEnd = start + selectedText.length + 2;
                }
            },
            insertAtCaret(text) {
                let txtarea = document.querySelector('#textarea_' + this.uid);
                
                let strPos = 0;
                let br = (txtarea.selectionStart || txtarea.selectionStart == '0') ? "ff" : (document.selection ? "ie" : false);
                if (br === "ie") {
                    txtarea.focus();
                    let range = document.selection.createRange();
                    range.moveStart('character', -txtarea.value.length);
                    strPos = range.text.length;
                } else if (br === "ff") {
                    strPos = txtarea.selectionStart;
                }

                let front = txtarea.value.substring(0, strPos);
                let back = txtarea.value.substring(strPos, txtarea.value.length);
                this.local_text = front + text + back;
                this.updateText(this.local_text);

                // Обновляем позицию курсора асинхронно
                setTimeout(() => {
                    strPos = strPos + text.length;
                    if (br === "ie") {
                        txtarea.focus();
                        let ieRange = document.selection.createRange();
                        ieRange.moveStart('character', -txtarea.value.length);
                        ieRange.moveStart('character', strPos);
                        ieRange.moveEnd('character', 0);
                        ieRange.select();
                    } else if (br === "ff") {
                        txtarea.selectionStart = strPos;
                        txtarea.selectionEnd = strPos;
                        txtarea.focus();
                    }

                    // Авто-ресайз для textarea
                    txtarea.style.height = 'auto';
                    txtarea.style.height = txtarea.scrollHeight + 'px';
                }, 0);
            },
            exec_area() {
                window.setTimeout(() => {
                    let area = document.querySelector('#textarea_' + this.uid);
                    area.style.height = 'auto';
                    area.style.height = (area.scrollHeight) + 'px';
                }, 60);
            },
            is_trigger_text_exist(item) {
                let res = false;
                if(this.local_text.includes(item.trigger)) {
                    res = true;
                }
                return res;
            },
            updateText(newText) {
                const textarea = this.$refs.waba_textarea;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;

                this.local_text = newText;
                this.$emit('update:text', newText);
                
                this.$nextTick(() => {
                    textarea.setSelectionRange(start, end);
                    this.isTextUpdating = false; // Сброс флага после завершения всех обновлений
                });
            },
            updateTextVariables(newVal) {
                const regex = /\{\{(\d+)\}\}/g;
                let matches = [...new Set(newVal.match(regex))];  // Удаление дубликатов с помощью Set
                let newText = newVal; // Начинаем с исходного текста

                if (matches) {
                    let variables = {};

                    // console.log(matches);

                    // Сбор всех переменных c текарии
                    matches.forEach((match, index) => {
                        const varNumber = match.replace(regex, '$1');
                        const varNumberINT = parseInt(match.replace(regex, '$1'));
                        if (varNumberINT <= this.text_variables_max) {
                                variables[varNumber] = {
                                trigger: `{{${index + 1}}}`,
                                index: index + 1,
                                value: ""
                            };
                            
                            let existingVar = this.text_variables.find(v => v.trigger === match);
                            if (existingVar) {
                                variables[varNumber].value = existingVar.value;
                            }
                        } else {
                            const removeRegex = new RegExp(`\\{\\{${varNumberINT}\\}\\}`, 'g');
                            newText = newText.replace(removeRegex, '');
                        }
                    });

                    // Обновляем текст и переменные, если были изменения
                    if (newText !== newVal) {
                        this.updateText(newText);
                    }

                    // console.log(variables);

                    // Обновляем текст и переменные
                    this.$emit('update:text_variables', Object.values(variables));
                    
                } else {
                    // Если нет совпадений, просто очищаем переменные
                    this.$emit('update:text_variables', []);
                    this.text_variables = [];
                }
            }
        },
        watch: {
            local_text(newVal, origVal) {
                if (this.isTextUpdating) {
                    return;
                }

                this.isTextUpdating = true; // Установка флага перед изменениями

                const regex = /\{\{(\d+)\}\}/g;
                let matches = newVal.match(regex);
                let newText = newVal;

                if (matches) {
                    let tempMap = new Map();
                    let finalMap = new Map();

                    // Создаем временные маркеры и окончательные значения
                    matches.forEach((match, index) => {
                        const tempMarker = `TEMP${index}`;
                        tempMap.set(match, tempMarker); // Связываем исходное значение с временным маркером
                        finalMap.set(tempMarker, `{{${index + 1}}}`); // Связываем временный маркер с окончательным значением
                    });

                    // Сначала заменяем все на временные маркеры
                    tempMap.forEach((tempVal, origVal) => {
                        newText = newText.replace(new RegExp(`\\{\\{${origVal.match(/\d+/)[0]}\\}\\}`, 'g'), tempVal);
                    });

                    // Заменяем временные маркеры на окончательные значения
                    finalMap.forEach((finalVal, tempMarker) => {
                        newText = newText.replace(new RegExp(tempMarker, 'g'), finalVal);
                    });

                    // console.log(newText);

                    this.updateText(newText);
                    
                } else {
                    this.isTextUpdating = false; // Сброс флага, если нет изменений
                }

                this.updateTextVariables(newText);
            }
        },
        mounted() {
            let shadow = document.createElement('div');
            shadow.classList.add('modal_shadow');
            document.body.appendChild(shadow);
            this.exec_area();
        },
        components: {
            Emoji,
        },
    }
    </script>