<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <h1 class="c-content">Статистика профилей</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!-- account block begin -->
          <div class="row g-0 g-md-2">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row mt-4">
                    <div class="col-6 col-lg-2">
                      <div class="c-title text-center">ВСЕГО ПРОФИЛЕЙ</div>
                      <div class="c-title text-center big_number">
                        {{ this.$store.state.all_wa_profiles.length + this.$store.state.all_tg_profiles.length + this.$store.state.all_avito_profiles.length }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="!this.user.spamer">
                      <div class="c-title text-center">АВТОРИЗОВАНО</div>
                      <div class="c-title text-center c-content big_number">
                        {{ authorized_count }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2">
                      <div class="c-title text-center">СТАТУС OPEN</div>
                      <div class="c-title text-center c-content big_number">
                        {{ cont_open }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="user.spamer">
                      <div class="c-title text-center">ОТПРАВЛЕНО СЕГОДНЯ</div>
                      <div class="c-title text-center c-content big_number">
                        {{ today_tasks_count }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="user.spamer">
                      <div class="c-title text-center">ОТПРАВЛЕНО ВСЕГО</div>
                      <div class="c-title text-center c-content big_number">
                        {{ all_tasks_count }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="user.spamer">
                      <div class="c-title text-center">СТОИМОСТЬ СООБЩЕНИЯ</div>
                      <div class="c-title text-center c-content big_number">
                        {{ user_msg_price }} ₽
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="user.spamer">
                      <div class="c-title text-center">ЗАРАБОТАНО ВСЕГО</div>
                      <div class="c-title text-center c-content big_number">
                        {{ all_earned }} ₽
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="!user.with_payments">
                      <div class="c-title text-center">ТАРИФ, ₽</div>
                      <div class="c-title text-center c-content big_number">
                        {{ tariff_rub.price }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="user.pay_system !== 'days' && !user.spamer && user.id !== 12">
                      <div class="c-title text-center">ОПЛАЧЕНО</div>
                      <div class="c-title text-center c-content big_number">
                        {{ paid }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2" v-if="user.pay_system !== 'days' && !user.spamer && user.id !== 12">
                      <div class="c-title text-center">НЕ ОПЛАЧЕНО</div>
                      <div class="c-title text-center text-danger big_number">
                        {{ not_paid }}
                      </div>
                    </div>
                    <div class="col-6 col-lg-2"
                      v-if="!user.with_payments && user.pay_system === 'days' && !user.spamer && user.id !== 12">
                      <div class="c-title text-center">НЕ ОПЛАЧЕНО, ₽</div>
                      <div class="c-title text-center text-danger big_number">
                        {{ all_sum }}
                      </div>
                    </div>
                  </div>
                  <!-- Modal Billing History -->
                  <div class="modal fade" id="billinghistory-modal" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="user.pay_system === 'days'">
                    <div class="modal-dialog modal-xl" role="document">
                      <div class="modal-content">
                        <div class="modal-head d-flex align-items-start justify-content-between">
                          <h5 class="modal-title" id="billing-history-modal-label">
                            История оплаты <span style="color: #23BB86">{{ this.profileName }}</span>
                          </h5>
                          <button type="button" class="close" @click="showPayHistoryModal">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                                fill="#6C757D" />
                            </svg>
                          </button>
                        </div>
                        <div class="">
                          <div class="table-responsive">
                            <table class="table table-striped">
                              <tbody>
                                <tr class="text-center text-uppercase">
                                  <th scope="col">Дата</th>
                                  <th scope="col">Сумма</th>
                                </tr>
                                <tr v-for="(payment, index) in payments" :key="index" class="text-center">
                                  <td>{{ payment.payment_at }}</td>
                                  <td>{{ payment.amount }}₽</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Modal Billing Histry end -->
                  <div class="row mt-4">
                    <div class="col-12 d-flex" style="justify-content: space-between;align-items: center;">
                      <form autocomplete="off" @submit.prevent="">
                        <input class="form-control" name="sidebar_search" placeholder="Поиск профиля" autocomplete="off"
                          v-model="search_name" />
                      </form>
                      <div class="me-3" v-if="user.pay_system === 'days'"><a @click="showPayHistoryModal"
                          class="my-green-a">История оплат</a></div>
                    </div>
                  </div>
                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <tbody>
                        <tr class="thead text-center text-uppercase table-secondary c-title">
                          <th scope="col" @click="sort_profiles('wa_status')" class="pointer tw1">
                            Статус
                            <span class="mb-1">
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" @click="sort_profiles('name')" class="pointer">
                            Имя профиля
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" @click="sort_profiles('platform')" class="pointer tw1">
                            Платформа
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" class="tw2">id профиля</th>
                          <th scope="col" @click="sort_profiles('ttc')" class="tw3 pointer" v-if="user.spamer">
                            Отправлено сегодня
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" @click="sort_profiles('atc')" class="tw3 pointer" v-if="user.spamer">
                            Отправлено всего
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" @click="sort_profiles('is_payment')" class="tw3 pointer"
                            v-if="user.pay_system !== 'days' && !user.spamer && user.id !== 12">
                            Оплата
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" @click="sort_profiles('left_days')" class="tw4 pointer"
                            v-if="user.pay_system !== 'days' && !user.spamer && user.id !== 12">
                            Остаток дней
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" @click="sort_profiles('worked_days')" class="tw4 pointer"
                            v-if="user.pay_system === 'days' && !user.spamer">
                            Дней в работе
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                          <th scope="col" @click="sort_profiles('authorized')" class="tw4 pointer">
                            Авторизован
                            <span>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z"
                                  fill="currentColor" />
                                <path
                                  d="M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z"
                                  fill="currentColor" />
                              </svg>
                            </span>
                          </th>
                        </tr>
                        <tr v-for="fp in filterProfiles" :key="fp.uuid" class="text-center"
                          :class="{ 'opacity': isDeleted(fp) }">
                          <td>
                            <span class="c-badge green" v-if="fp.wa_status === 'open'">open</span>
                            <span class="c-badge yellow" v-if="fp.wa_status === 'connecting'">connecting</span>
                            <span class="c-badge gray" v-if="fp.wa_status === 'close'">close</span>
                          </td>
                          <td>
                            <span class="wraps">
                              <inertia-link
                                v-if="!isDeleted(fp) && ($store.state.user.id !== 12 || ($store.state.user.id === 12 && fp.tech !== 'js'))"
                                :href="route('dashboard.instance', fp.uuid)">{{ fp.name }}</inertia-link>
                              <inertia-link style="color: #dc3545"
                                v-if="!isDeleted(fp) && ($store.state.user.id === 12 && fp.tech === 'js')"
                                :href="route('dashboard.instance', fp.uuid)">{{ fp.name }}</inertia-link>
                              <span v-if="isDeleted(fp)">{{ fp.name }}</span>
                            </span>
                          </td>
                          <td>
                            <span v-if="fp.platform === 'wz'">wa</span>
                            <span v-if="fp.platform === 'tg'">tg</span>
                            <span v-if="fp.platform === 'av'">av</span>
                          </td>
                          <td>
                            <span class="c-title">
                              <inertia-link v-if="!isDeleted(fp)" :href="route('dashboard.instance', fp.uuid)">{{
      fp.uuid }}</inertia-link>
                              <span v-if="isDeleted(fp)">{{ fp.uuid }}</span>
                            </span>
                          </td>
                          <td v-if="user.spamer">
                            <span>{{ fp.ttc }}</span>
                          </td>
                          <td v-if="user.spamer">
                            <span>{{ fp.atc }}</span>
                          </td>
                          <td v-if="user.pay_system !== 'days' && !user.spamer && user.id !== 12">
                            <span :class="{
      'c-content': fp.left_days > 0,
      'text-danger': fp.left_days < 1,
    }" v-if="fp.left_days === '-'">-</span>
                            <span
                              v-if="(fp.left_days !== '-' && fp.left_days != 0 && fp.is_payment) || fp.left_days > 0"
                              class="c-content">Оплачен</span>
                            <span v-if="(fp.left_days !== '-' && !fp.is_payment) || fp.left_days == 0"
                              class="text-danger">Не оплачен</span>
                          </td>
                          <td v-if="user.pay_system !== 'days' && !user.spamer && user.id !== 12">
                            <span :class="{
      'c-content': fp.left_days > 0,
      'text-danger': fp.left_days < 1,
    }">
                              {{ fp.left_days }}
                            </span>
                          </td>
                          <td v-if="user.pay_system === 'days' && !user.spamer && user.id !== 12">
                            <span>{{ fp.worked_days }}</span>
                          </td>
                          <td>
                            <span v-if="fp.authorized" class="c-content">
                              Авторизован
                            </span>
                            <span v-if="!fp.authorized" class="text-danger">
                              Не авторизован
                            </span>
                          </td>
                          <!-- <td>
                            <span v-if="fp.platform === 'wz'" class="c-content">
                              whatsapp
                            </span>
                            <span v-if="fp.platform === 'tg'" class="c-content">
                              telega
                            </span>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import _ from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';

export default {
  data() {
    return {
      search_name: "",
      cont_open: 0,
      paid: 0,
      not_paid: 0,
      filtered: this.filtered_profiles,
      order: 'desc',
      order_worded: 'asc',
      show_deleted: false
    };
  },
  methods: {
    showPayHistoryModal() {
      $("#billinghistory-modal").modal("toggle");
    },
    isDeleted(profile) {
      return !profile.status;
    },
    sort_profiles(key) {
      if (key === 'worked_days') {
        this.order_worded = this.order_worded !== 'asc' ? 'asc' : 'desc';
      } else {
        this.order = this.order !== 'asc' ? 'asc' : 'desc';
      }
      let prepared_arr = [];
      let replaced_minus = [];
      this.filtered.forEach(pr => {
        if (pr.left_days === '-') {
          pr.left_days = 0;
          pr.is_payment = null;
          replaced_minus.push(pr.uuid);
        }
        prepared_arr.push(pr);
      });

      let ordered;
      if (key === 'name') {
        ordered = _.orderBy(prepared_arr, [(o) => o[key].toLowerCase()], this.order);
      } else if (key === 'worked_days') {
        ordered = _.orderBy(prepared_arr, key, this.order_worded);
      } else {
        ordered = _.orderBy(prepared_arr, key, this.order);
      }

      replaced_minus.forEach(uuid => {
        ordered.filter(profile => profile.uuid === uuid)[0].left_days = '-';
        ordered.filter(profile => profile.uuid === uuid)[0].is_payment = false;
      });
      this.filtered = ordered;

    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
              .then(url => {
                this.base64_qr = url;
              });
          }

        } else if (data.method === "update_auth") {

          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          }

          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", { data: data.data, profile_id: data.profile_id });
          if (data.data === 'close') {
            this.$swal.close();
          }
          // if (data.data === 'open') {
          //   let restore_on_open = document.querySelectorAll('.restore_on_open');
          //   restore_on_open.forEach(res => {
          //     res.classList.remove('disabled')
          //   })
          // }
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  props: ["filtered_profiles", "tariff_rub", "payments", "user", "all_sum", "all_profiles", "all_containers", "authorized_count", "token", "user_msg_price", "today_tasks_count", "all_tasks_count", "all_earned"],
  created() {
    console.log(this.user_msg_price);
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);

    if (!this.show_deleted) {
      this.filtered = this.filtered.filter(profile => profile.status)
    }

    this.filtered_profiles.map((fp) => {
      if (fp.wa_status == "open") {
        this.cont_open += 1;
      }
      if (fp.is_payment == true) {
        this.paid += 1;
      } else {
        if (fp.left_days !== '-') {
          this.not_paid += 1;
        }
      }
    });
    this.createWebSocketConnection();
  },
  mounted() {
    const sidebar = document.querySelector(".sidebar");
    if (window.screen.width < 768) {
      sidebar.classList.add("sidebar-mini");
    }

    // console.log(this.user);
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });


      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  computed: {
    filterProfiles: {
      get() {
        return this.filtered.filter(
          (profile) =>
            profile.name.toLowerCase().indexOf(this.search_name.toLowerCase()) >=
            0

        );
      }
    },

  },
  components: {
    Sidebar,
    Topbar,
  },
};
</script>
