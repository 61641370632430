var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Sidebar", {
        attrs: { all_profiles: "all_profiles" },
        on: { side_toggle: _vm.sidebar_toggle },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main-content" },
        [
          _c("Topbar", { on: { side_toggle: _vm.sidebar_toggle } }),
          _vm._v(" "),
          _c("div", { staticClass: "iq-navbar-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "iq-header-img" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container-fluid content-inner py-0 my-mt-5" },
              [
                _c("div", { staticClass: "row g-0 g-sm-2 g-md-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12",
                    },
                    [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                id: "mass_posting_form",
                                enctype: "multipart/form-data",
                              },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "is_inertia",
                                  value: "1",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-4" }, [
                                _vm._m(2),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6 col-md-6 mb-4" },
                                  [
                                    _c("div", { staticClass: "mass_from" }, [
                                      _c("span", [_vm._v("от")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.mass_timeout_from,
                                            expression:
                                              "form.mass_timeout_from",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: "600",
                                          name: "mass_timeout_from",
                                          id: "mass_timeout_from",
                                        },
                                        domProps: {
                                          value: _vm.form.mass_timeout_from,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.form,
                                              "mass_timeout_from",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6 col-md-6 mb-4" },
                                  [
                                    _c("div", { staticClass: "mass_from" }, [
                                      _c("span", [_vm._v("до")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.mass_timeout_to,
                                            expression: "form.mass_timeout_to",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: "600",
                                          name: "mass_timeout_to",
                                          id: "mass_timeout_to",
                                        },
                                        domProps: {
                                          value: _vm.form.mass_timeout_to,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.form,
                                              "mass_timeout_to",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "mb-4" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "spam_text" },
                                      },
                                      [_vm._v("Текст сообщения:")]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.mass_text,
                                          expression: "form.mass_text",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "spam_text",
                                        rows: "4",
                                        name: "mass_text",
                                        required: "",
                                        placeholder: "Введите текст",
                                      },
                                      domProps: { value: _vm.form.mass_text },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form,
                                            "mass_text",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _vm._m(3),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mb-4" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row",
                                          "justify-content": "space-between",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "phones" },
                                          },
                                          [
                                            _vm._v(
                                              "Телефоны в формате 79991231212, каждый с новой строки: "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticStyle: { color: "#23bb86" } },
                                          [_vm._v(_vm._s(_vm.skLines()))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.mass_phones,
                                          expression: "form.mass_phones",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "phones",
                                        rows: "10",
                                        name: "mass_phones",
                                        required: "",
                                        placeholder:
                                          "79991231212\n79112223344\n79221119977",
                                      },
                                      domProps: { value: _vm.form.mass_phones },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.form,
                                              "mass_phones",
                                              $event.target.value
                                            )
                                          },
                                          _vm.countLines,
                                        ],
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm._m(4),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.mailings.length > 0 || _vm.history.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12",
                        },
                        [
                          _c("div", { staticClass: "card text-black" }, [
                            _c(
                              "div",
                              { staticClass: "card-body" },
                              [
                                _vm.mailings.length > 0
                                  ? _c(
                                      "h2",
                                      { staticClass: "mass_title mb-4" },
                                      [_vm._v("Запущенные рассылки")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.mailings, function (mailing) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "started_task",
                                      class: { stoped: !mailing.have_consumer },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "started_close",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.cancel(mailing.uuid)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "15",
                                                height: "15",
                                                viewBox: "0 0 15 15",
                                                fill: "none",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  "clip-rule": "evenodd",
                                                  d: "M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L7.5 6.32149L13.5774 0.244078C13.9028 -0.0813592 14.4305 -0.0813592 14.7559 0.244078C15.0814 0.569515 15.0814 1.09715 14.7559 1.42259L8.67851 7.5L14.7559 13.5774C15.0814 13.9029 15.0814 14.4305 14.7559 14.7559C14.4305 15.0814 13.9028 15.0814 13.5774 14.7559L7.5 8.67851L1.42259 14.7559C1.09715 15.0814 0.569515 15.0814 0.244078 14.7559C-0.0813592 14.4305 -0.0813592 13.9029 0.244078 13.5774L6.32149 7.5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z",
                                                  fill: "#232D42",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "post_stat" }, [
                                        _c(
                                          "div",
                                          { staticClass: "post_stat_text" },
                                          [
                                            _vm._v(
                                              "Рассылка " +
                                                _vm._s(
                                                  _vm._f("wtz")(
                                                    mailing.created_at
                                                  )
                                                ) +
                                                " - " +
                                                _vm._s(mailing.uuid)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "post_count mt-2" },
                                        [
                                          _vm._v(
                                            _vm._s(mailing.done_count) + " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "light_green" },
                                            [
                                              _vm._v(
                                                "/ " +
                                                  _vm._s(mailing.messages_count)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(5, true),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "post_buttons mt-4" },
                                        [
                                          mailing.have_consumer
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-success resp-button",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.pause(
                                                        mailing.uuid
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex_button",
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          attrs: {
                                                            width: "12",
                                                            height: "15",
                                                            viewBox:
                                                              "0 0 12 15",
                                                            fill: "none",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              "fill-rule":
                                                                "evenodd",
                                                              "clip-rule":
                                                                "evenodd",
                                                              d: "M11.1277 1.36425C11.25 1.60425 11.25 1.91925 11.25 2.55V12.45C11.25 13.08 11.25 13.395 11.1277 13.6357C11.0197 13.8473 10.8475 14.0193 10.6358 14.127C10.3958 14.25 10.0807 14.25 9.45 14.25H9.3C8.67 14.25 8.355 14.25 8.11425 14.1278C7.90238 14.0199 7.73014 13.8476 7.62225 13.6357C7.5 13.3958 7.5 13.0808 7.5 12.45V2.55C7.5 1.92 7.5 1.605 7.62225 1.36425C7.73014 1.15238 7.90238 0.98014 8.11425 0.87225C8.35425 0.75 8.66925 0.75 9.3 0.75H9.45C10.08 0.75 10.395 0.75 10.6358 0.87225C10.8476 0.98014 11.0199 1.15238 11.1277 1.36425ZM4.37775 1.36425C4.5 1.60425 4.5 1.91925 4.5 2.55V12.45C4.5 13.08 4.5 13.395 4.377 13.6357C4.26918 13.8472 4.09723 14.0192 3.88575 14.127C3.64575 14.25 3.33075 14.25 2.7 14.25H2.55C1.92 14.25 1.605 14.25 1.36425 14.1278C1.15266 14.0197 0.980694 13.8475 0.872999 13.6357C0.75 13.3958 0.75 13.0808 0.75 12.45V2.55C0.75 1.92 0.75 1.605 0.87225 1.36425C0.98014 1.15238 1.15238 0.98014 1.36425 0.87225C1.60425 0.75 1.91925 0.75 2.55 0.75H2.7C3.33 0.75 3.645 0.75 3.88575 0.87225C4.09734 0.980267 4.27006 1.15249 4.37775 1.36425Z",
                                                              fill: "white",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Приостановить"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !mailing.have_consumer
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-success resp-button",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.start(
                                                        mailing.uuid
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex_button",
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          attrs: {
                                                            width: "12",
                                                            height: "15",
                                                            viewBox:
                                                              "0 0 12 15",
                                                            fill: "none",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M1.7145 1.05524C1.54406 0.950388 1.34875 0.89289 1.14868 0.888677C0.948619 0.884465 0.751053 0.933691 0.576355 1.03128C0.401657 1.12887 0.256151 1.27129 0.154839 1.44386C0.0535272 1.61642 7.70183e-05 1.81289 0 2.01299V12.987C7.70183e-05 13.1871 0.0535272 13.3836 0.154839 13.5561C0.256151 13.7287 0.401657 13.8711 0.576355 13.9687C0.751053 14.0663 0.948619 14.1155 1.14868 14.1113C1.34875 14.1071 1.54406 14.0496 1.7145 13.9447L10.6305 8.45774C10.7941 8.35705 10.9293 8.21613 11.023 8.04841C11.1167 7.88068 11.1659 7.69175 11.1659 7.49962C11.1659 7.30749 11.1167 7.11856 11.023 6.95083C10.9293 6.78311 10.7941 6.64219 10.6305 6.54149L1.7145 1.05524Z",
                                                              fill: "white",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Возобновить"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "inertia-link",
                                            {
                                              staticClass:
                                                "c-title text-decoration-underline billing-history",
                                              attrs: {
                                                href:
                                                  _vm.route(
                                                    "spam_messages_history"
                                                  ) +
                                                  "?id=" +
                                                  mailing.uuid,
                                              },
                                            },
                                            [_vm._v("История сообщений")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _vm.history.length > 0
                                  ? _c(
                                      "h2",
                                      { staticClass: "mass_title mb-4" },
                                      [_vm._v("История рассылок")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.history, function (his) {
                                  return _vm.history.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "history_task" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("iso")(his.created_at)
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { opacity: "0.4" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(his.mass_posting_id)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex_history_task",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "history_counter",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(his.done) + " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "light_green",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "/ " + _vm._s(his.all)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "inertia-link",
                                                    {
                                                      staticClass:
                                                        "c-title text-decoration-underline billing-history",
                                                      attrs: {
                                                        href:
                                                          _vm.route(
                                                            "spam_messages_history"
                                                          ) +
                                                          "?id=" +
                                                          his.mass_posting_id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "История сообщений"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e()
                                }),
                                _vm._v(" "),
                                _vm.history.length > 0 && _vm.all_pages > 0
                                  ? _c("v-pagination", {
                                      attrs: {
                                        "page-count": _vm.all_pages,
                                        classes: _vm.bootstrapPaginationClasses,
                                      },
                                      model: {
                                        value: _vm.now_page,
                                        callback: function ($$v) {
                                          _vm.now_page = $$v
                                        },
                                        expression: "now_page",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-fluid iq-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-wrap",
            },
            [
              _c("div", [
                _c("h1", { staticClass: "c-content" }, [
                  _vm._v("Рассылка сообщений"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "mass_title" }, [
      _vm._v("Новая рассылка (есть возможность разослать "),
      _c("span", { attrs: { id: "remained_count" } }, [_vm._v("0")]),
      _vm._v(" сообщений)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "label",
        { staticClass: "form-label", attrs: { for: "mass_timeout_from" } },
        [_vm._v("Минимальный таймаут между сообщениями, в секундах")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "mass_image" } }, [
        _vm._v("Прикрепить файл или картинку:"),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        staticStyle: { color: "#cccccb !important" },
        attrs: {
          type: "file",
          id: "mass_image",
          name: "mass_image",
          placeholder: "https://kartinka.ru/image.jpg",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 text-center" }, [
      _c("input", {
        staticClass: "btn btn-success resp-button",
        attrs: { type: "submit", value: "Запустить" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "post_count_update" }, [
      _c("span", { staticClass: "noselect" }, [
        _vm._v("выполнено задач на рассылку"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }