<template>
    <div>
        <div class="button_container" v-for="(button, index) in buttons">
            <input type="hidden" :value="JSON.stringify(button.trigger)" :name="'button['+index+'][trigger]'">
            <div class="button_line">
                <div class="title_button">
                    <div class="circle">{{ button.trigger }}</div>
                </div>
                <div class="title_button">
                    <svg v-if="buttons.length > 1" style="cursor: pointer" @click="delete_button(index)" width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4 9L18 1.4L16.6 0L9 7.6L1.4 0L0 1.4L7.6 9L0 16.6L1.4 18L9 10.4L16.6 18L18 16.6L10.4 9Z" fill="currentColor" />
                    </svg>
                </div>
            </div>
            <div class="mb16">
                <div class="label mb-2">Сообщение от пользователя/триггер срабатывания:</div>
                <div>
                    <input v-model="button.trigger" :id="'button_emoji_name_' + index" class="emoji_title form-control" placeholder="Цифра, символ или слово" autocomplete="off" type="text" :name="'button['+index+'][title]'" minlength="1" maxlength="100" required>
                </div>
            </div>
            <div>
                <div class="label mb-2">Текст ответа:</div>
            </div>
            <smart-textarea :exec_area_height="execAreaHeight" :placeholder="'Введите текст, который будет показываться при вызове этого пункта'" :uid="uuidv4() + index" :text.sync="button.description.text" :url.sync="button.description.url" :file.sync="button.description.file"></smart-textarea>
        </div>

        <a class="responder_add" v-if="buttons.length < 20" @click="add_button">
            <svg class="mr16" width="20" height="24" viewBox="0 0 20 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g id="plus-solid 1">
                <path id="Vector" d="M18.5714 9.75H12.1429V3C12.1429 2.17172 11.5031 1.5 10.7143 1.5H9.28571C8.49688 1.5 7.85714 2.17172 7.85714 3V9.75H1.42857C0.639732 9.75 0 10.4217 0 11.25V12.75C0 13.5783 0.639732 14.25 1.42857 14.25H7.85714V21C7.85714 21.8283 8.49688 22.5 9.28571 22.5H10.7143C11.5031 22.5 12.1429 21.8283 12.1429 21V14.25H18.5714C19.3603 14.25 20 13.5783 20 12.75V11.25C20 10.4217 19.3603 9.75 18.5714 9.75Z" fill="currentColor"/>
            </g>
            </svg>
            <span>Добавить пункт</span>
        </a>
    </div>
</template>


<script>
import SmartTextarea from './SmartTextarea.vue';

export default {
    data() {
        return {
            execAreaHeight: ""
        }
    },
    props: ['buttons', 'exec_area_height'],
    methods: {
        generateRandomString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        },
        exec_area() {
            this.execAreaHeight = this.generateRandomString(5);
        },
        uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },
        add_button() {
            this.buttons.push({
                trigger: "",
                description: { file: null, text: "", url: null }
            });
        },
        delete_button(index) {
            this.buttons.splice(index, 1);
        },
        close_all_modals() {
            let modals = document.querySelectorAll('.my_modal');
            modals.forEach(modal => {
                modal.classList.remove('active')
            });
            document.querySelector('.modal_shadow').classList.remove('active');
        },
    },
    watch: {
        buttons(newButton, oldButton) {
            this.$emit('update:buttons', newButton);
        },
        exec_area_height(newQuestion, oldQuestion) {
            this.exec_area();
        }
    },
    mounted() {
        
    },
    components: {
        SmartTextarea
    },
}
</script>
