var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "input_wrapper", staticStyle: { "margin-bottom": "3px" } },
      [
        _c("div", { staticClass: "form min_h110" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.local_text,
                expression: "local_text",
              },
            ],
            ref: "waba_textarea",
            staticClass: "bugs_textarea emoji_title",
            class: { oy_scroll: _vm.check_height() },
            attrs: {
              placeholder: _vm.placeholder,
              id: "textarea_" + _vm.uid,
              autocomplete: "off",
              maxlength: "1028",
              minlength: "1",
            },
            domProps: { value: _vm.local_text },
            on: {
              change: _vm.resText,
              drop: _vm.resText,
              cut: _vm.deResText,
              paste: _vm.deResText,
              keydown: _vm.deResText,
              scroll: _vm.scrollStop,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.local_text = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "upload_files",
            staticStyle: { "justify-content": "end" },
          },
          [
            _c("div", { staticClass: "symbol_length symbol_length_waba" }, [
              _c(
                "span",
                { staticClass: "d-none d-sm-inline waba_textarea_symbol_desc" },
                [_vm._v("Символы: ")]
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.text_length_comp()) + "/1028")]),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c(
          "span",
          { staticClass: "input_desc" },
          [
            _c(
              "div",
              [
                _c("b", [_vm._v("переменные:")]),
                _vm._l(_vm.text_variables.fields, function (vval, vkey) {
                  return _c("span", { staticClass: "ms-1" }, [
                    _c(
                      "a",
                      {
                        staticClass: "crm_var",
                        attrs: { href: "", title: vkey },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.insertAtCaret(vkey)
                          },
                        },
                      },
                      [_vm._v(_vm._s(vkey))]
                    ),
                    _vm._v(" - " + _vm._s(vval) + ";"),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.text_variables.links, function (link) {
              return _c("div", [
                _c(
                  "span",
                  [
                    _c("b", [_vm._v(_vm._s(link.name) + ":")]),
                    _vm._v(" "),
                    _vm._l(link.fields, function (vv, vk) {
                      return _c("span", { staticClass: "ms-1" }, [
                        _c(
                          "a",
                          {
                            staticClass: "crm_var ms-1",
                            attrs: { href: "", title: vv },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.insertAtCaret(vk)
                              },
                            },
                          },
                          [_vm._v(_vm._s(vk))]
                        ),
                        _vm._v(" - " + _vm._s(vv) + ";"),
                      ])
                    }),
                  ],
                  2
                ),
              ])
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }