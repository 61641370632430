<template>
  <div class="iq-navbar-header">
    <div class="container-fluid iq-container">
      
      
    </div>
    
    <!--    main block-->
    <div class="container-fluid content-inner py-0 my-mt-5" :style="this.$store.state.user.moysklad ? 'min-height:initial !important' : ''">
      
      
      
    </div>
    <Footer v-if="!this.$store.state.user.moysklad"></Footer>
  </div>
</template>
<script>
import Footer from './Footer.vue';
export default {
  props: {
    
  },
  components: {
    Footer,
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  created() {
    
  },
  mounted() {
  
  },
};
</script>