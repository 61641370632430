var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.buttons, function (button, index) {
        return _c(
          "div",
          { staticClass: "button_container" },
          [
            _c("input", {
              attrs: { type: "hidden", name: "button[" + index + "][trigger]" },
              domProps: { value: JSON.stringify(button.trigger) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "button_line" }, [
              _c("div", { staticClass: "title_button" }, [
                _c("div", { staticClass: "circle" }, [
                  _vm._v(_vm._s(button.trigger)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title_button" }, [
                _vm.buttons.length > 1
                  ? _c(
                      "svg",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          width: "18",
                          height: "18",
                          viewBox: "0 0 18 18",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.delete_button(index)
                          },
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M10.4 9L18 1.4L16.6 0L9 7.6L1.4 0L0 1.4L7.6 9L0 16.6L1.4 18L9 10.4L16.6 18L18 16.6L10.4 9Z",
                            fill: "currentColor",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb16" }, [
              _c("div", { staticClass: "label mb-2" }, [
                _vm._v("Сообщение от пользователя/триггер срабатывания:"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: button.trigger,
                      expression: "button.trigger",
                    },
                  ],
                  staticClass: "emoji_title form-control",
                  attrs: {
                    id: "button_emoji_name_" + index,
                    placeholder: "Цифра, символ или слово",
                    autocomplete: "off",
                    type: "text",
                    name: "button[" + index + "][title]",
                    minlength: "1",
                    maxlength: "100",
                    required: "",
                  },
                  domProps: { value: button.trigger },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(button, "trigger", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0, true),
            _vm._v(" "),
            _c("smart-textarea", {
              attrs: {
                exec_area_height: _vm.execAreaHeight,
                placeholder:
                  "Введите текст, который будет показываться при вызове этого пункта",
                uid: _vm.uuidv4() + index,
                text: button.description.text,
                url: button.description.url,
                file: button.description.file,
              },
              on: {
                "update:text": function ($event) {
                  return _vm.$set(button.description, "text", $event)
                },
                "update:url": function ($event) {
                  return _vm.$set(button.description, "url", $event)
                },
                "update:file": function ($event) {
                  return _vm.$set(button.description, "file", $event)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.buttons.length < 20
        ? _c(
            "a",
            { staticClass: "responder_add", on: { click: _vm.add_button } },
            [
              _c(
                "svg",
                {
                  staticClass: "mr16",
                  attrs: {
                    width: "20",
                    height: "24",
                    viewBox: "0 0 20 24",
                    fill: "currentColor",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("g", { attrs: { id: "plus-solid 1" } }, [
                    _c("path", {
                      attrs: {
                        id: "Vector",
                        d: "M18.5714 9.75H12.1429V3C12.1429 2.17172 11.5031 1.5 10.7143 1.5H9.28571C8.49688 1.5 7.85714 2.17172 7.85714 3V9.75H1.42857C0.639732 9.75 0 10.4217 0 11.25V12.75C0 13.5783 0.639732 14.25 1.42857 14.25H7.85714V21C7.85714 21.8283 8.49688 22.5 9.28571 22.5H10.7143C11.5031 22.5 12.1429 21.8283 12.1429 21V14.25H18.5714C19.3603 14.25 20 13.5783 20 12.75V11.25C20 10.4217 19.3603 9.75 18.5714 9.75Z",
                        fill: "currentColor",
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("Добавить пункт")]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "label mb-2" }, [_vm._v("Текст ответа:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }