export const templates = [
    {
        "id": "",
        "type": "responder",
        "name": "Автоответчик",
        "status": true,
        "uuid": "",
        "work_place": "all",
        "repeat": "interval",
        "repeat_interval": 1440,
        "to_whom": "all",
        "new_contact_days": 30,
        "main_text": {
          "file": null,
          "text": "Привет.\n\nНа связи Whatsapp помощник, со мной можно общаться с помощью команд\n\n*Отправляйте по одной цифре*👇: \n*1*  🌐 Информация о нас\n*2*  🗃️ Каталог товаров\n*3*  🎉 Акции\n*4*  🔎 Проверить заказ\n*5*  🕹️ Плюмбус\n",
          "url": null
        },
        "interactive": {
          "status": false,
          "menu": [
            {
              "trigger": "1",
              "description": {
                "file": null,
                "text": "",
                "url": null
              }
            },
            {
              "trigger": "2",
              "description": {
                "file": null,
                "text": "",
                "url": null
              }
            }
          ]
        },
        "diary": {
          "status": true,
          "week": [
            {
              "title": "Понедельник",
              "enable": true,
              "day": 0,
              "time": [
                {
                  "from": "10:00",
                  "to": "21:00"
                }
              ]
            },
            {
              "title": "Вторник",
              "enable": true,
              "day": 1,
              "time": [
                {
                  "from": "10:00",
                  "to": "21:00"
                }
              ]
            },
            {
              "title": "Среда",
              "enable": true,
              "day": 2,
              "time": [
                {
                  "from": "10:00",
                  "to": "21:00"
                }
              ]
            },
            {
              "title": "Четверг",
              "enable": true,
              "day": 3,
              "time": [
                {
                  "from": "10:00",
                  "to": "21:00"
                }
              ]
            },
            {
              "title": "Пятница",
              "enable": true,
              "day": 4,
              "time": [
                {
                  "from": "10:00",
                  "to": "21:00"
                }
              ]
            },
            {
              "title": "Суббота",
              "enable": false,
              "day": 5,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            },
            {
              "title": "Воскресенье",
              "enable": false,
              "day": 6,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            }
          ]
        }
    },
    {
      "id": "",
      "type": "interactive_menu",
      "name": "Интерактивное меню",
      "status": true,
      "uuid": "",
      "work_place": "all",
      "repeat": "always",
      "repeat_interval": 1440,
      "to_whom": "all",
      "new_contact_days": 30,
      "main_text": {
        "file": null,
        "text": null,
        "url": null
      },
      "interactive": {
        "status": true,
        "menu": [
          {
            "trigger": "#",
            "description": {
              "file": null,
              "text": "*Отправляйте по одной цифре*👇: \n*1*  🌐 Информация о нас\n*2*  🗃️ Каталог товаров\n*3*  🎉 Акции\n*4*  🔎 Проверить заказ\n*5*  🕹️ Плюмбус",
              "url": null
            }
          },
          {
            "trigger": "1",
            "description": {
              "file": null,
              "text": "🌐 Здесь будет информация о нас\n\n_____\n# - возврат в меню",
              "url": null
            }
          },
          {
            "trigger": "2",
            "description": {
              "file": null,
              "text": "🗃️ Здесь будет каталог товаров\n\n_____\n# - возврат в меню",
              "url": null
            }
          },
          {
            "trigger": "3",
            "description": {
              "file": null,
              "text": "🎉 Здесь будет информация об акциях\n\n_____\n# - возврат в меню",
              "url": null
            }
          },
          {
            "trigger": "4",
            "description": {
              "file": null,
              "text": "🔎 Проверить заказ\n\n_____\n# - возврат в меню",
              "url": null
            }
          },
          {
            "trigger": "5",
            "description": {
              "file": null,
              "text": "🕹️ Плюмбус\n\n_____\n# - возврат в меню",
              "url": null
            }
          }
        ]
      },
      "diary": {
        "status": false,
        "week": [
          {
            "title": "Понедельник",
            "enable": false,
            "day": 0,
            "time": [
              {
                "from": "11:00",
                "to": "14:00"
              }
            ]
          },
          {
            "title": "Вторник",
            "enable": false,
            "day": 1,
            "time": [
              {
                "from": "11:00",
                "to": "23:00"
              }
            ]
          },
          {
            "title": "Среда",
            "enable": false,
            "day": 2,
            "time": [
              {
                "from": "11:00",
                "to": "23:00"
              }
            ]
          },
          {
            "title": "Четверг",
            "enable": false,
            "day": 3,
            "time": [
              {
                "from": "11:00",
                "to": "23:00"
              }
            ]
          },
          {
            "title": "Пятница",
            "enable": false,
            "day": 4,
            "time": [
              {
                "from": "11:00",
                "to": "23:00"
              }
            ]
          },
          {
            "title": "Суббота",
            "enable": false,
            "day": 5,
            "time": [
              {
                "from": "11:00",
                "to": "23:00"
              }
            ]
          },
          {
            "title": "Воскресенье",
            "enable": false,
            "day": 6,
            "time": [
              {
                "from": "11:00",
                "to": "23:00"
              }
            ]
          }
        ]
      }
  },
    {
        "id": "",
        "type": "hello_new",
        "name": "Приветствие новых клиентов",
        "status": true,
        "uuid": "",
        "work_place": "all",
        "repeat": "always",
        "repeat_interval": 1440,
        "to_whom": "new",
        "new_contact_days": 30,
        "main_text": {
          "file": null,
          "text": "Вы у нас впервые, поэтому мы дарим вам скидку 30% на первый заказ по промокоду NEW",
          "url": null
        },
        "interactive": {
          "status": true,
          "menu": [
            {
              "trigger": "1",
              "description": {
                "file": null,
                "text": "",
                "url": null
              }
            },
            {
              "trigger": "2",
              "description": {
                "file": null,
                "text": "",
                "url": null
              }
            }
          ]
        },
        "diary": {
          "status": false,
          "week": [
            {
              "title": "Понедельник",
              "enable": false,
              "day": 0,
              "time": [
                {
                  "from": "11:00",
                  "to": "14:00"
                }
              ]
            },
            {
              "title": "Вторник",
              "enable": false,
              "day": 1,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            },
            {
              "title": "Среда",
              "enable": false,
              "day": 2,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            },
            {
              "title": "Четверг",
              "enable": false,
              "day": 3,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            },
            {
              "title": "Пятница",
              "enable": false,
              "day": 4,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            },
            {
              "title": "Суббота",
              "enable": false,
              "day": 5,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            },
            {
              "title": "Воскресенье",
              "enable": false,
              "day": 6,
              "time": [
                {
                  "from": "11:00",
                  "to": "23:00"
                }
              ]
            }
          ]
        }
    },
    {
        "id": "",
        "type": "afk",
        "name": "Нет на месте",
        "status": true,
        "uuid": "",
        "work_place": "all",
        "repeat": "interval",
        "repeat_interval": 360,
        "to_whom": "all",
        "new_contact_days": 30,
        "main_text": {
          "file": null,
          "text": "Мы сейчас не работаем, откроемся в 10 утра!\n\nПока вы можете воспользоваться нашим Whatsapp помощником.\n\n*Отправляйте по одной цифре*👇: \n*1*  🌐 Информация о нас\n*2*  🗃️ Каталог товаров\n*3*  🎉 Акции\n*4*  🔎 Проверить заказ\n*5*  🕹️ Плюмбус",
          "url": null
        },
        "interactive": {
          "status": false,
          "menu": [
            {
              "trigger": "1",
              "description": {
                "file": null,
                "text": "",
                "url": null
              }
            },
            {
              "trigger": "2",
              "description": {
                "file": null,
                "text": "",
                "url": null
              }
            }
          ]
        },
        "diary": {
          "status": true,
          "week": [
            {
              "title": "Понедельник",
              "enable": true,
              "day": 0,
              "time": [
                {
                  "from": "00:00",
                  "to": "09:59"
                },
                {
                  "from": "21:00",
                  "to": "23:59"
                }
              ]
            },
            {
              "title": "Вторник",
              "enable": true,
              "day": 1,
              "time": [
                {
                  "from": "00:00",
                  "to": "09:59"
                },
                {
                  "from": "21:00",
                  "to": "23:59"
                }
              ]
            },
            {
              "title": "Среда",
              "enable": true,
              "day": 2,
              "time": [
                {
                  "from": "00:00",
                  "to": "09:59"
                },
                {
                  "from": "21:00",
                  "to": "23:59"
                }
              ]
            },
            {
              "title": "Четверг",
              "enable": true,
              "day": 3,
              "time": [
                {
                  "from": "00:00",
                  "to": "09:59"
                },
                {
                  "from": "21:00",
                  "to": "23:59"
                }
              ]
            },
            {
              "title": "Пятница",
              "enable": true,
              "day": 4,
              "time": [
                {
                  "from": "00:00",
                  "to": "09:59"
                },
                {
                  "from": "21:00",
                  "to": "23:59"
                }
              ]
            },
            {
              "title": "Суббота",
              "enable": true,
              "day": 5,
              "time": [
                {
                  "from": "00:00",
                  "to": "23:59"
                }
              ]
            },
            {
              "title": "Воскресенье",
              "enable": true,
              "day": 6,
              "time": [
                {
                  "from": "00:00",
                  "to": "23:59"
                }
              ]
            }
          ]
        }
    },
    {
      "id": "",
      "type": "missed_call",
      "name": "Пропущенный звонок",
      "status": true,
      "uuid": "",
      "work_place": "all",
      "repeat": "interval",
      "repeat_interval": 360,
      "to_whom": "all",
      "new_contact_days": 30,
      "main_text": {
        "file": null,
        "text": "Мы сейчас не работаем, откроемся в 10 утра!\n\nПока вы можете воспользоваться нашим Whatsapp помощником.\n\n*Отправляйте по одной цифре*👇: \n*1*  🌐 Информация о нас\n*2*  🗃️ Каталог товаров\n*3*  🎉 Акции\n*4*  🔎 Проверить заказ\n*5*  🕹️ Плюмбус",
        "url": null
      },
      "interactive": {
        "status": false,
        "menu": [
          {
            "trigger": "1",
            "description": {
              "file": null,
              "text": "",
              "url": null
            }
          },
          {
            "trigger": "2",
            "description": {
              "file": null,
              "text": "",
              "url": null
            }
          }
        ]
      },
      "diary": {
        "status": true,
        "week": [
          {
            "title": "Понедельник",
            "enable": true,
            "day": 0,
            "time": [
              {
                "from": "00:00",
                "to": "09:59"
              },
              {
                "from": "21:00",
                "to": "23:59"
              }
            ]
          },
          {
            "title": "Вторник",
            "enable": true,
            "day": 1,
            "time": [
              {
                "from": "00:00",
                "to": "09:59"
              },
              {
                "from": "21:00",
                "to": "23:59"
              }
            ]
          },
          {
            "title": "Среда",
            "enable": true,
            "day": 2,
            "time": [
              {
                "from": "00:00",
                "to": "09:59"
              },
              {
                "from": "21:00",
                "to": "23:59"
              }
            ]
          },
          {
            "title": "Четверг",
            "enable": true,
            "day": 3,
            "time": [
              {
                "from": "00:00",
                "to": "09:59"
              },
              {
                "from": "21:00",
                "to": "23:59"
              }
            ]
          },
          {
            "title": "Пятница",
            "enable": true,
            "day": 4,
            "time": [
              {
                "from": "00:00",
                "to": "09:59"
              },
              {
                "from": "21:00",
                "to": "23:59"
              }
            ]
          },
          {
            "title": "Суббота",
            "enable": true,
            "day": 5,
            "time": [
              {
                "from": "00:00",
                "to": "23:59"
              }
            ]
          },
          {
            "title": "Воскресенье",
            "enable": true,
            "day": 6,
            "time": [
              {
                "from": "00:00",
                "to": "23:59"
              }
            ]
          }
        ]
      }
  },
  {
    "id": "",
    "type": "self_template",
    "name": "Свой шаблон",
    "status": true,
    "uuid": "",
    "work_place": "all",
    "repeat": "always",
    "repeat_interval": 1440,
    "to_whom": "all",
    "new_contact_days": 30,
    "main_text": {
      "file": null,
      "text": null,
      "url": null
    },
    "interactive": {
      "status": false,
      "menu": [
        {
          "trigger": "1",
          "description": {
            "file": null,
            "text": "",
            "url": null
          }
        },
        {
          "trigger": "2",
          "description": {
            "file": null,
            "text": "",
            "url": null
          }
        }
      ]
    },
    "diary": {
      "status": false,
      "week": [
        {
          "title": "Понедельник",
          "enable": false,
          "day": 0,
          "time": [
            {
              "from": "11:00",
              "to": "14:00"
            }
          ]
        },
        {
          "title": "Вторник",
          "enable": false,
          "day": 1,
          "time": [
            {
              "from": "11:00",
              "to": "23:00"
            }
          ]
        },
        {
          "title": "Среда",
          "enable": false,
          "day": 2,
          "time": [
            {
              "from": "11:00",
              "to": "23:00"
            }
          ]
        },
        {
          "title": "Четверг",
          "enable": false,
          "day": 3,
          "time": [
            {
              "from": "11:00",
              "to": "23:00"
            }
          ]
        },
        {
          "title": "Пятница",
          "enable": false,
          "day": 4,
          "time": [
            {
              "from": "11:00",
              "to": "23:00"
            }
          ]
        },
        {
          "title": "Суббота",
          "enable": false,
          "day": 5,
          "time": [
            {
              "from": "11:00",
              "to": "23:00"
            }
          ]
        },
        {
          "title": "Воскресенье",
          "enable": false,
          "day": 6,
          "time": [
            {
              "from": "11:00",
              "to": "23:00"
            }
          ]
        }
      ]
    }
},
];