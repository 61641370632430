<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <div>
                  <h1 class="c-content">Рассылка сообщений</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row g-0 g-sm-2 g-md-4">
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="mass_title">Новая рассылка (есть возможность разослать <span id="remained_count">0</span> сообщений)</h2>

                  <form class="mt-3" @submit.prevent="submit" id="mass_posting_form" enctype="multipart/form-data">
                  <input type="hidden" name="is_inertia" value="1">
                  <div class="row mt-4">
                    <!-- <div class="col-12 col-md-12 mb-4">
                        <label for="mass_uuid" class="form-label">Запустить на профиле:</label>
                        <select id="mass_uuid" class="form-select" name="mass_profile" v-model="form.mass_profile">
                          <option value="0" disabled selected>Выберите профиль</option>
                          <option v-for="profile in profiles" :value="profile.uuid">{{ profile.name }}</option>
                        </select>
                    </div> -->
                    <div class="col-12"><label for="mass_timeout_from" class="form-label">Минимальный таймаут между сообщениями, в секундах</label></div>
                    <div class="col-6 col-md-6 mb-4">
                      <div class="mass_from">
                        <span>от</span>
                        <input type="number" min="1" max="600" v-model="form.mass_timeout_from" name="mass_timeout_from" class="form-control" id="mass_timeout_from">
                      </div>
                    </div>
                    <div class="col-6 col-md-6 mb-4">
                      <div class="mass_from">
                        <span>до</span>
                        <input type="number" min="1" max="600" v-model="form.mass_timeout_to" name="mass_timeout_to" class="form-control" id="mass_timeout_to">
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-4">
                          <label for="spam_text" class="form-label">Текст сообщения:</label>
                          <textarea v-model="form.mass_text" class="form-control" id="spam_text" rows="4" name="mass_text" required placeholder="Введите текст"></textarea>
                      </div>
                      <div class="mb-4">
                          <label for="mass_image" class="form-label">Прикрепить файл или картинку:</label>
                          <input style="color:#cccccb !important;" type="file" class="form-control" id="mass_image" name="mass_image" placeholder="https://kartinka.ru/image.jpg">
                      </div> <!-- v-model="form.mass_image" -->

                      <div class="mb-4">
                          <div style="display: flex;flex-direction: row; justify-content: space-between;">
                            <label for="phones" class="form-label">Телефоны в формате 79991231212, каждый с новой строки: </label>
                            <div style="color: #23bb86">{{ skLines() }}</div>
                          </div>

                          <textarea v-model="form.mass_phones" @input="countLines" class="form-control" id="phones" rows="10" name="mass_phones" required placeholder="79991231212
79112223344
79221119977"></textarea>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                        <input type="submit" value="Запустить" class="btn btn-success resp-button">
                    </div>
                  </div>
                  </form>

                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12" v-if="mailings.length > 0 || history.length > 0">
              <div class="card text-black">
                <div class="card-body">
                  <h2 class="mass_title mb-4" v-if="mailings.length > 0">Запущенные рассылки</h2>
                  
                  <!-- started tasks -->
                  <div class="started_task" v-for="mailing in mailings" :class="{ 'stoped': !mailing.have_consumer }">
                    <div class="started_close" @click.prevent="cancel(mailing.uuid)">
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L7.5 6.32149L13.5774 0.244078C13.9028 -0.0813592 14.4305 -0.0813592 14.7559 0.244078C15.0814 0.569515 15.0814 1.09715 14.7559 1.42259L8.67851 7.5L14.7559 13.5774C15.0814 13.9029 15.0814 14.4305 14.7559 14.7559C14.4305 15.0814 13.9028 15.0814 13.5774 14.7559L7.5 8.67851L1.42259 14.7559C1.09715 15.0814 0.569515 15.0814 0.244078 14.7559C-0.0813592 14.4305 -0.0813592 13.9029 0.244078 13.5774L6.32149 7.5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z" fill="#232D42"/>
                      </svg>
                    </div>
                    <div class="post_stat">
                      <div class="post_stat_text">Рассылка {{ mailing.created_at | wtz }} - {{ mailing.uuid }}</div>
                    </div>
                    <div class="post_count mt-2">{{ mailing.done_count }} <span class="light_green">/ {{ mailing.messages_count }}</span></div>
                    <div class="post_count_update">
                      <span class="noselect">выполнено задач на рассылку</span>
                    </div>
                    <div class="post_buttons mt-4">
                      <a v-if="mailing.have_consumer" href="#" @click.prevent="pause(mailing.uuid)" class="btn btn-success resp-button">
                        <div class="flex_button">
                          <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1277 1.36425C11.25 1.60425 11.25 1.91925 11.25 2.55V12.45C11.25 13.08 11.25 13.395 11.1277 13.6357C11.0197 13.8473 10.8475 14.0193 10.6358 14.127C10.3958 14.25 10.0807 14.25 9.45 14.25H9.3C8.67 14.25 8.355 14.25 8.11425 14.1278C7.90238 14.0199 7.73014 13.8476 7.62225 13.6357C7.5 13.3958 7.5 13.0808 7.5 12.45V2.55C7.5 1.92 7.5 1.605 7.62225 1.36425C7.73014 1.15238 7.90238 0.98014 8.11425 0.87225C8.35425 0.75 8.66925 0.75 9.3 0.75H9.45C10.08 0.75 10.395 0.75 10.6358 0.87225C10.8476 0.98014 11.0199 1.15238 11.1277 1.36425ZM4.37775 1.36425C4.5 1.60425 4.5 1.91925 4.5 2.55V12.45C4.5 13.08 4.5 13.395 4.377 13.6357C4.26918 13.8472 4.09723 14.0192 3.88575 14.127C3.64575 14.25 3.33075 14.25 2.7 14.25H2.55C1.92 14.25 1.605 14.25 1.36425 14.1278C1.15266 14.0197 0.980694 13.8475 0.872999 13.6357C0.75 13.3958 0.75 13.0808 0.75 12.45V2.55C0.75 1.92 0.75 1.605 0.87225 1.36425C0.98014 1.15238 1.15238 0.98014 1.36425 0.87225C1.60425 0.75 1.91925 0.75 2.55 0.75H2.7C3.33 0.75 3.645 0.75 3.88575 0.87225C4.09734 0.980267 4.27006 1.15249 4.37775 1.36425Z" fill="white"/>
                          </svg>
                          <span>Приостановить</span>
                        </div>
                      </a>
                      <a v-if="!mailing.have_consumer" href="#" @click.prevent="start(mailing.uuid)" class="btn btn-success resp-button">
                        <div class="flex_button">
                          <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.7145 1.05524C1.54406 0.950388 1.34875 0.89289 1.14868 0.888677C0.948619 0.884465 0.751053 0.933691 0.576355 1.03128C0.401657 1.12887 0.256151 1.27129 0.154839 1.44386C0.0535272 1.61642 7.70183e-05 1.81289 0 2.01299V12.987C7.70183e-05 13.1871 0.0535272 13.3836 0.154839 13.5561C0.256151 13.7287 0.401657 13.8711 0.576355 13.9687C0.751053 14.0663 0.948619 14.1155 1.14868 14.1113C1.34875 14.1071 1.54406 14.0496 1.7145 13.9447L10.6305 8.45774C10.7941 8.35705 10.9293 8.21613 11.023 8.04841C11.1167 7.88068 11.1659 7.69175 11.1659 7.49962C11.1659 7.30749 11.1167 7.11856 11.023 6.95083C10.9293 6.78311 10.7941 6.64219 10.6305 6.54149L1.7145 1.05524Z" fill="white"/>
                          </svg>
                          <span>Возобновить</span>
                        </div>
                      </a>
                      <inertia-link :href="route('spam_messages_history') + '?id=' + mailing.uuid" class="c-title text-decoration-underline billing-history">История сообщений</inertia-link>
                    </div>
                  </div>
                  
                  <h2 v-if="history.length > 0" class="mass_title mb-4">История рассылок</h2>
                  <!-- started tasks -->
                  <div v-if="history.length > 0" class="history_task" v-for="his in history">
                    <div>{{ his.created_at | iso }} <span style="opacity: 0.4;">{{ his.mass_posting_id }}</span></div>
                    <div class="flex_history_task">
                      <div class="history_counter">{{ his.done }} <span class="light_green">/ {{ his.all }}</span></div>
                      <div>
                        <inertia-link :href="route('spam_messages_history') + '?id=' + his.mass_posting_id" class="c-title text-decoration-underline billing-history">История сообщений</inertia-link>
                      </div>
                    </div>
                  </div>
                  <v-pagination v-if="history.length > 0 && all_pages > 0" v-model="now_page" :page-count="all_pages" :classes="bootstrapPaginationClasses"></v-pagination>
                  <!-- /end -->
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </main>
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';
import vPagination from 'vue-plain-pagination'
import moment from "moment";

export default {
  data() {
    return {
        error:'',
        name: '',
        email: '',
        message:'',
        linesCount: 0,
        mailings: [],
        form: {
          mass_timeout_from: 10,
          mass_timeout_to: 20,
          mass_text: null,
          mass_phones: null,
          mass_image: '',
          is_inertia: 1,
        },
        history: [],
        messages_on_page: 5,
        all_pages: 1,
        now_page: 1,
        bootstrapPaginationClasses: {
          ul: 'pagination',
          li: 'page-item',
          liActive: 'active',
          liDisable: 'disabled',
          button: 'page-link'
        },
    };
  },
  filters: {
    wtz: function (date) {
      return moment(date, "DD.MM.YYYY HH:mm:ss").add(3, 'hours').format("DD.MM.YYYY HH:mm:ss");
    },
    iso: function (value) {
      if (value) {
        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      }
    },
  },
  methods: {
    sklonenie(number, txt) {
      let cases = [2, 0, 1, 1, 1, 2];
      return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
    countLines() {
      if (this.form.mass_phones !== null) {
        this.linesCount = this.form.mass_phones.split('\n').length;
      }
    },
    skLines() {
      // return this.linesCount + ' ' + this.sklonenie(this.linesCount, ['номер', 'номера', 'номеров']);
      return this.linesCount;
    },
    async delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    submit() {
      const form = document.querySelector('#mass_posting_form');
      const formData = new FormData(form);
      this.$axios.post(
        "https://" +
        window.location.host +
        "/api/spam/posting?is_spam=true&profile_id=" + this.any_user_profile.uuid,
        formData,
        {
          headers: {
            Authorization: this.$store.state.token,
          },
        }
      ).then(response => {
        let data = response.data;
        // console.log(data);
        if(response.data.status === "done") {
          this.form.mass_timeout_from = 10;
          this.form.mass_timeout_to = 20;
          this.form.mass_text = null;
          this.form.mass_phones = null;
          this.form.mass_image = null;
          this.form.is_inertia = 1;

          let mailing = {
            done_count:  data.posting.messages_count - data.posting.active,
            messages_count: data.posting.messages_count,
            profile_uuid: data.posting.profile_uuid,
            user_id: data.posting.user_id,
            uuid: data.posting.uuid,
            profile_name: data.posting.profile_name, 
            created_at: data.posting.created_at,
            have_consumer: data.posting.have_consumer,
          }
          // console.log("mailing");
          // console.log(mailing);
          this.mailings.push(mailing);

          window.scrollTo(0, 0);
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Рассылка успешно запущена",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 3000,
          });
        }
        }).catch(error => {
            if (error.response && error.response.data) {
              const { status, detail } = error.response.data; 
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: status,
                title: detail,
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
              return false;
            }
        });
    },
    cancel(queue) {
      if (confirm("Действительно хотите отменить рассылку?")) {
        let user_token = document.querySelector("#user_token").innerHTML;
        this.$axios
          .get(
            "https://" +
            window.location.host +
            "/api/spam/posting/cancel?mass_posting_id=" + queue + "&profile_id=" +
            this.any_user_profile.uuid,
            {
              headers: {
                Authorization: user_token,
              },
            }
          )
          .then((data) => {
            if (data.data.status === 'done') {
              let index = this.mailings.findIndex(item => item.uuid === queue);
              if (index !== -1) {
                this.mailings.splice(index, 1);
                this.updateHistory();
                this.$swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: "success",
                  title: "Рассылка отменена",
                  timerProgressBar: true,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }
          });
      }
      
    },
    pause(queue) {
      if (confirm("Действительно поставить рассылку на паузу?")) {
        let user_token = document.querySelector("#user_token").innerHTML;
        this.$axios
          .get(
            "https://" +
            window.location.host +
            "/api/spam/posting/pause?mass_posting_id=" + queue + "&profile_id=" +
            this.any_user_profile.uuid,
            {
              headers: {
                Authorization: user_token,
              },
            }
          )
          .then((data) => {
            if (data.data.status === 'done') {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Поставили рассылку на паузу",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
      }
    },
    start(queue) {
      if (confirm("Действительно возобновляем рассылку?")) {
        let user_token = document.querySelector("#user_token").innerHTML;
        this.$axios
          .get(
            "https://" +
            window.location.host +
            "/api/spam/posting/start?mass_posting_id=" + queue + "&profile_id=" +
            this.any_user_profile.uuid,
            {
              headers: {
                Authorization: user_token,
              },
            }
          )
          .then((data) => {
            if (data.data.status === 'done') {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Возобновили рассылку",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
      }
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        // console.log(data)
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (typeof this.$store.state.current_profile !== 'undefined' && data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          this.$store.commit("set_status", { auth: data.data, profile_id: data.profile_id });
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'open') {
            let restore_on_open = document.querySelectorAll('.restore_on_open');
            restore_on_open.forEach(res => {
              res.classList.remove('disabled')
            })
          }
        } else if (data.method === "mass_posting") {
          // console.log(data);
          // console.log("this.mailings");
          // console.log(this.mailings);
          let index = this.mailings.findIndex(item => item.uuid === data.data);
          if (index !== -1) {
            this.mailings[index].done_count = parseInt(this.mailings[index].done_count) + 1;
          }
        } else if (data.method === "close_mass_posting") {
          let index = this.mailings.findIndex(item => item.uuid === data.data);
          if (index !== -1) {
            this.mailings.splice(index, 1);
            this.updateHistory();
          }
        } else if (data.method === "stop_mass_consumer") {
          let index = this.mailings.findIndex(item => item.uuid === data.data); 
          if (index !== -1) { 
            this.mailings[index].have_consumer = false;
          }
        } else if (data.method === "start_mass_consumer") {
          let index = this.mailings.findIndex(item => item.uuid === data.data); 
          if (index !== -1) { 
            this.mailings[index].have_consumer = true;
          }
        }
      };
    },
    updateHistory() {
      let jsonMailings = [];
      if (this.mailings === null) {
        this.mailings = [];
      }
      if (this.mailings.length > 0) {
        this.mailings.forEach(m => {
          jsonMailings.push(m.uuid);
        })
      }
      let user_token = document.querySelector("#user_token").innerHTML;
      
      this.$axios
      .get(
        "https://" +
        window.location.host +
        "/api/spam/posting/history?is_spam=true&exclude=" + JSON.stringify(jsonMailings) + "&limit="+ this.messages_on_page +"&offset=0&profile_id=" +
        this.any_user_profile.uuid,
        {
          headers: {
            Authorization: user_token,
          },
        }
      )
      .then((data) => {
        // let copy = JSON.parse(JSON.stringify(data.data));
        // console.log(copy);
        // console.log(data.data);
        if (data.data.status === "done") {
          let remained_count = document.querySelector("#remained_count");
          remained_count.innerHTML = data.data.ready_to_send;
          let consumers = data.data.consumers
          let mass_posting_history = data.data.mass_posting_history
          if (mass_posting_history === null) {
            mass_posting_history = []
          }
          if (consumers !== null) {
            consumers.forEach(consumer => {
              let index = this.mailings.findIndex(item => item.uuid === consumer.queue_name); // ищем индекс элемента с нужным uuid
              if (index !== -1) { // если элемент найден
                if (consumer.consumers > 0) {
                  this.mailings[index].have_consumer = true; // изменяем поле have_consumer
                } else {
                  this.mailings[index].have_consumer = false;
                }
              }
            });
          }
          if (data.data.all_history_count > 0) {
            this.history = [];
            this.all_pages = Math.ceil(data.data.all_history_count / this.messages_on_page);
            if (mass_posting_history.length > 0) {
              mass_posting_history.forEach(mass_posting => {
                this.history.push(mass_posting);
              });
            }
          }
        } else {
          console.log(data.data)
        }
      });
    }
  },
  props: ["profiles", "token", "user", "mailings", "any_user_profile"],
  created() {
    this.countLines();
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.updateHistory();
    this.createWebSocketConnection();
  },
  mounted(){
    const sidebar = document.querySelector(".sidebar");
    if(window.screen.width < 768){
      sidebar.classList.add("sidebar-mini");
    }
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  watch: {
    now_page: {
       handler(val){
         if (parseInt(val) > 0) {
            let offset = (val * this.messages_on_page) - this.messages_on_page;
            this.$axios.get("/api/spam/posting/history?is_spam=true&profile_id=" + this.any_user_profile.uuid + "&limit=" + this.messages_on_page + "&offset=" + offset,
            {
              headers: {
                Authorization: this.token,
              },
            }
          )
          .then((res) => {
            let data = res.data;
            // console.log(data);
            if (data.status === "done") {
              let remained_count = document.querySelector("#remained_count");
              remained_count.innerHTML = data.ready_to_send;
              let consumers = data.consumers
              let mass_posting_history = data.mass_posting_history
              if (consumers !== null) {
                consumers.forEach(consumer => {
                  const index = this.mailings.findIndex(item => item.uuid === consumer.queue_name); // ищем индекс элемента с нужным uuid
                  if (index !== -1) { // если элемент найден
                    if (consumer.consumers > 0) {
                      this.mailings[index].have_consumer = true; // изменяем поле have_consumer
                    } else {
                      this.mailings[index].have_consumer = false;
                    }
                  }
                });
              }
              if (data.all_history_count > 0) {
                this.history = [];
                this.all_pages = Math.ceil(data.all_history_count / this.messages_on_page);
                mass_posting_history.forEach(mass_posting => {
                  this.history.push(mass_posting);
                });
                window.scrollTo(0, 0);
              }
            } else {
              console.log(data.data)
            }
            
          })
          
         }
       },
    }
  },
  computed: {},
  components: {
    Sidebar,
    Topbar,
    vPagination
  },
};
</script>
