var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Sidebar", {
        attrs: { all_profiles: "all_profiles" },
        on: { side_toggle: _vm.sidebar_toggle },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main-content" },
        [
          _c("Topbar", { on: { side_toggle: _vm.sidebar_toggle } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iq-navbar-header" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "iq-header-img" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container-fluid content-inner py-0 my-mt-5" },
                [
                  _c("div", { staticClass: "row g-0 g-sm-2 g-md-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12",
                      },
                      [
                        _c("div", { staticClass: "card" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "h2",
                              {
                                staticClass: "mass_title responder_head_margin",
                              },
                              [_vm._v(_vm._s(_vm.gpt.name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "responder_head_margin" },
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gpt.name,
                                        expression: "gpt.name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", name: "name" },
                                    domProps: { value: _vm.gpt.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.gpt,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _vm._m(2),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "responder_head_margin" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-label d-flex justify-content-between align-items-center",
                                  },
                                  [
                                    _c("b", [_vm._v("API Токен OpenAI")]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-toggle": "tooltip",
                                          "data-placement": "right",
                                          title: "Токен OpenAI",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "16",
                                              height: "16",
                                              viewBox: "0 0 16 16",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                fill: "#6c757d",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "row gx-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 col-xl-7 col-xxl-8 col-xxxxl-9",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.openAIToken,
                                            expression: "openAIToken",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          placeholder: "Введите токен",
                                        },
                                        domProps: { value: _vm.openAIToken },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.openAIToken =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 col-xl-5 col-xxl-4 col-xxxxl-3",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "resp_button_green ms-xl-2 mt-2 mt-xl-0",
                                          staticStyle: { width: "100%" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.save_token()
                                            },
                                          },
                                        },
                                        [_vm._v("Применить")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._m(3),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-label d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c("b", [_vm._v("Ассистент ChatGPT")]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title: "Ассистент ChatGPT",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                    fill: "#6c757d",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.gpt.ai_asisstent_id,
                                              expression: "gpt.ai_asisstent_id",
                                            },
                                          ],
                                          staticClass: "form-select",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.gpt,
                                                "ai_asisstent_id",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.asisstents,
                                          function (asisstent) {
                                            return _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: asisstent.id,
                                                },
                                              },
                                              [_vm._v(_vm._s(asisstent.name))]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(4),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _vm._m(5),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.gpt.profile_uuid,
                                              expression: "gpt.profile_uuid",
                                            },
                                          ],
                                          staticClass: "form-select",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.gpt,
                                                "profile_uuid",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.profiles,
                                          function (profile) {
                                            return _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: profile.uuid,
                                                },
                                              },
                                              [_vm._v(_vm._s(profile.name))]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(6),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "wtpl_subtitle_2 d-flex align-items-center justify-content-center",
                                  },
                                  [_c("div", [_vm._v("НАСТРОЙКИ")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _vm._m(7),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("switch-toggle", {
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                          attrs: {
                                            uid: "test_gpt_enable",
                                            model: _vm.gpt.enabled,
                                          },
                                          on: {
                                            "update:model": function ($event) {
                                              return _vm.$set(
                                                _vm.gpt,
                                                "enabled",
                                                $event
                                              )
                                            },
                                            toggler: _vm.toggleStatus,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.gpt.enabled
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bot_mini_text always_visible d-inline-block initial active",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          ВКЛЮЧЕН"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.gpt.enabled
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bot_mini_text always_visible d-inline-block initial",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          ВЫКЛЮЧЕН"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-label d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form_check_flex align-items-start",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "me-2",
                                                staticStyle: { height: "22px" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.gpt.is_stop_from_me,
                                                      expression:
                                                        "gpt.is_stop_from_me",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-check-input check2",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "flexRadioDefault1",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.gpt.is_stop_from_me
                                                    )
                                                      ? _vm._i(
                                                          _vm.gpt
                                                            .is_stop_from_me,
                                                          null
                                                        ) > -1
                                                      : _vm.gpt.is_stop_from_me,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.gpt
                                                            .is_stop_from_me,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_stop_from_me",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_stop_from_me",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.gpt,
                                                          "is_stop_from_me",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "flexRadioDefault1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Отключать ассистента в чате в случае прерывания его владельцем"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title:
                                                "Отключать ассистента в чате в случае прерывания его владельцем",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                    fill: "#6c757d",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-label d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form_check_flex align-items-start",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "me-2",
                                                staticStyle: { height: "22px" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.gpt
                                                          .is_owner_stop_trigers,
                                                      expression:
                                                        "gpt.is_owner_stop_trigers",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-check-input check2",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "flexRadioDefault2",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.gpt
                                                        .is_owner_stop_trigers
                                                    )
                                                      ? _vm._i(
                                                          _vm.gpt
                                                            .is_owner_stop_trigers,
                                                          null
                                                        ) > -1
                                                      : _vm.gpt
                                                          .is_owner_stop_trigers,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.gpt
                                                            .is_owner_stop_trigers,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_owner_stop_trigers",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_owner_stop_trigers",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.gpt,
                                                          "is_owner_stop_trigers",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "flexRadioDefault2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Триггер  от владельца на остановку ассистента"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title:
                                                "Триггер  от владельца на остановку ассистента",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                    fill: "#6c757d",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gpt.owner_stop_trigers[0],
                                            expression:
                                              "gpt.owner_stop_trigers[0]",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          placeholder:
                                            "Символ, слово или фраза",
                                        },
                                        domProps: {
                                          value: _vm.gpt.owner_stop_trigers[0],
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.gpt.owner_stop_trigers,
                                              0,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(8),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-label d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form_check_flex align-items-start",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "me-2",
                                                staticStyle: { height: "22px" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.gpt
                                                          .is_owner_start_trigers,
                                                      expression:
                                                        "gpt.is_owner_start_trigers",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-check-input check2",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "flexRadioDefault3",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.gpt
                                                        .is_owner_start_trigers
                                                    )
                                                      ? _vm._i(
                                                          _vm.gpt
                                                            .is_owner_start_trigers,
                                                          null
                                                        ) > -1
                                                      : _vm.gpt
                                                          .is_owner_start_trigers,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.gpt
                                                            .is_owner_start_trigers,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_owner_start_trigers",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_owner_start_trigers",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.gpt,
                                                          "is_owner_start_trigers",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "flexRadioDefault3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Триггер от владельца на старт ассистента"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title:
                                                "Триггер от владельца на старт ассистента",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                    fill: "#6c757d",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gpt.owner_start_trigers[0],
                                            expression:
                                              "gpt.owner_start_trigers[0]",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          placeholder:
                                            "Символ, слово или фраза",
                                        },
                                        domProps: {
                                          value: _vm.gpt.owner_start_trigers[0],
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.gpt.owner_start_trigers,
                                              0,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(9),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-label d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form_check_flex align-items-start",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "me-2",
                                                staticStyle: { height: "22px" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.gpt
                                                          .is_client_stop_trigers,
                                                      expression:
                                                        "gpt.is_client_stop_trigers",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-check-input check2",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "flexRadioDefault4",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.gpt
                                                        .is_client_stop_trigers
                                                    )
                                                      ? _vm._i(
                                                          _vm.gpt
                                                            .is_client_stop_trigers,
                                                          null
                                                        ) > -1
                                                      : _vm.gpt
                                                          .is_client_stop_trigers,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.gpt
                                                            .is_client_stop_trigers,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_client_stop_trigers",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_client_stop_trigers",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.gpt,
                                                          "is_client_stop_trigers",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "flexRadioDefault4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Триггер от собеседника на остановку ассистента"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title:
                                                "Триггер от собеседника на остановку ассистента",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                    fill: "#6c757d",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gpt.client_stop_trigers[0],
                                            expression:
                                              "gpt.client_stop_trigers[0]",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          placeholder:
                                            "Символ, слово или фраза",
                                        },
                                        domProps: {
                                          value: _vm.gpt.client_stop_trigers[0],
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.gpt.client_stop_trigers,
                                              0,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(10),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "responder_head_margin" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-label d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form_check_flex align-items-start",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "me-2",
                                                staticStyle: { height: "22px" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.gpt
                                                          .is_client_triger_handlers,
                                                      expression:
                                                        "gpt.is_client_triger_handlers",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-check-input check2",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "flexRadioDefault5",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.gpt
                                                        .is_client_triger_handlers
                                                    )
                                                      ? _vm._i(
                                                          _vm.gpt
                                                            .is_client_triger_handlers,
                                                          null
                                                        ) > -1
                                                      : _vm.gpt
                                                          .is_client_triger_handlers,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.gpt
                                                            .is_client_triger_handlers,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_client_triger_handlers",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.gpt,
                                                              "is_client_triger_handlers",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.gpt,
                                                          "is_client_triger_handlers",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "flexRadioDefault5",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Триггер от собеседника на http запрос"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title:
                                                "Использовать триггер от собеседника на остановку ассистента",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                    fill: "#6c757d",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gpt.client_triger_handlers[0]
                                                .value,
                                            expression:
                                              "gpt.client_triger_handlers[0].value",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          placeholder:
                                            "Символ, слово или фраза",
                                        },
                                        domProps: {
                                          value:
                                            _vm.gpt.client_triger_handlers[0]
                                              .value,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.gpt.client_triger_handlers[0],
                                              "value",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(11),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gpt.client_triger_handlers[0]
                                                .url,
                                            expression:
                                              "gpt.client_triger_handlers[0].url",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          placeholder:
                                            "Введите url (http://test.ru/test)",
                                        },
                                        domProps: {
                                          value:
                                            _vm.gpt.client_triger_handlers[0]
                                              .url,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.gpt.client_triger_handlers[0],
                                              "url",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(12),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gpt.client_triger_handlers[0]
                                                .json,
                                            expression:
                                              "gpt.client_triger_handlers[0].json",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "name",
                                          placeholder: "Введите запрос",
                                        },
                                        domProps: {
                                          value:
                                            _vm.gpt.client_triger_handlers[0]
                                              .json,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.gpt.client_triger_handlers[0],
                                              "json",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(13),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gpt.ai_token.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "responder_head_margin responder_submit_buttons",
                                  },
                                  [
                                    !_vm.sending
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "resp_button_green mr24",
                                            on: {
                                              click: function ($event) {
                                                return _vm.save_gpt()
                                              },
                                            },
                                          },
                                          [_vm._v("Сохранить")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.sending
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "resp_button_green mr24",
                                            attrs: { disabled: "" },
                                          },
                                          [_vm._m(14)]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "inertia-link",
                                      {
                                        staticClass: "resp_button_grey2",
                                        attrs: { href: _vm.route("gpt.index") },
                                      },
                                      [_vm._v("Отменить")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12",
                      },
                      [
                        _c("div", { staticClass: "card card_desc" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("h2", { staticClass: "mass_title" }, [
                              _vm._v("Что такое ChatGPT?"),
                            ]),
                            _vm._v(" "),
                            _vm._m(15),
                            _vm._v(" "),
                            _c("h2", { staticClass: "mass_title mb-3" }, [
                              _vm._v(
                                "Индивидуальный чатбот ChatGPT для службы поддержки"
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._m(16),
                            _vm._v(" "),
                            _vm._m(17),
                            _vm._v(" "),
                            _c("h2", { staticClass: "mass_title mt-4" }, [
                              _vm._v("Возможности Wappi и ChatGPT"),
                            ]),
                            _vm._v(" "),
                            _vm._m(18),
                            _vm._v(" "),
                            _c("h2", { staticClass: "mass_title mt-4" }, [
                              _vm._v("Инструкции по использованию"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2" }, [
                              _c("div", { staticClass: "my_collapse" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button_collapse mb_36",
                                    attrs: {
                                      id: "collapsed1",
                                      "data-bs-toggle": "collapse",
                                      href: "#collapseExample1",
                                      role: "button",
                                      "aria-expanded": "false",
                                      "aria-controls": "collapseExample1",
                                      "data-clear": "true",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleRotate(1)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "h2",
                                      { staticClass: "mass_title_green" },
                                      [
                                        _vm._v(
                                          "Как обучить персонального ChatGPT-ассистента на своих данных?   "
                                        ),
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "borg__str",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "7",
                                              height: "12",
                                              viewBox: "0 0 7 12",
                                              fill: "none",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                "clip-rule": "evenodd",
                                                d: "M4.58579 6L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6Z",
                                                fill: "#23BB86",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(19),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("Footer"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-fluid iq-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-wrap",
            },
            [
              _c("div", [
                _c("h1", { staticClass: "c-content" }, [
                  _vm._v("ChatGPT ассистент"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("b", [_vm._v("Название")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v("Введите название для вашего ассистента"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Токен от вашего аккаунта OpenAI, в котором находятся ассистенты"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v("Выберите из списка необходимого ассистента"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("b", [_vm._v("Прикрепить к профилю")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Выберите профиль Wappi, на котором будет активирован ChatGPT ассистент"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("span", [_c("b", [_vm._v("Статус")])])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Если владелец введет этот триггер в чате - ассистент прервет работу в этом диалоге"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Если владелец введет этот триггер в чате - ассистент начнет работу в этом диалоге"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Если собеседник введет этот триггер в чате - ассистент прервет работу в этом диалоге"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v(
            "Если собеседник введет этот триггер в чате - ассистент отправит http запрос"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v("По этому URL будет отправлен запрос"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", { staticClass: "input_desc" }, [
          _vm._v("Запрос в формате json"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("p", [
        _vm._v(
          "ChatGPT - это умный чатбот от OpenAI, который умеет вести разговоры почти как человек. Он может использоваться для самых разных задач. "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "ChatGPT ассистенты - это индивидуальные версии ChatGPT, которые вы можете обучить для решения конкретных задач, например, "
        ),
        _c("span", { staticClass: "c-content" }, [
          _vm._v("отвечать на вопросы о вашей работе или компании"),
        ]),
        _vm._v("."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Замените рутинную службу поддержки на умного чат-бота ChatGPT. "),
      _c("span", { staticClass: "c-content" }, [
        _vm._v("Обучите его вашим знаниям"),
      ]),
      _vm._v(
        ", и он станет круглосуточным помощником, отвечая на вопросы клиентов, автоматизируя:"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "mt-4 graydotul" }, [
      _c("li", [_vm._v("Ответы на частые вопросы")]),
      _vm._v(" "),
      _c("li", [_vm._v("Решение проблем и предоставление инструкций")]),
      _vm._v(" "),
      _c("li", [_vm._v("Обработку заявок, запросов, бронирований")]),
      _vm._v(" "),
      _c("li", [_vm._v("Сбор обратной связи от клиентов")]),
      _vm._v(" "),
      _c("li", [_vm._v("Поддержку на разных языках")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "green_list mt-4" }, [
      _c("li", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "green_list_title" }, [
          _vm._v("ChatGPT бот в Whatsapp или Telegram"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "green_list_desc" }, [
          _vm._v(
            "\n                      Подключение обученного под вашу специфику ChatGPT ассистента в качестве чат-бота Whatsapp или Telegram\n                    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "green_list_title" }, [
          _vm._v("Управление ChatGPT ботом прямо из чата мессенджера"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "green_list_desc" }, [
          _vm._v(
            "\n                      Настройка триггеров для остановки и запуска бота прямо из чата Whatsapp или Telegram\n                    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "green_list_title" }, [
          _vm._v("Уведомление, если клиент позовет оператора"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "green_list_desc" }, [
          _vm._v(
            "\n                      Настройка уведомлений в случае, когда собеседник хочет поговорить с живым оператором\n                    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "green_list_title" }, [
          _vm._v("Взаимодействие с внешними системами"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "green_list_desc" }, [
          _vm._v(
            "\n                      Настройка взаимодействия с вашей системой через отправку любого json запроса по вашему url\n                    "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "collapse", attrs: { id: "collapseExample1" } },
      [
        _c("div", { staticClass: "mt-2" }, [
          _c("p", [
            _vm._v(
              "Для того, чтобы дообучить ChatGPT на основе вашей базы знаний работать в качестве ассистента, необходимо:"
            ),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "ulnumeric mt-4" }, [
            _c("li", [
              _vm._v("Зарегистрируйтесь на "),
              _c(
                "a",
                {
                  staticClass: "text_green",
                  attrs: {
                    href: "https://platform.openai.com",
                    target: "_blank",
                  },
                },
                [_vm._v("https://platform.openai.com")]
              ),
              _vm._v(" (используйте VPN)."),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Пополните баланс в вашем аккаунте на "),
              _c(
                "a",
                {
                  staticClass: "text_green",
                  attrs: {
                    href: "https://platform.openai.com",
                    target: "_blank",
                  },
                },
                [_vm._v("https://platform.openai.com")]
              ),
              _vm._v(" (не принимают РФ карты)."),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Нажмите "),
              _c("span", { staticClass: "text_green" }, [_vm._v("Assistants")]),
              _vm._v(", далее выберите "),
              _c("span", { staticClass: "text_green" }, [
                _vm._v("+ Create assistant"),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Добавьте промт (инструкцию). В нём должны быть описаны основные инструкции, например задача ассистента, стиль общения, как отвечать на вопросы, конфиденциальность. Чем подробнее, тем лучше."
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Выберите модель chatGPT. Рекомендуем "),
              _c("span", { staticClass: "text_green" }, [_vm._v("gpt-4o")]),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Для дообучения ChatGPT соберите все релевантные данные о вашей компании. Это могут быть часто задаваемые вопросы (FAQ), документы по продуктам, инструкции, записи прошлых разговоров с клиентами и другие материалы, которые могут быть полезны для ответов на запросы пользователей. Убедитесь, что собранные данные актуальны и не содержат конфиденциальной информации, которую не следует разглашать."
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Отформатируйте данные. Приведите данные в структурированный формат, удобный для обучения модели. Например, можно создать таблицы с вопросами и ответами, или документы с чёткими заголовками и секциями. Пометьте данные так, чтобы модель могла понимать, какие части текста являются вопросами, а какие ответами."
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Загрузите приготовленные файлы в вашего ассистента, активировав "
              ),
              _c("span", { staticClass: "text_green" }, [
                _vm._v("File search"),
              ]),
              _vm._v(" и нажав "),
              _c("span", { staticClass: "text_green" }, [_vm._v("+ Files")]),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Настройте остальные опции в зависимости от ваших задач. Сохраните ассистента."
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "ChatGPT ассистент прошел обучение на ваших данных и готов к работе!"
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }