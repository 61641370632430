var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Sidebar", {
        attrs: { all_profiles: "all_profiles" },
        on: { side_toggle: _vm.sidebar_toggle },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main-content" },
        [
          _c("Topbar", { on: { side_toggle: _vm.sidebar_toggle } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iq-navbar-header" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "iq-header-img" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container-fluid content-inner py-0 my-mt-5" },
                [
                  _c("div", { staticClass: "row g-0 g-sm-2 g-md-4" }, [
                    _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "green_bg_block mb-4" }, [
                            _c("div", { staticClass: "bg_block_title mb-3" }, [
                              _c("div", [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "27",
                                      height: "25",
                                      viewBox: "0 0 27 25",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M11.0837 16.125H13.5003C17.5044 16.125 20.7503 12.8791 20.7503 8.875C20.7503 4.87093 17.5044 1.625 13.5003 1.625H8.66699C4.66293 1.625 1.41699 4.87093 1.41699 8.875C1.41699 10.7318 2.11506 12.4257 3.26307 13.7083M18.3337 23.375C22.3377 23.375 25.5837 20.1291 25.5837 16.125C25.5837 14.2682 24.8856 12.5743 23.7376 11.2917M13.5003 23.375C9.49626 23.375 6.25033 20.1291 6.25033 16.125C6.25033 12.1209 9.49626 8.875 13.5003 8.875H15.917",
                                        stroke: "#232D42",
                                        "stroke-width": "1.8125",
                                        "stroke-linecap": "round",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "ms-2" }, [
                                _vm._v("Реферальная ссылка"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "bg_block_description mb-3" },
                              [
                                _vm._v(
                                  "\n                    Отправляйте эту реферальную ссылку на регистрацию в Wappi.pro вашим клиентам или знакомым. Все, кто зарегистрируется по ней, станут вашими рефералами. Вы будете получать вознаграждение за каждый оплаченный ими профиль.\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "bg_block_link" }, [
                              _c("div", { staticClass: "bg_block_url" }, [
                                _vm._v(
                                  "https://wappi.pro/registration?ref=" +
                                    _vm._s(_vm.ref_code)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "bg_block_copy ms-2" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "c-ico d-none d-sm-inline",
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      title: "Копировать в буфер",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img-fluid",
                                      attrs: {
                                        src: "/static/images/copy.svg",
                                        alt: "edit",
                                      },
                                      on: { click: _vm.copyReferalLink },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row gx-2 gx-lg-4" }, [
                            _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                              _c("div", { staticClass: "gray_bg_block" }, [
                                _c(
                                  "div",
                                  { staticClass: "bg_block_title mb-3" },
                                  [
                                    _c("div", [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            viewBox: "0 0 28 28",
                                            fill: "none",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M10.4997 11.6667C13.077 11.6667 15.1663 9.57737 15.1663 7.00004C15.1663 4.42271 13.077 2.33337 10.4997 2.33337C7.92235 2.33337 5.83301 4.42271 5.83301 7.00004C5.83301 9.57737 7.92235 11.6667 10.4997 11.6667Z",
                                              stroke: "#232D42",
                                              "stroke-width": "1.75",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("path", {
                                            attrs: {
                                              d: "M17.5 10.5C19.433 10.5 21 8.93299 21 7C21 5.06701 19.433 3.5 17.5 3.5",
                                              stroke: "#232D42",
                                              "stroke-width": "1.75",
                                              "stroke-linecap": "round",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("path", {
                                            attrs: {
                                              d: "M10.4997 24.5C15.01 24.5 18.6663 22.4106 18.6663 19.8333C18.6663 17.256 15.01 15.1666 10.4997 15.1666C5.98935 15.1666 2.33301 17.256 2.33301 19.8333C2.33301 22.4106 5.98935 24.5 10.4997 24.5Z",
                                              stroke: "#232D42",
                                              "stroke-width": "1.75",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("path", {
                                            attrs: {
                                              d: "M21 16.3334C23.0466 16.7822 24.5 17.9188 24.5 19.25C24.5 20.4509 23.3173 21.4934 21.5833 22.0155",
                                              stroke: "#232D42",
                                              "stroke-width": "1.75",
                                              "stroke-linecap": "round",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ms-2" }, [
                                      _vm._v("Рефералы"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "bg_block_number" }, [
                                  _vm._v(_vm._s(_vm.ref_count)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "col-sm-12 col-md-6 mt-4 mt-md-0",
                              },
                              [
                                _c("div", { staticClass: "gray_bg_block" }, [
                                  _c(
                                    "div",
                                    { staticClass: "bg_block_title mb-3" },
                                    [
                                      _c("div", [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              viewBox: "0 0 29 29",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4.58562 16.4465L5.43206 18.114C4.85206 18.685 4.53125 19.3067 4.53125 19.9374C4.53125 22.2683 8.93019 24.4687 14.5 24.4687C20.0698 24.4687 24.4688 22.2683 24.4688 19.9374C24.4688 19.3067 24.1461 18.6868 23.5679 18.1158L24.4307 16.461C25.5998 17.4398 26.2812 18.6252 26.2812 19.9374C26.2812 23.6113 20.9416 26.2812 14.5 26.2812C8.05838 26.2812 2.71875 23.6113 2.71875 19.9374C2.71875 18.6179 3.4075 17.4289 4.58562 16.4465Z",
                                                fill: "#232D42",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M4.58562 11.009L5.43206 12.6765C4.85206 13.2493 4.53125 13.871 4.53125 14.4999C4.53125 16.8308 8.93019 19.0312 14.5 19.0312C20.0698 19.0312 24.4688 16.8308 24.4688 14.4999C24.4688 13.8692 24.1461 13.2493 23.5679 12.6783L24.4307 11.0235C25.5998 12.0023 26.2812 13.1877 26.2812 14.4999C26.2812 18.1738 20.9416 20.8437 14.5 20.8437C8.05838 20.8437 2.71875 18.1738 2.71875 14.4999C2.71875 13.1804 3.4075 11.9914 4.58562 11.009Z",
                                                fill: "#232D42",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M14.5 15.4062C8.05838 15.4062 2.71875 12.7364 2.71875 9.0625C2.71875 5.38856 8.05838 2.71875 14.5 2.71875C20.9416 2.71875 26.2812 5.38856 26.2812 9.0625C26.2812 12.7364 20.9416 15.4062 14.5 15.4062ZM14.5 13.5938C20.0698 13.5938 24.4688 11.3934 24.4688 9.0625C24.4688 6.73163 20.0698 4.53125 14.5 4.53125C8.93019 4.53125 4.53125 6.73163 4.53125 9.0625C4.53125 11.3934 8.93019 13.5938 14.5 13.5938Z",
                                                fill: "#232D42",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm._m(1),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "bg_block_number" },
                                    [_vm._v(_vm._s(_vm.balance))]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg_block_buttons mt-4 d-none d-md-flex flex-column",
                            },
                            [
                              _c("div", { staticClass: "d-flex mb-3" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-billing",
                                    attrs: { type: "button" },
                                    on: { click: _vm.RetailPay },
                                  },
                                  [_vm._v("Потратить на профили")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-billing ms-2 ms-lg-4",
                                    class: { disabled: _vm.balance < 5000 },
                                    attrs: { type: "button" },
                                    on: { click: _vm.OrderPayment },
                                  },
                                  [_vm._v("Заказать выплату")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "c-title text-decoration-underline billing-history",
                                  on: { click: _vm.RefPayHistoryModal },
                                },
                                [_vm._v("История выплат")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg_block_buttons mt-4 d-flex d-md-none flex-column",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-billing mb-4",
                                  attrs: { type: "button" },
                                  on: { click: _vm.RetailPay },
                                },
                                [_vm._v("Потратить на профили")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-billing mb-4",
                                  class: { disabled: _vm.balance < 5000 },
                                  attrs: { type: "button" },
                                  on: { click: _vm.OrderPayment },
                                },
                                [_vm._v("Заказать выплату")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "c-title text-decoration-underline billing-history",
                                  on: { click: _vm.RefPayHistoryModal },
                                },
                                [_vm._v("История выплат")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("h2", { staticClass: "mass_title" }, [
                            _vm._v("Как работает партнерская программа"),
                          ]),
                          _vm._v(" "),
                          _vm._m(2),
                          _vm._v(" "),
                          _c("h2", { staticClass: "mass_title" }, [
                            _vm._v("Сколько зарабатывает партнер"),
                          ]),
                          _vm._v(" "),
                          _vm._m(3),
                          _vm._v(" "),
                          _c("h2", { staticClass: "mass_title" }, [
                            _vm._v("Как получить вознаграждение"),
                          ]),
                          _vm._v(" "),
                          _vm._m(4),
                          _vm._v(" "),
                          _vm._m(5),
                          _vm._v(" "),
                          _c("div", { staticClass: "green_bg_block" }, [
                            _c("h2", { staticClass: "mass_title" }, [
                              _vm._v("Как заработать больше"),
                            ]),
                            _vm._v(" "),
                            _vm._m(6),
                            _vm._v(" "),
                            _c("div", { staticClass: "partner_contacts" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "28",
                                    height: "28",
                                    viewBox: "0 0 28 28",
                                    fill: "none",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        "clip-path": "url(#clip0_4899_1559)",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M10.9866 17.7111L10.5235 24.2257C11.1861 24.2257 11.4731 23.9411 11.8173 23.5992L14.9241 20.6301L21.3618 25.3446C22.5424 26.0026 23.3743 25.6561 23.6928 24.2584L27.9185 4.45774L27.9196 4.45657C28.2941 2.71124 27.2885 2.02874 26.1381 2.4569L1.29978 11.9664C-0.395384 12.6244 -0.369717 13.5694 1.01162 13.9976L7.36178 15.9727L22.1119 6.74324C22.8061 6.28357 23.4373 6.5379 22.9181 6.99757L10.9866 17.7111Z",
                                          fill: "#2BB4DF",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("defs", [
                                    _c(
                                      "clipPath",
                                      { attrs: { id: "clip0_4899_1559" } },
                                      [
                                        _c("rect", {
                                          attrs: {
                                            width: "28",
                                            height: "28",
                                            fill: "white",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" Telegram: "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://t.me/wappi_support",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("@wappi_support")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("Footer"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: {
                id: "refhistory-modal",
                tabindex: "-1",
                role: "dialog",
                "aria-labelledby": "exampleModalLabel",
                "aria-hidden": "true",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog modal-xl",
                  attrs: { role: "document" },
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-head d-flex align-items-start justify-content-between",
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "modal-title",
                            attrs: { id: "billing-history-modal-label" },
                          },
                          [
                            _vm._v(
                              "\n                История выплат\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: { type: "button" },
                            on: { click: _vm.RefPayHistoryModal },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "16",
                                  height: "15",
                                  viewBox: "0 0 16 15",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z",
                                    fill: "#6C757D",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-striped" }, [
                          _c(
                            "tbody",
                            [
                              _vm._m(7),
                              _vm._v(" "),
                              _vm._l(_vm.balances, function (b, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "text-center" },
                                  [
                                    _c("td", [_vm._v(_vm._s(b.created_at))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(b.amount))]),
                                    _vm._v(" "),
                                    b.type == "payment_request"
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                        Выплата вознаграждения\n                      "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    b.type == "add_days"
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                        Продление профиля\n                      "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-fluid iq-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-wrap",
            },
            [
              _c("div", [
                _c("h1", { staticClass: "c-content" }, [
                  _vm._v("Партнерская программа"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-2" }, [
      _vm._v("Баланс "),
      _c("span", { staticClass: "d-inline d-md-none d-xl-none d-xxl-inline" }, [
        _vm._v("(руб)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "partner_text" }, [
      _c("p", [
        _vm._v(
          "Партнерская программа позволяет вам зарабатывать на привлеченных в наш сервис пользователях. Вы просто отправляете вашу реферальную ссылку вашим клиентам, \n                    встраиваете ее в свой сервис или распространяете иным способом. Зарегистрированные по этой ссылке пользователи становятся вашими рефералами."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "partner_text" }, [
      _c("p", [
        _vm._v("Партнер зарабатывает вознаграждение в размере "),
        _c("b", { staticClass: "c-content" }, [_vm._v("10%")]),
        _vm._v("  от всех оплат его рефералов."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "partner_text" }, [
      _c("p", [
        _vm._v(
          "Партнер может в любой момент потратить вознаграждение на оплату своих собственных профилей в Wappi.pro из расчета "
        ),
        _c("b", { staticClass: "c-content" }, [_vm._v("1 день = 20 руб")]),
        _vm._v("."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "partner_text" }, [
      _c("p", [
        _vm._v(
          "Партнер может заказать выплату в рублях на карту в Российском банке. "
        ),
        _c("b", { staticClass: "c-content" }, [
          _vm._v("Минимальная выплата - от 5000 рублей"),
        ]),
        _vm._v("."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "partner_text" }, [
      _c("p", [
        _vm._v(
          "Если вы разрабатываете свою crm систему или сервис и планируете подключать своим клиентам whatsapp или telegram, то можете использовать наш сервис в качестве Whatsapp и Telegram шлюза, интегрировав с помощью API. Для таких партнеров будут действовать существенные скидки от объема и индивидуальные условия - выбор схемы оплаты, промопериода. Обращайтесь в поддержку:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "text-center text-uppercase" }, [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Дата")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Сумма")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Тип оплаты")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }