export const wabaTemplates = {
  "profile_id": "",
  "wappi_title": "Шаблон проверочного кода",
  "category": "MARKETING",
  "containerMeta": {
      "data": "Привет, {{1}}! Мы празднуем день рождения нашего интернет-магазина дизайнерских носков и по этому поводу дарим подарки всем нашим покупателям 🎂\n\nВаш персональный подарок - Промокод {{2}} на скидку 30% на любую покупку до 30.04.2024\n",
      "data_vars": [
        {"trigger": "{{1}}", "value": "Александр"},
        {"trigger": "{{2}}", "value": "65TRTF2000"},
      ],
      "footer": "Friendsocks.ru",
  },
  "header": "media",
  "header_text": "",
  "header_text_params": [],
  "languageCode": "ru",
  "templateType": "IMAGE",
  "vertical": "рассылка 1",
  "media": "",
  "file_name": "",
  "all_buttons": [
    { 
      type: 'quick_reply', 
      title: 'Быстрые ответы',
      buttons: [
        { name: "Быстрый ответ", value: "quick_reply" },
      ],
    },
    { 
      type: 'actions', 
      title: 'Призывы к действию',
      buttons: [
        {
          type: "URL",
          text: "Перейти на сайт 11",
          url: "https://friendsocks.ru/",
          dynamic: false,
          country: "bel",
          phone: "",
        },
        {
          type: "PHONE",
          text: "Позвонить",
          url: "",
          dynamic: true,
          country: "rus",
          phone: "9115576367",
        },
      ],
    },
  ],
};