<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <div>
                  <h1 class="c-content">Партнерская программа</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row g-0 g-sm-2 g-md-4">
            <!--  -->
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="card">
                <div class="card-body">
                    
                  <div class="green_bg_block mb-4">
                    <div class="bg_block_title mb-3">
                      <div>
                        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.0837 16.125H13.5003C17.5044 16.125 20.7503 12.8791 20.7503 8.875C20.7503 4.87093 17.5044 1.625 13.5003 1.625H8.66699C4.66293 1.625 1.41699 4.87093 1.41699 8.875C1.41699 10.7318 2.11506 12.4257 3.26307 13.7083M18.3337 23.375C22.3377 23.375 25.5837 20.1291 25.5837 16.125C25.5837 14.2682 24.8856 12.5743 23.7376 11.2917M13.5003 23.375C9.49626 23.375 6.25033 20.1291 6.25033 16.125C6.25033 12.1209 9.49626 8.875 13.5003 8.875H15.917" stroke="#232D42" stroke-width="1.8125" stroke-linecap="round"/>
                        </svg>
                      </div>
                      <div class="ms-2">Реферальная ссылка</div>
                    </div>
                    <div class="bg_block_description mb-3">
                      Отправляйте эту реферальную ссылку на регистрацию в Wappi.pro вашим клиентам или знакомым. Все, кто зарегистрируется по ней, станут вашими рефералами. Вы будете получать вознаграждение за каждый оплаченный ими профиль.
                    </div>
                    <div class="bg_block_link">
                      <div class="bg_block_url">https://wappi.pro/registration?ref={{ ref_code }}</div>
                      <div class="bg_block_copy ms-2">
                        <span class="c-ico d-none d-sm-inline" data-toggle="tooltip" title="Копировать в буфер"><img
                          src="/static/images/copy.svg" alt="edit" class="img-fluid" @click="copyReferalLink" /></span>
                      </div>
                    </div>
                  </div>

                  <div class="row gx-2 gx-lg-4">
                    <div class="col-sm-12 col-md-6">
                      <div class="gray_bg_block">
                        <div class="bg_block_title mb-3">
                          <div>
                            <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.4997 11.6667C13.077 11.6667 15.1663 9.57737 15.1663 7.00004C15.1663 4.42271 13.077 2.33337 10.4997 2.33337C7.92235 2.33337 5.83301 4.42271 5.83301 7.00004C5.83301 9.57737 7.92235 11.6667 10.4997 11.6667Z" stroke="#232D42" stroke-width="1.75"/>
                              <path d="M17.5 10.5C19.433 10.5 21 8.93299 21 7C21 5.06701 19.433 3.5 17.5 3.5" stroke="#232D42" stroke-width="1.75" stroke-linecap="round"/>
                              <path d="M10.4997 24.5C15.01 24.5 18.6663 22.4106 18.6663 19.8333C18.6663 17.256 15.01 15.1666 10.4997 15.1666C5.98935 15.1666 2.33301 17.256 2.33301 19.8333C2.33301 22.4106 5.98935 24.5 10.4997 24.5Z" stroke="#232D42" stroke-width="1.75"/>
                              <path d="M21 16.3334C23.0466 16.7822 24.5 17.9188 24.5 19.25C24.5 20.4509 23.3173 21.4934 21.5833 22.0155" stroke="#232D42" stroke-width="1.75" stroke-linecap="round"/>
                            </svg>
                          </div>
                          <div class="ms-2">Рефералы</div>
                        </div>
                        <div class="bg_block_number">{{ ref_count }}</div>
                      </div>

                    </div>
                    <div class="col-sm-12 col-md-6 mt-4 mt-md-0">
                      <div class="gray_bg_block">
                        <div class="bg_block_title mb-3">
                          <div>
                            <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.58562 16.4465L5.43206 18.114C4.85206 18.685 4.53125 19.3067 4.53125 19.9374C4.53125 22.2683 8.93019 24.4687 14.5 24.4687C20.0698 24.4687 24.4688 22.2683 24.4688 19.9374C24.4688 19.3067 24.1461 18.6868 23.5679 18.1158L24.4307 16.461C25.5998 17.4398 26.2812 18.6252 26.2812 19.9374C26.2812 23.6113 20.9416 26.2812 14.5 26.2812C8.05838 26.2812 2.71875 23.6113 2.71875 19.9374C2.71875 18.6179 3.4075 17.4289 4.58562 16.4465Z" fill="#232D42"/>
                              <path d="M4.58562 11.009L5.43206 12.6765C4.85206 13.2493 4.53125 13.871 4.53125 14.4999C4.53125 16.8308 8.93019 19.0312 14.5 19.0312C20.0698 19.0312 24.4688 16.8308 24.4688 14.4999C24.4688 13.8692 24.1461 13.2493 23.5679 12.6783L24.4307 11.0235C25.5998 12.0023 26.2812 13.1877 26.2812 14.4999C26.2812 18.1738 20.9416 20.8437 14.5 20.8437C8.05838 20.8437 2.71875 18.1738 2.71875 14.4999C2.71875 13.1804 3.4075 11.9914 4.58562 11.009Z" fill="#232D42"/>
                              <path d="M14.5 15.4062C8.05838 15.4062 2.71875 12.7364 2.71875 9.0625C2.71875 5.38856 8.05838 2.71875 14.5 2.71875C20.9416 2.71875 26.2812 5.38856 26.2812 9.0625C26.2812 12.7364 20.9416 15.4062 14.5 15.4062ZM14.5 13.5938C20.0698 13.5938 24.4688 11.3934 24.4688 9.0625C24.4688 6.73163 20.0698 4.53125 14.5 4.53125C8.93019 4.53125 4.53125 6.73163 4.53125 9.0625C4.53125 11.3934 8.93019 13.5938 14.5 13.5938Z" fill="#232D42"/>
                            </svg>
                          </div>
                          <div class="ms-2">Баланс <span class="d-inline d-md-none d-xl-none d-xxl-inline">(руб)</span></div>
                        </div>
                        <div class="bg_block_number">{{ balance }}</div>
                      </div>
                      
                    </div>
                  </div>

                  <div class="bg_block_buttons mt-4 d-none d-md-flex flex-column">
                    <div class="d-flex mb-3">
                      <button type="button" class="btn btn-billing" @click="RetailPay">Потратить на профили</button>
                      <button v-bind:class="{'disabled': balance < 5000}" type="button" class="btn btn-billing ms-2 ms-lg-4" @click="OrderPayment">Заказать выплату</button>
                    </div>
                    <a class="c-title text-decoration-underline billing-history" @click="RefPayHistoryModal">История выплат</a>
                  </div>

                  <div class="bg_block_buttons mt-4 d-flex d-md-none flex-column">
                    <button type="button" class="btn btn-billing mb-4" @click="RetailPay">Потратить на профили</button>
                    <button v-bind:class="{'disabled': balance < 5000}" type="button" class="btn btn-billing mb-4" @click="OrderPayment">Заказать выплату</button>
                    <a class="c-title text-decoration-underline billing-history" @click="RefPayHistoryModal">История выплат</a>
                  </div>
                  

                </div>
              </div>
            </div>
            <!--  -->
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="card">
                <div class="card-body">
                    <h2 class="mass_title">Как работает партнерская программа</h2>
                    <div class="partner_text">
                      <p>Партнерская программа позволяет вам зарабатывать на привлеченных в наш сервис пользователях. Вы просто отправляете вашу реферальную ссылку вашим клиентам, 
                      встраиваете ее в свой сервис или распространяете иным способом. Зарегистрированные по этой ссылке пользователи становятся вашими рефералами.</p>
                    </div>
                    <h2 class="mass_title">Сколько зарабатывает партнер</h2>
                    <div class="partner_text"><p>Партнер зарабатывает вознаграждение в размере <b class="c-content">10%</b>  от всех оплат его рефералов.</p></div>
                    <h2 class="mass_title">Как получить вознаграждение</h2>
                    <div class="partner_text"><p>Партнер может в любой момент потратить вознаграждение на оплату своих собственных профилей в Wappi.pro из расчета <b class="c-content">1 день = 20 руб</b>.</p></div>
                    <div class="partner_text"><p>Партнер может заказать выплату в рублях на карту в Российском банке. <b class="c-content">Минимальная выплата - от 5000 рублей</b>.</p></div>
                    <div class="green_bg_block">
                      <h2 class="mass_title">Как заработать больше</h2>
                      <div class="partner_text"><p>Если вы разрабатываете свою crm систему или сервис и планируете подключать своим клиентам whatsapp или telegram, то можете использовать наш сервис в качестве Whatsapp и Telegram шлюза, интегрировав с помощью API. Для таких партнеров будут действовать существенные скидки от объема и индивидуальные условия - выбор схемы оплаты, промопериода. Обращайтесь в поддержку:</p></div>
                      <div class="partner_contacts"> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                          <g clip-path="url(#clip0_4899_1559)">
                            <path d="M10.9866 17.7111L10.5235 24.2257C11.1861 24.2257 11.4731 23.9411 11.8173 23.5992L14.9241 20.6301L21.3618 25.3446C22.5424 26.0026 23.3743 25.6561 23.6928 24.2584L27.9185 4.45774L27.9196 4.45657C28.2941 2.71124 27.2885 2.02874 26.1381 2.4569L1.29978 11.9664C-0.395384 12.6244 -0.369717 13.5694 1.01162 13.9976L7.36178 15.9727L22.1119 6.74324C22.8061 6.28357 23.4373 6.5379 22.9181 6.99757L10.9866 17.7111Z" fill="#2BB4DF"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_4899_1559">
                              <rect width="28" height="28" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg> Telegram: <a href="https://t.me/wappi_support" target="_blank">@wappi_support</a>
                      </div>
                    </div>

                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
        <Footer></Footer>
      </div>
      <!-- Modal Billing History -->
      <div class="modal fade" id="refhistory-modal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-head d-flex align-items-start justify-content-between">
                <h5 class="modal-title" id="billing-history-modal-label">
                  История выплат
                </h5>
                <button type="button" class="close" @click="RefPayHistoryModal">
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                      fill="#6C757D" />
                  </svg>
                </button>
              </div>
              <div class="">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tbody>
                      <tr class="text-center text-uppercase">
                        <th scope="col">Дата</th>
                        <th scope="col">Сумма</th>
                        <th scope="col">Тип оплаты</th>
                      </tr>
                      <tr v-for="(b, index) in balances" :key="index" class="text-center">
                        <td>{{ b.created_at }}</td>
                        <td>{{ b.amount }}</td>
                        <td v-if="b.type == 'payment_request'">
                          Выплата вознаграждения
                        </td>
                        <td v-if="b.type == 'add_days'">
                          Продление профиля
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal Billing Histry end -->
    </main>
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import Footer from "./Components/Footer.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';

export default {
  data() {
    return {
        error:'',
        name: '',
        email: '',
        message:''
    };
  },
  methods: {
      RefPayHistoryModal() {
        $("#refhistory-modal").modal("toggle");
      },
      OrderPayment() {
        this.$axios.post("https://" + window.location.host + "/admin/request/pay", {
            user_id: this.user.id,
            user_email: this.user.email
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
              this.$swal
              .fire({
                title: "Заказать выплату",
                html: "<span style='line-height: 28px'>Пожалуйста, свяжитесь с нами в телеграм <b class='c-content'>@wappi_support</b> для уточнения реквизитов и получения денег</span>",
                icon: "success",
                confirmButtonText: "Хорошо",
                confirmButtonColor: "#1ea276",
              })
          });
      },
      async RetailPay() {

        let days = Math.floor(this.balance / 20);

        let option_tags = "";
        this.all_profiles.forEach(profile => {
          option_tags += "<option value='" + profile.uuid + "'>" + profile.name + "</option>";
        });

        const { value: formValues } = await this.$swal.fire({
          title: 'Вы можете потратить на профили <b class="c-content">'+ days +'</b> дней',
          html:
            '<input type="number" id="month-swal-input1" class="swal2-input" placeholder="Количество дней" autocomplete="off" style="max-width:100% !important;">' +
            '<select id="month-swal-input2" class="swal2-input" placeholder="Выберите профиль">' +
              option_tags +
            '</select>',
          focusConfirm: false,
          confirmButtonColor: "#1ea276",
          preConfirm: () => {

            if (parseInt(document.getElementById('month-swal-input1').value) > days || days == 0) {
              this.$swal.showValidationMessage('Недостаточно средств на балансе');
            }

            if (parseInt(document.getElementById('month-swal-input1').value) < 1) {
              this.$swal.showValidationMessage('Количество дней должно быть больше 0');
            }

            return [
              document.getElementById('month-swal-input1').value,
              document.getElementById('month-swal-input2').value
            ]
          }
        })

        if (formValues) {
          this.$axios.post("https://" + window.location.host + "/admin/pay/ref", {
            profile_id: formValues[1],
            days: formValues[0]
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Оплата прошла успешно",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
             
              this.balance = res.data.balance;
            
          })
        }
      },
      async copyReferalLink() {
        this.copyToClipboard("https://wappi.pro/registration?ref=" + this.ref_code).then(() => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Ссылка была скопирована",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
          });
        });
      },
      async copyToClipboard(textToCopy) {
        if (navigator.clipboard && window.isSecureContext) {
          return navigator.clipboard.writeText(textToCopy);
        } else {
          let textArea = document.createElement("textarea");
          textArea.value = textToCopy;
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
            document.execCommand("copy") ? res() : rej();
            textArea.remove();
          });
        }
      },
      sendMessage(){
          if(this.name && this.email && this.message){
            this.$axios.post(this.route('dashboard.support'),
            {
                name: this.name, email: this.email, message: this.message, user: this.$store.state.user
            }
        ).then(res => {
            if (res.data.status == true){
                this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Ваше сообщение доставлено",
                    showConfirmButton: false,
                    timer: 1500,
                    });
                this.name = '',
                this.email = '',
                this.message = ''
            }
        });
          }else if (this.name == ''){
              this.error="Имя не заполнено"
          }else if (this.email == ''){
              this.error="Почта не заполнена"
          }else if (this.message == ''){
              this.error="Сообщение не заполнено"
          }
      },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          } 
          
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            this.$swal.close();
          }
          // if (data.data === 'open') {
          //   let restore_on_open = document.querySelectorAll('.restore_on_open');
          //   restore_on_open.forEach(res => {
          //     res.classList.remove('disabled')
          //   })
          // }
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  props: ["profile", "user", "token", "all_profiles", "all_containers", "user", "payments", "tariffs", "ref_count", "ref_code", "balance", "balances"],
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted() {

    const sidebar = document.querySelector(".sidebar");
    if(window.screen.width < 768){
      sidebar.classList.add("sidebar-mini");
    }
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });


      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  computed: {},
  components: {
    Sidebar,
    Topbar,
    Footer
  },
};
</script>
