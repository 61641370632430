var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "center",
          "font-size": "40px",
          "font-weight": "700",
        },
      },
      [_c("span", { staticClass: "c-content" }, [_vm._v(_vm._s(_vm.code))])]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 mt-2 text-center", attrs: { id: "timerCode" } },
      [_vm._v("02:40")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 mt-3 text-left" }, [
      _c("span", { staticClass: "c-title mb-sm-2" }, [
        _vm._v("Связывание аккаунта Whatsapp "),
        _c("span", { staticClass: "c-content" }, [_vm._v(_vm._s(_vm.phone))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-title mt-2" }, [
        _vm._v("1. Откройте Whatsapp на телефоне."),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "c-title" }, [_vm._v("6. Введите код.")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title" }, [
      _vm._v("\n    2. Нажмите "),
      _c("span", { staticClass: "c-content" }, [_vm._v("Меню")]),
      _vm._v(" или\n    "),
      _c("span", { staticClass: "c-content" }, [_vm._v("Настройки.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title" }, [
      _vm._v("\n    3. Нажмите\n    "),
      _c("span", { staticClass: "c-content" }, [
        _vm._v("Связанные устройства."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title" }, [
      _vm._v("\n    4. Нажмите\n    "),
      _c("span", { staticClass: "c-content" }, [
        _vm._v("Привязка устройства."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title" }, [
      _vm._v("\n    5. Нажмите "),
      _c("span", { staticClass: "c-content" }, [
        _vm._v("Связать по номеру телефона"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }