<template>
    <div>
        <div class="input_wrapper" style="margin-bottom: 3px;">
            <div class="form min_h110">
                <textarea :placeholder="placeholder" 
                ref="waba_textarea"
                :id="'textarea_' + uid"
                autocomplete="off"  
                :class="{'oy_scroll': check_height()}"
                class="bugs_textarea emoji_title" 
                v-model="local_text" 
                maxlength="1028" 
                minlength="1" 
                @change="resText" @drop="resText" @cut="deResText" @paste="deResText" @keydown="deResText" @scroll="scrollStop">
                </textarea>
            </div>
            <div class="upload_files" style="justify-content: end;">
                <div class="symbol_length symbol_length_waba">
                    <span class="d-none d-sm-inline waba_textarea_symbol_desc">Символы: </span>
                    <span>{{ text_length_comp() }}/1028</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">
                <div>
                    <b>переменные:</b><span v-for="(vval, vkey) in text_variables.fields" class="ms-1"><a href="" class="crm_var" :title="vkey" @click.prevent="insertAtCaret(vkey)">{{ vkey }}</a> - {{ vval }};</span>
                </div>
                <div v-for="link in text_variables.links">
                    <span><b>{{ link.name }}:</b> <span v-for="(vv, vk) in link.fields" class="ms-1"><a href="" class="crm_var ms-1" :title="vv" @click.prevent="insertAtCaret(vk)">{{ vk }}</a> - {{ vv }};</span></span>
                    
                </div>
            </span>
        </div>
    </div>
    </template>
    
    
    <script>
    
    export default {
        data() {
            return {
                
                local_text: this.text,
                isTextUpdating: false
            }
        },
        props: ['uid', 'text', 'placeholder', 'text_variables'],
        computed: {
            text_length: function() {
                return function() { 
                    return this.text !== null ? this.text.length : 0; 
                }
            },
        },
        methods: {
            check_height() {
                let ta = document.querySelector('#textarea_' + this.uid);
                if(ta === null) {
                    return false;
                }
                return ta.scrollHeight < 1000 ? false : true;
            },
            text_length_comp() {
                return this.text !== null ? this.text.length : 0; 
            },
            toggle_modal(id) {
                document.getElementById(id).classList.toggle('active');
                document.querySelector('.modal_shadow').classList.toggle('active');
            },
            close_all_modals() {
                let modals = document.querySelectorAll('.my_modal');
                modals.forEach(modal => {
                    modal.classList.remove('active')
                });
                document.querySelector('.modal_shadow').classList.remove('active');
            },
            scrollStop(e) {
                let area = e.target;
                if(area.scrollHeight < 1000) {
                    e.preventDefault();
                    area.scrollTop = 0;
                }
            },
            addEmoji(emoji){
                let doc = new DOMParser().parseFromString(emoji, "text/html");
                console.log(doc.documentElement.textContent);
                this.insertAtCaret(doc.documentElement.textContent);
                this.$emit('update:text', this.local_text);
            },
            resText(e) {
                let area = e.target;
                this.$emit('update:text', area.value);
                e.stopPropagation();
                console.log(area.scrollHeight);
                if(area.scrollHeight < 1000) {
                    area.style.height = 'auto';
                    area.style.height = (area.scrollHeight) + 'px';
                } 
            },
            deResText(e) {
                let text = e.target.value;
                window.setTimeout(() => {
                    this.resText(e, text);
                }, 10);
            },
            insertAtCaret(text) {
                
                let txtarea = document.querySelector('#textarea_' + this.uid);
                
                let strPos = 0;
                let br = (txtarea.selectionStart || txtarea.selectionStart == '0') ? "ff" : (document.selection ? "ie" : false);
                if (br === "ie") {
                    txtarea.focus();
                    let range = document.selection.createRange();
                    range.moveStart('character', -txtarea.value.length);
                    strPos = range.text.length;
                } else if (br === "ff") {
                    strPos = txtarea.selectionStart;
                }

                let front = txtarea.value.substring(0, strPos);
                let back = txtarea.value.substring(strPos, txtarea.value.length);
                this.local_text = front + text + back;
                this.updateText(this.local_text);

                // Обновляем позицию курсора асинхронно
                setTimeout(() => {
                    strPos = strPos + text.length;
                    if (br === "ie") {
                        txtarea.focus();
                        let ieRange = document.selection.createRange();
                        ieRange.moveStart('character', -txtarea.value.length);
                        ieRange.moveStart('character', strPos);
                        ieRange.moveEnd('character', 0);
                        ieRange.select();
                    } else if (br === "ff") {
                        txtarea.selectionStart = strPos;
                        txtarea.selectionEnd = strPos;
                        txtarea.focus();
                    }

                    // Авто-ресайз для textarea
                    txtarea.style.height = 'auto';
                    txtarea.style.height = txtarea.scrollHeight + 'px';
                }, 0);
            },
            wrapSelectedText(type) {
                let textarea;
                textarea = document.querySelector('#textarea_' + this.uid);
                const value = textarea.value; // Текущий текст в textarea
                const start = textarea.selectionStart; // Начальная позиция выделенного текста
                const end = textarea.selectionEnd; // Конечная позиция выделенного текста

                // Проверяем, что есть выделение
                if (start !== end) {
                    const selectedText = value.substring(start, end); // Выделенный текст
                    const beforeText = value.substring(0, start); // Текст до выделения
                    const afterText = value.substring(end); // Текст после выделения

                    // Оборачиваем выделенный текст в квадратные скобки
                    if (type === 'bold') {
                        this.local_text = beforeText + '*' + selectedText + '*' + afterText;
                    } else if (type === 'italic') {
                        this.local_text = beforeText + '_' + selectedText + '_' + afterText;
                    } else if (type === 'strike') {
                        this.local_text = beforeText + '~' + selectedText + '~' + afterText;
                    }

                    this.$emit('update:text', this.local_text);
                    
                    // Перемещаем курсор сразу за закрывающую скобку
                    textarea.selectionStart = textarea.selectionEnd = start + selectedText.length + 2;
                }
            },
            exec_area() {
                window.setTimeout(() => {
                    let area = document.querySelector('#textarea_' + this.uid);
                    if (area) {
                        area.style.height = 'auto';
                        area.style.height = (area.scrollHeight) + 'px';
                    }
                }, 150);
            },
            is_trigger_text_exist(item) {
                let res = false;
                if(this.local_text.includes(item.trigger)) {
                    res = true;
                }
                return res;
            },
            updateText(newText) {
                const textarea = this.$refs.waba_textarea;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;

                this.local_text = newText;
                this.$emit('update:text', newText);
                
                this.$nextTick(() => {
                    textarea.setSelectionRange(start, end);
                    this.isTextUpdating = false; // Сброс флага после завершения всех обновлений
                });
            },
        },
        mounted() {
            let shadow = document.createElement('div');
            shadow.classList.add('modal_shadow');
            document.body.appendChild(shadow);
            this.exec_area();
        },
        components: {
            
        },
    }
    </script>