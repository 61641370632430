var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "input_wrapper" }, [
      _c("div", { staticClass: "form min_h110" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.local_text,
              expression: "local_text",
            },
          ],
          ref: "waba_textarea",
          staticClass: "bugs_textarea emoji_title",
          class: { oy_scroll: _vm.check_height() },
          attrs: {
            placeholder: _vm.placeholder,
            id: "textarea_" + _vm.uid,
            autocomplete: "off",
            maxlength: "1028",
            minlength: "1",
          },
          domProps: { value: _vm.local_text },
          on: {
            change: _vm.resText,
            drop: _vm.resText,
            cut: _vm.deResText,
            paste: _vm.deResText,
            keydown: _vm.doDown,
            scroll: _vm.scrollStop,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.local_text = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "upload_files" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "a",
            {
              staticClass: "button waba_button",
              class: {
                disabled: _vm.text_variables.length == _vm.text_variables_max,
              },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addVariable()
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "21",
                    height: "20",
                    viewBox: "0 0 21 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M10.5 4.375V15.625M16.125 10H4.875",
                      stroke: "currentColor",
                      "stroke-width": "1.25",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "d-inline d-sm-none waba_textarea_var_short",
                  class: "bot_attach_text_" + _vm.uid,
                },
                [_vm._v("Переменная")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "d-none d-sm-inline waba_textarea_var_long",
                  class: "bot_attach_text_" + _vm.uid,
                },
                [_vm._v("Добавить переменную")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "waba_font_pref waba_font_pref_margin",
              on: {
                click: function ($event) {
                  return _vm.wrapSelectedText("bold")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "30",
                    height: "30",
                    viewBox: "0 0 30 30",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M21.5989 22.3413V21.865C20.8984 21.2205 20.7022 20.8002 20.7022 20.0156V11.6095C20.7022 8.52722 18.6007 6.95807 14.5097 6.95807C10.4187 6.95807 8.28911 8.69535 8.03693 12.1979H11.8197C12.0158 10.6288 12.6603 10.1244 14.5937 10.1244C16.1068 10.1244 16.8634 10.6288 16.8634 11.6375C16.8634 13.2347 15.6865 13.0665 13.7251 13.4028L12.156 13.683C9.15775 14.2154 7.70068 15.5043 7.70068 18.2503C7.70068 21.1925 9.77421 22.5935 12.2961 22.5935C13.9773 22.5935 15.5184 21.865 16.8914 20.436C16.8914 21.2205 16.9755 21.893 17.3398 22.3413H21.5989ZM16.8634 15.8686C16.8634 18.1383 15.7426 19.4272 13.7531 19.4272C12.4362 19.4272 11.6236 18.9228 11.6236 17.802C11.6236 16.6532 12.24 16.2329 13.8652 15.9246L15.2102 15.6725C16.2469 15.4763 16.4151 15.4203 16.8634 15.1961V15.8686Z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "waba_font_pref waba_font_pref_margin",
              on: {
                click: function ($event) {
                  return _vm.wrapSelectedText("italic")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "30",
                    height: "30",
                    viewBox: "0 0 30 30",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M22.3132 17.4238L21.4446 16.9474C20.3798 19.077 19.3991 20.2539 18.6706 20.2539C18.3624 20.2539 18.0821 19.9456 18.0821 19.5533C18.0821 19.2731 18.1942 18.7407 18.3904 18.0402L21.1924 8.62532H18.7546L17.9981 11.1472C17.8019 9.4099 16.4009 8.20502 14.5796 8.20502C12.8703 8.20502 11.2732 9.10168 9.95619 10.7829C8.52714 12.6323 7.68652 14.986 7.68652 17.2276C7.68652 19.9736 9.33974 21.795 11.8336 21.795C13.5708 21.795 14.6917 21.0945 16.0927 19.077C15.9806 19.4973 15.9526 19.6934 15.9526 19.9736C15.9526 21.0664 16.6531 21.795 17.6618 21.795C18.8947 21.795 19.9595 21.1225 20.9402 19.7495C21.5007 18.9649 21.977 18.1803 22.3132 17.4238ZM15.7564 17.8441C14.8878 19.5814 13.8791 20.3659 12.478 20.3659C11.077 20.3659 10.2364 19.3292 10.2364 17.6199C10.2364 15.1541 11.3292 12.24 12.8143 10.7269C13.4307 10.0824 14.2153 9.74615 15.0559 9.74615C16.3729 9.74615 17.1855 10.6988 17.1855 12.24C17.1855 13.8371 16.6251 16.0788 15.7564 17.8441Z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "waba_font_pref waba_font_pref_margin",
              on: {
                click: function ($event) {
                  return _vm.wrapSelectedText("strike")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "30",
                    height: "30",
                    viewBox: "0 0 30 30",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M14.6191 7.23633C11.0605 7.23633 8.8779 8.61391 8.73779 12.0044H11.0889C11.285 10.2111 12.353 9.39697 14.5386 9.39697C16.6401 9.39697 17.8125 10.1786 17.8125 11.5796V12.1948C17.8125 13.1755 17.2302 13.5966 15.3809 13.8208C12.0744 14.2411 11.568 14.3574 10.6714 14.7217C9.9808 15.0047 9.43041 15.3892 9.01611 15.8643H4.86328V17.5854H8.18115C8.12214 17.8831 8.09326 18.1993 8.09326 18.5303C8.09326 21.1922 9.94242 22.7637 12.9126 22.7637C14.79 22.7637 16.6114 21.976 17.9004 20.603C18.1526 21.7238 19.1612 22.5366 20.3101 22.5366C20.7864 22.5366 21.1502 22.4837 21.9067 22.2876V20.5225C21.6546 20.5785 21.5415 20.5737 21.4014 20.5737C20.5888 20.5737 20.1416 20.1524 20.1416 19.4238V17.5854H25.1367V15.8643H20.1416V11.2427C20.1416 8.63677 18.2338 7.23633 14.6191 7.23633ZM17.8125 15.0806V15.8643H14.2749C16.5245 15.5544 17.0554 15.4321 17.8125 15.0806ZM10.686 17.5854H17.7979C17.5941 19.5027 15.5703 20.7129 13.418 20.7129C11.5966 20.7129 10.5322 20.0688 10.5322 18.4717C10.5322 18.1346 10.5824 17.8453 10.686 17.5854Z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "waba_font_pref_margin" },
            [
              _c("Emoji", {
                attrs: { uid: _vm.uid, text: _vm.local_text },
                on: {
                  "update:text": function ($event) {
                    _vm.local_text = $event
                  },
                  addEmoji: _vm.addEmoji,
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "symbol_length symbol_length_waba" }, [
          _c(
            "span",
            { staticClass: "d-none d-sm-inline waba_textarea_symbol_desc" },
            [_vm._v("Символы: ")]
          ),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.text_length_comp()) + "/1028")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }