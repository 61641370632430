<template>
    <div class="d-flex flex-column col-12 text-center align-items-center">
        <div class="w-100 mb-3" id="qr_container">
            <img class="img-fluid qr_code" @click="scaleQr" :src="base64_qr" alt="qr code" v-if="base64_qr !== ''" />
        </div>
        <div class="cssload-container" v-if="base64_qr === ''">
            <div class="cssload-cube">
                <div class="cssload-half1">
                <div class="cssload-side cssload-s1"></div>
                <div class="cssload-side cssload-s2"></div>
                <div class="cssload-side cssload-s5"></div>
                </div>
                <div class="cssload-half2">
                <div class="cssload-side cssload-s3"></div>
                <div class="cssload-side cssload-s4"></div>
                <div class="cssload-side cssload-s6"></div>
                </div>
            </div>
        </div>

        <div class="col-12 mt-2 text-left">
            <span class="c-title mb-sm-2">Отсканируйте код в приложении Whatsapp на телефоне.</span>
            <div class="c-title">1. Откройте Whatsapp на телефоне.</div>
            <div class="c-title">
            2. Нажмите <span class="c-content">Меню</span> или
            <span class="c-content">Настройки.</span>
            </div>
            <div class="c-title">
            3. Нажмите
            <span class="c-content">Связанные устройства.</span>
            </div>
            <div class="c-title">
            4. Нажмите
            <span class="c-content">Привязка устройства.</span>
            </div>
            <div class="c-title">
            5. Наведите камеру на экран, чтобы считать этот код.
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  props: ["base64_qr"],
  methods: {
    scaleQr() {
      $("#qr-modal").modal("show");
    },
  },
}
</script>