var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column col-12 text-center align-items-center" },
    [
      _c("div", { staticClass: "w-100 mb-3", attrs: { id: "qr_container" } }, [
        _vm.base64_qr !== ""
          ? _c("img", {
              staticClass: "img-fluid qr_code",
              attrs: { src: _vm.base64_qr, alt: "qr code" },
              on: { click: _vm.scaleQr },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.base64_qr === ""
        ? _c("div", { staticClass: "cssload-container" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cssload-cube" }, [
      _c("div", { staticClass: "cssload-half1" }, [
        _c("div", { staticClass: "cssload-side cssload-s1" }),
        _vm._v(" "),
        _c("div", { staticClass: "cssload-side cssload-s2" }),
        _vm._v(" "),
        _c("div", { staticClass: "cssload-side cssload-s5" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cssload-half2" }, [
        _c("div", { staticClass: "cssload-side cssload-s3" }),
        _vm._v(" "),
        _c("div", { staticClass: "cssload-side cssload-s4" }),
        _vm._v(" "),
        _c("div", { staticClass: "cssload-side cssload-s6" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 mt-2 text-left" }, [
      _c("span", { staticClass: "c-title mb-sm-2" }, [
        _vm._v("Отсканируйте код в приложении Whatsapp на телефоне."),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-title" }, [
        _vm._v("1. Откройте Whatsapp на телефоне."),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-title" }, [
        _vm._v("\n        2. Нажмите "),
        _c("span", { staticClass: "c-content" }, [_vm._v("Меню")]),
        _vm._v(" или\n        "),
        _c("span", { staticClass: "c-content" }, [_vm._v("Настройки.")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-title" }, [
        _vm._v("\n        3. Нажмите\n        "),
        _c("span", { staticClass: "c-content" }, [
          _vm._v("Связанные устройства."),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-title" }, [
        _vm._v("\n        4. Нажмите\n        "),
        _c("span", { staticClass: "c-content" }, [
          _vm._v("Привязка устройства."),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-title" }, [
        _vm._v(
          "\n        5. Наведите камеру на экран, чтобы считать этот код.\n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }