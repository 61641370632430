<template>
  <div class="iq-navbar-header">
    <div class="container-fluid iq-container">
      <div class="row">
        <div class="col-md-12">
          <div>
            <h1 class="c-content d-md-block">Whatsapp профиль</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-header-img"></div>
    <!--    main block-->
    <div class="container-fluid content-inner py-0 my-mt-5" :style="this.$store.state.user.moysklad ? 'min-height:initial !important' : ''">
      <!--      account block begin-->
      <div class="row g-0 g-sm-2 g-md-4">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap align-items-center mb-3">
                <div class="position-relative me-3 profile-logo profile-logo1 d-none d-xxl-block">
                  <svg width="80" height="80" viewBox="0 0 22 22" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.0027 0H10.9972C4.93211 0 0 4.93349 0 11C0 13.4062 0.775498 15.6365 2.09412 17.4473L0.723248 21.5338L4.95136 20.1822C6.69073 21.3344 8.7656 21.9999 11.0027 21.9999C17.0678 21.9999 21.9999 17.0651 21.9999 11C21.9999 4.93486 17.0678 0 11.0027 0ZM17.4033 15.5333C17.138 16.2827 16.0847 16.9042 15.2446 17.0857C14.6698 17.2081 13.9191 17.3057 11.3918 16.258C8.15923 14.9187 6.07748 11.6338 5.91523 11.4207C5.75986 11.2076 4.60899 9.68135 4.60899 8.10285C4.60899 6.52436 5.41061 5.75573 5.73374 5.42574C5.99911 5.15486 6.43773 5.03111 6.85848 5.03111C6.99461 5.03111 7.11698 5.03799 7.22698 5.04349C7.55011 5.05724 7.71235 5.07649 7.92548 5.58661C8.19085 6.22598 8.8371 7.80448 8.9141 7.96673C8.99248 8.12898 9.07085 8.34898 8.96085 8.5621C8.85773 8.7821 8.76698 8.87973 8.60473 9.06673C8.44248 9.25373 8.28848 9.39673 8.12623 9.59748C7.97773 9.7721 7.80998 9.9591 7.99698 10.2822C8.18398 10.5985 8.83023 11.6531 9.78172 12.5001C11.0096 13.5932 12.0051 13.9425 12.3612 14.091C12.6266 14.201 12.9428 14.1748 13.1367 13.9686C13.3828 13.7032 13.6867 13.2632 13.9961 12.8301C14.2161 12.5193 14.4938 12.4808 14.7853 12.5908C15.0823 12.694 16.654 13.4708 16.9771 13.6317C17.3002 13.794 17.5133 13.871 17.5917 14.0071C17.6687 14.1432 17.6687 14.7826 17.4033 15.5333Z"
                      fill="#23BB86" />
                  </svg>
                </div>
                <div>
                  <div class="d-flex flex-wrap align-items-center mb-3 mb-sm-0 d-none d-md-block">
                    <h4 class="me-2 h4">{{ profileName }}</h4>
                  </div>
                  <div class="d-none d-md-block">
                    <span class="c-title last_activity_text" v-if="this.$store.state.current_profile.last_activity != null">Последняя активность {{ this.$store.state.current_profile.last_activity | moment2 }} (GMT+03.00, Москва)</span>
                  </div>
                </div>
              </div>

              <div class="row profile_details">
                <div class="col-lg-6 col-12 profile_details_first">
                  <!-- -->

                  <div class="row">

                    <!-- col-xxxl-6 -->
                    <div class="col-12">

                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="c-title">Имя:</span>
                        <span class="c-content ms-2" v-if="!edited">{{
                        profileName
                        }}</span>
                        <input :maxlength="maxLength" type="text"
                          class="form-control name c-content h- me-1 name_form ms-1 ms-sm-2 me-sm-2"
                          v-on:keyup.enter="saveName" v-model="profileName" v-if="edited" />
                        <span v-if="edited" @click="saveName" class="btn btn-sm btn-success">
                          Сохранить
                        </span>
                        <span class="c-ico"><img @click="editName()" v-if="!edited" src="/static/images/edit.svg"
                            title="Редактировать имя" class="img-fluid" /></span>
                        <span class="c-content ml-2" v-if="edited">
                          {{ profileName.length }} / {{ maxLength }}</span>
                      </div>
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="c-title">Статус<span class="d-none d-sm-inline"> приложения</span>:</span>
                        <span class="c-badge green" v-if="this.$store.state.wa_status === 'open'">open</span>
                        <span class="c-badge yellow" v-if="this.$store.state.wa_status === 'connecting'">connecting</span>
                        <span class="c-badge gray" v-if="this.$store.state.wa_status === 'close'">close</span>
                      </div>
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="c-title">Токен API:</span>
                        <div>
                          <span class="c-content ms-0 ms-sm-2 token_text">{{
                          this.$store.state.token
                          }}</span>
                          <span class="c-ico d-none d-sm-inline" data-toggle="tooltip" title="Копировать в буфер"><img
                              src="/static/images/copy.svg" alt="edit" class="img-fluid" @click="copyToken" /></span>
                        </div>
                      </div>
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="c-title">ID профиля:</span>
                        <span class="c-content ms-2">{{
                        this.$store.state.current_profile.uuid
                        }}</span>
                        <span class="c-ico d-none d-sm-inline" data-toggle="tooltip" title="Копировать в буфер"><img
                            src="/static/images/copy.svg" alt="edit" class="img-fluid" @click="copyUuid" /></span>
                      </div>
                    </div>
                    

                    <div class="col-12 mt-3">

                      <WebhookSettings></WebhookSettings>

                      <CRMBlock class="mt-2" :profile="this.$store.state.current_profile"></CRMBlock>

                    </div>
                    
                  </div>

                </div>
                <div class="col-lg-6 col-12 border-left px-lg-4 px-xxl-5">
                  <div class="row">

                    <!-- col-xxxl-6 -->
                    <div class="col-12 gx-lg-2">
                      <div class="info-block d-flex flex-wrap align-items-center" style="padding: 8px 0;">
                        <span class="c-title"><i>Настройки профиля:</i></span>
                      </div>

                      <div class="info-block d-flex flex-wrap align-items-center" v-if="!this.$store.state.user.moysklad">

                        <svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.625 2.96875C0.625 1.94372 1.54547 1.25 2.5 1.25H12.5C13.4546 1.25 14.375 1.94372 14.375 2.96875V5.15625C14.375 6.18127 13.4546 6.875 12.5 6.875H2.5C1.54547 6.875 0.625 6.18127 0.625 5.15625V2.96875ZM4.375 4.0625C4.375 4.58027 3.95527 5 3.4375 5C2.91973 5 2.5 4.58027 2.5 4.0625C2.5 3.54473 2.91973 3.125 3.4375 3.125C3.95527 3.125 4.375 3.54473 4.375 4.0625Z" fill="#6C757D"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.625 9.84375C0.625 8.81875 1.54547 8.125 2.5 8.125H12.5C13.4546 8.125 14.375 8.81875 14.375 9.84375V12.0312C14.375 13.0563 13.4546 13.75 12.5 13.75H2.5C1.54547 13.75 0.625 13.0563 0.625 12.0312V9.84375ZM4.375 10.9375C4.375 11.4553 3.95527 11.875 3.4375 11.875C2.91973 11.875 2.5 11.4553 2.5 10.9375C2.5 10.4197 2.91973 10 3.4375 10C3.95527 10 4.375 10.4197 4.375 10.9375Z" fill="#6C757D"/>
                        </svg>

                        <span class="c-title" style="margin-left: 6px;">Прокси:</span>
                        <span class="c-content ms-2" v-if="!edited_proxy && user_proxy.length > 0">{{ user_proxy }}</span>
                        <span class="c-content ms-1" v-if="!edited_proxy && user_proxy.length === 0">встроенный</span>
                        <input type="text"
                          class="form-control name c-content h- me-1 name_form ms-1 ms-sm-2 me-sm-2"
                          v-on:keyup.enter="saveUserProxy" v-model="user_proxy" v-if="edited_proxy" placeholder="login:password@ip:port или ip:port"/>
                        <span v-if="edited_proxy" @click="saveUserProxy" class="btn btn-sm btn-success">
                          Сохранить
                        </span>
                        <span class="c-ico"><img @click="editUserProxy()" v-if="!edited_proxy" src="/static/images/edit.svg"
                            title="Редактировать прокси" class="img-fluid" />
                        </span>
                        <span class="mx-2" data-toggle="tooltip" data-placement="right"
                          title="Подходят только https/http прокси в формате `login:password@ip:port` или `ip:port`. Если поле пустое, то используются прокси сервиса"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                        </span>
                      </div>

                      <div v-if="!this.user.spamer && !this.$store.state.user.moysklad" class="info-block d-flex flex-wrap align-items-center">
                        <input type="checkbox" class="form-check-input" id="remember_billing" v-model="paynotify"
                          @click="paynotify_change" />
                        <label class="m-0" for="remember_billing">
                          <span class="c-title">Напоминания об оплате</span>
                        </label>

                        <span class="mx-2" data-toggle="tooltip" data-placement="right"
                          title="Вам на почту будут приходить уведомления об окончании оплаченого периода"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                        </span>
                      </div>

                      <div v-if="!this.user.spamer && !this.$store.state.user.moysklad" class="info-block d-flex flex-wrap align-items-center">
                        <input type="checkbox" class="form-check-input" id="auth_notify" v-model="authnotify"
                          @click="authnotify_change" />
                        <label class="m-0" for="auth_notify">
                          <span class="c-title">Уведомления о разавторизации</span>
                        </label>

                        <span class="mx-2" data-toggle="tooltip" data-placement="right"
                          title="Вам на почту будут приходить уведомления о разавторизации профиля"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                              fill="#6c757d" />
                          </svg>
                        </span>
                      </div>

                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="m-0 c-title" style="color:#009BDE">
                          <svg style="margin-top: -3px;" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_5767_87)">
                            <path d="M7.84758 12.6508L7.51675 17.3041C7.99008 17.3041 8.19508 17.1008 8.44091 16.8566L10.6601 14.7358L15.2584 18.1033C16.1017 18.5733 16.6959 18.3258 16.9234 17.3274L19.9417 3.1841L19.9426 3.18326C20.2101 1.9366 19.4917 1.4491 18.6701 1.75493L0.928413 8.54743C-0.28242 9.01743 -0.264087 9.69243 0.72258 9.99826L5.25841 11.4091L15.7942 4.8166C16.2901 4.48826 16.7409 4.66993 16.3701 4.99826L7.84758 12.6508Z" fill="currentColor"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_5767_87">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                          <span class="c-title" style="margin-left: 2px;"><a href="https://t.me/WappiProBot" target="_blank" class="c-title text-decoration-underline billing-history" style="color: #009BDE;">Уведомления в Telegram</a></span>
                        </span>
                      </div>

                      <!-- spamer block -->
                      <div v-if="this.user.spamer" class="info-block d-flex flex-wrap align-items-center">
                        <span class="c-title">Лимит сообщений:</span>
                        <span class="c-content ms-2">{{ msg_limit }}</span>
                      </div>
                      <div v-if="this.user.spamer" class="info-block d-flex flex-wrap align-items-center">
                        <span class="c-title">Таймаут между сообщениями:</span>
                        <span class="c-content ms-2">{{ msg_timeout }}</span>
                      </div>
                      <!-- end of spamer block -->

                      <div class="info-block d-flex flex-wrap align-items-center">
                        
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" style="margin-top: -2px"
                        viewBox="0 0 512 512">
                        <path fill="#6c757d"
                          d="M449.9 39.96l-48.5 48.53C362.5 53.19 311.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.97 5.5 34.86-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c37.96 0 73 14.18 100.2 37.8L311.1 178C295.1 194.8 306.8 223.4 330.4 224h146.9C487.7 223.7 496 215.3 496 204.9V59.04C496 34.99 466.9 22.95 449.9 39.96zM441.8 289.6c-16.94-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-37.96 0-73-14.18-100.2-37.8L200 334C216.9 317.2 205.2 288.6 181.6 288H34.66C24.32 288.3 16 296.7 16 307.1v145.9c0 24.04 29.07 36.08 46.07 19.07l48.5-48.53C149.5 458.8 200.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z" />
                      </svg>
                      <a class="c-title restore_on_open text-decoration-underline billing-history" @click="profile_restart">
                        <span class="reboot_app">Перезагрузить приложение</span>
                      </a>                          
                      <span class="mx-2" data-toggle="tooltip" data-placement="right"
                        title="Перезагрузка приложения занимает около 60 секунд"><svg width="16" height="16"
                          viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z"
                            fill="#6c757d" />
                        </svg>
                      </span>
                        
                      </div>
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <svg width="18" style="margin-top: -2px" viewBox="0 0 24 24" fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
                            fill="#6c757d"></path>
                        </svg>
                        <a class="c-title text-decoration-underline billing-history delete_link restore_on_open" @click="deleteInstance">
                          <span style="margin-left: 6px;">Удалить профиль</span>
                        </a>
                      </div>
                    </div>
                    <!-- -->
                    <!-- col-xxxl-6 -->
                    <div class="col-12 gx-lg-2 mt-3">
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="c-title"><i>Статистика сообщений:</i></span>
                      </div>

                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="m-0 c-title">
                          <svg width="18" height="18" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.515 5 14.934V20L10.34 15.995C15.697 15.853 20 12.32 20 8C20 3.589 15.514 0 10 0ZM9 12.414L5.293 8.707L6.707 7.293L9 9.586L13.793 4.793L15.207 6.207L9 12.414Z"
                              fill="currentColor" />
                          </svg>
                          <span class="c-title">Отправлено сегодня:
                            <span class="c-content">{{
                            this.$store.state.today_tasks_count
                            }}</span></span>
                        </span>
                      </div>
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="m-0 c-title">
                          <svg width="18" height="18" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.515 5 14.934V20L10.34 15.995C15.697 15.853 20 12.32 20 8C20 3.589 15.514 0 10 0ZM9 12.414L5.293 8.707L6.707 7.293L9 9.586L13.793 4.793L15.207 6.207L9 12.414Z"
                              fill="currentColor" />
                          </svg>
                          <span class="c-title">Отправлено всего:
                            <span class="c-content">{{
                            this.$store.state.current_profile.message_count
                            }}</span></span>
                        </span>
                      </div>
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="m-0 c-title">
                          <svg width="18" height="18" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.516 5 14.934V20L10.34 15.995C15.697 15.854 20 12.32 20 8C20 3.589 15.514 0 10 0ZM11 12H9V10H11V12ZM11 8H9V3H11V8Z"
                              fill="currentColor" />
                          </svg>
                          <span class="c-title">Сообщений в очереди:
                            <span class="c-content">{{
                            this.$store.state.pending_tasks_count
                            }}</span></span>
                          <a class="c-title delete_link restore_on_open" @click="purgeQueue">
                            <span class="mx-0" data-toggle="tooltip" data-placement="right"
                              title="Очистить сообщения из очереди">
                              <svg width="18" style="margin-top: -2px" viewBox="0 0 24 24" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
                                  fill="currentColor"></path>
                              </svg>
                            </span>
                          </a>
                        </span>
                      </div>
                      <div class="info-block d-flex flex-wrap align-items-center">
                        <span class="m-0 c-title">
                          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.515 5 14.934V20L10.34 15.995C15.697 15.853 20 12.32 20 8C20 3.589 15.514 0 10 0ZM12 11H5V9H12V11ZM15 7H5V5H15V7Z" fill="currentColor"/>
                          </svg>
                          <span class="c-title"><inertia-link :href="route('dashboard.messages_history', this.$store.state.current_profile.uuid)" class="c-title text-decoration-underline billing-history">История сообщений</inertia-link></span>
                        </span>
                      </div>
                      
                    </div>
                    <!-- -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      account block end-->
      <div class="row g-0 g-sm-2 g-md-4">
        <!--      qr code block begin-->
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 block_qr_code">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div class="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                  <h4 class="h4">Авторизация</h4>
                </div>
              </div>
              <div class="alert alert-success d-flex align-items-center mt-3" role="alert"
                v-if="this.$store.state.current_profile.authorized">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.0263 11.2632C23.0263 16.7872 18.5493 21.2632 13.0263 21.2632C7.50331 21.2632 3.02631 16.7872 3.02631 11.2632C3.02631 5.74115 7.50331 1.26315 13.0263 1.26315C18.5493 1.26315 23.0263 5.74115 23.0263 11.2632Z"
                    fill="#12703A"></path>
                  <path
                    d="M11.261 17C10.9328 17 10.6045 16.8692 10.3538 16.6059L6.87593 12.9532C6.37469 12.4268 6.37469 11.574 6.87593 11.0491C7.37717 10.5227 8.18765 10.5211 8.6889 11.0476L11.261 13.749L17.3111 7.39483C17.8123 6.86839 18.6228 6.86839 19.1241 7.39483C19.6253 7.92127 19.6253 8.77403 19.1241 9.30047L12.1683 16.6059C11.9176 16.8692 11.5893 17 11.261 17Z"
                    fill="white"></path>
                </svg>
                <div class="ms-2 alert_text">Вы авторизованы</div>
              </div>
              <div class="alert alert-danger d-flex mt-3 align-items-center" role="alert"
                v-if="!this.$store.state.current_profile.authorized">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.72251 21.1672C4.70951 21.1672 4.69751 21.1672 4.68351 21.1662C4.36851 21.1502 4.05951 21.0822 3.76551 20.9632C2.31851 20.3752 1.62051 18.7222 2.20751 17.2762L9.52851 4.45025C9.78051 3.99425 10.1625 3.61225 10.6285 3.35425C11.9935 2.59825 13.7195 3.09525 14.4745 4.45925L21.7475 17.1872C21.9095 17.5682 21.9785 17.8782 21.9955 18.1942C22.0345 18.9502 21.7765 19.6752 21.2705 20.2362C20.7645 20.7972 20.0695 21.1282 19.3145 21.1662L4.79451 21.1672H4.72251Z"
                    fill="#862317"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.1245 10.0208C11.1245 9.53875 11.5175 9.14575 11.9995 9.14575C12.4815 9.14575 12.8745 9.53875 12.8745 10.0208V12.8488C12.8745 13.3318 12.4815 13.7238 11.9995 13.7238C11.5175 13.7238 11.1245 13.3318 11.1245 12.8488V10.0208ZM11.1245 16.2699C11.1245 15.7849 11.5175 15.3899 11.9995 15.3899C12.4815 15.3899 12.8745 15.7799 12.8745 16.2589C12.8745 16.7519 12.4815 17.1449 11.9995 17.1449C11.5175 17.1449 11.1245 16.7519 11.1245 16.2699Z"
                    fill="white"></path>
                </svg>
                <div class="ms-2">Вы не авторизованы</div>
              </div>
              <div v-if="
                  this.$store.state.current_profile.authorized &&
                  !is_task_exist" class="row mb-3 mb-sm-0">
                <div class="col-12 col-md-12 col-lg-12 text-center">
                  <button type="button" @click="profile_logout"
                    class="btn resp-button btn-outline-secondary text-decoration-none restore_on_open mt-2 mb-1">
                    <span class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 512 512" style="margin-top: -2px">
                        <path
                          d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
                      </svg>
                    </span>
                    Выйти из whatsapp
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 c-title" v-if="this.$store.state.current_profile.authorized">
                <div class="mt-3 text-left" style="color: #6c757d">
                  Последний раз вы были авторизованы
                  {{
                  this.$store.state.current_profile.authorized_at | short
                  }}
                  с номера
                  <span class="c-content">{{
                  this.$store.state.current_profile.phone
                  }}</span>. Если вы хотите сбросить авторизацию этого профиля в
                  Whatsapp нажмите «Выйти из Whatsapp».
                </div>
              </div>
              <div class="d-flex flex-column col-12 text-center">
                <div v-if="
                  !this.$store.state.current_profile.authorized
                " class="col-12 col-md-12 col-lg-12 text-center mt-2 mb-2">
                  <!-- get_qr_animate -->
                  <button id="qr_request_button" type="button" @click="authorize"
                    class="btn resp-button btn-billing text-decoration-none">
                    <span class="me-2">
                      <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#fff" d="M144 32C170.5 32 192 53.49 192 80V176C192 202.5 170.5 224 144 224H48C21.49 224 0 202.5 0 176V80C0 53.49 21.49 32 48 32H144zM128 96H64V160H128V96zM144 288C170.5 288 192 309.5 192 336V432C192 458.5 170.5 480 144 480H48C21.49 480 0 458.5 0 432V336C0 309.5 21.49 288 48 288H144zM128 352H64V416H128V352zM256 80C256 53.49 277.5 32 304 32H400C426.5 32 448 53.49 448 80V176C448 202.5 426.5 224 400 224H304C277.5 224 256 202.5 256 176V80zM320 160H384V96H320V160zM352 448H384V480H352V448zM448 480H416V448H448V480zM416 288H448V416H352V384H320V480H256V288H352V320H416V288z"></path></svg>
                    </span>
                    <span>Авторизовать</span>
                  </button>
                </div>

                <div v-if="!this.$store.state.current_profile.authorized" class="col-12 mt-2 text-left">
                  <span class="c-title mb-sm-2 mt-2">Авторизоваться в Whatsapp можно двумя способами:</span>

                  <div class="c-content bold mt-2">Сканирование QR-кода</div>
                  <div class="c-title mt-2">1. Откройте Whatsapp на телефоне.</div>
                  <div class="c-title">2. Нажмите <span class="c-content">Меню</span> или <span class="c-content">Настройки.</span></div>
                  <div class="c-title">3. Нажмите <span class="c-content">Связанные устройства.</span></div>
                  <div class="c-title">4. Нажмите <span class="c-content">Привязка устройства.</span></div>
                  <div class="c-title">5. Наведите камеру на экран, чтобы считать этот код.</div>

                  <div class="c-content bold mt-2">По номеру телефона</div>
                  <div class="c-title mt-2">1. Введите в форму номер телефона вашего Whatsapp.</div>
                  <div class="c-title">2. Вы получите код для ввода в приложении Whatsapp.</div>
                  <div class="c-title">3. Откройте Whatsapp на телефоне.</div>
                  <div class="c-title">4. Нажмите <span class="c-content">Меню</span> или <span class="c-content">Настройки.</span></div>
                  <div class="c-title">5. Нажмите <span class="c-content">Связанные устройства.</span></div>
                  <div class="c-title">6. Нажмите <span class="c-content">Связать по номеру телефона.</span></div>
                  <div class="c-title">7. Нажмите <span class="c-content">Привязка устройства.</span></div>
                  <div class="c-title">8. Введите код.</div>
                </div>

                <div class="mt-3 text-left">
                  <span class="c-title text-decoration-underline billing-history" @click="showAuthHistory">История авторизаций</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        qr code block end-->
        <!--        billing block begin-->
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 block_billing">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap align-items-center justify-content-between">
                <h4 class="me-2 h4">Оплата</h4>
              </div>
              <div class="alert alert-success vip-partner d-flex align-items-center mt-3" role="alert" v-if="!user.with_payments">
                <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.97162 2.03967L5.77662 5.38767L9.16662 1.21367C9.26154 1.09698 9.38157 1.00321 9.51776 0.939341C9.65395 0.875473 9.8028 0.843152 9.95322 0.844786C10.1036 0.846419 10.2517 0.881966 10.3865 0.948778C10.5213 1.01559 10.6392 1.11195 10.7316 1.23067L13.9666 5.38667L17.8946 1.99067C18.0489 1.85758 18.2401 1.77478 18.4427 1.75335C18.6453 1.73192 18.8496 1.77289 19.0283 1.87077C19.207 1.96865 19.3515 2.11877 19.4425 2.30104C19.5335 2.4833 19.5667 2.68903 19.5376 2.89067L18.0376 13.2377H1.84462L0.321618 2.93767C0.291365 2.73491 0.324163 2.52774 0.415568 2.34424C0.506974 2.16074 0.652571 2.00976 0.832638 1.91176C1.01271 1.81376 1.21854 1.77347 1.42227 1.79635C1.626 1.81923 1.81777 1.90417 1.97162 2.03967ZM1.92262 14.2377H17.9226V15.2377C17.9226 15.5029 17.8173 15.7572 17.6297 15.9448C17.4422 16.1323 17.1878 16.2377 16.9226 16.2377H2.92262C2.6574 16.2377 2.40305 16.1323 2.21551 15.9448C2.02797 15.7572 1.92262 15.5029 1.92262 15.2377V14.2377Z" fill="#FFA300"/>
                </svg>
                <div class="ms-2">VIP Партнер</div>
              </div>
              <div class="alert alert-success d-flex align-items-center mt-3" role="alert" v-if="show_not_paid && user.with_payments">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.0263 11.2632C23.0263 16.7872 18.5493 21.2632 13.0263 21.2632C7.50331 21.2632 3.02631 16.7872 3.02631 11.2632C3.02631 5.74115 7.50331 1.26315 13.0263 1.26315C18.5493 1.26315 23.0263 5.74115 23.0263 11.2632Z"
                    fill="#12703A" />
                  <path
                    d="M11.261 17C10.9328 17 10.6045 16.8692 10.3538 16.6059L6.87593 12.9532C6.37469 12.4268 6.37469 11.574 6.87593 11.0491C7.37717 10.5227 8.18765 10.5211 8.6889 11.0476L11.261 13.749L17.3111 7.39483C17.8123 6.86839 18.6228 6.86839 19.1241 7.39483C19.6253 7.92127 19.6253 8.77403 19.1241 9.30047L12.1683 16.6059C11.9176 16.8692 11.5893 17 11.261 17Z"
                    fill="white" />
                </svg>
                <div class="ms-2">Оплачено</div>
              </div>
              <div class="alert alert-danger d-flex align-items-center mt-3" role="alert" v-if="!show_not_paid && user.with_payments">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.72251 21.1672C4.70951 21.1672 4.69751 21.1672 4.68351 21.1662C4.36851 21.1502 4.05951 21.0822 3.76551 20.9632C2.31851 20.3752 1.62051 18.7222 2.20751 17.2762L9.52851 4.45025C9.78051 3.99425 10.1625 3.61225 10.6285 3.35425C11.9935 2.59825 13.7195 3.09525 14.4745 4.45925L21.7475 17.1872C21.9095 17.5682 21.9785 17.8782 21.9955 18.1942C22.0345 18.9502 21.7765 19.6752 21.2705 20.2362C20.7645 20.7972 20.0695 21.1282 19.3145 21.1662L4.79451 21.1672H4.72251Z"
                    fill="#862317" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.1245 10.0208C11.1245 9.53875 11.5175 9.14575 11.9995 9.14575C12.4815 9.14575 12.8745 9.53875 12.8745 10.0208V12.8488C12.8745 13.3318 12.4815 13.7238 11.9995 13.7238C11.5175 13.7238 11.1245 13.3318 11.1245 12.8488V10.0208ZM11.1245 16.2699C11.1245 15.7849 11.5175 15.3899 11.9995 15.3899C12.4815 15.3899 12.8745 15.7799 12.8745 16.2589C12.8745 16.7519 12.4815 17.1449 11.9995 17.1449C11.5175 17.1449 11.1245 16.7519 11.1245 16.2699Z"
                    fill="white" />
                </svg>
                <div class="ms-2">Не оплачено</div>
              </div>
              <div class="alert description" role="alert" v-if="this.$store.state.current_profile.is_subscribe">
                Оформлена подписка.
                <span class="text-gray">Следующее списание:</span>
                <span class="text-gray" v-if="typeof last_payment.tariff !== 'undefined'">
                  {{ last_payment.expired_at | short }} —
                  {{ last_payment.tariff["price"] }}₽
                </span>
                <div class="mt-2">
                  <a class="text-decoration-underline billing-history" @click="cancel_subscribe">Отменить подписку</a>
                </div>
              </div>
              <div class="row" v-if="typeof last_payment !== 'undefined'">
                <div class="col-6" v-if="hours_left == 0">
                  <span class="text-uppercase text-up">Осталось дней</span>
                  <div>
                    <span class="text-green-big">{{ days_left_sklad }} д.</span> <!-- days_left -->
                  </div>
                </div>
                <div class="col-6" v-if="hours_left != 0">
                  <span class="text-uppercase text-up">Осталось часов</span>
                  <div>
                    <span class="text-green-big">{{ hours_left }} ч.</span>
                  </div>
                </div>
                <div class="col-6">
                  <span class="text-uppercase text-up">оплачено до</span>
                  <div> <!-- last_payment.expired_at | short -->
                    <span v-if="last_payment" class="billing-date">{{
                      expired_at_sklad
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-17">
                <span class="c-title">Своевременно оплачивайте профили во избежание отключения.
                  Рекомендуем вам включить напоминания, либо оформить
                  подписку.</span>
              </div>
              <div class="mt-3 row align-items-center" v-if="this.$store.state.current_profile.crm_id != 'mspay'">
                <div class="col-md-6 text-center">
                  <button v-if="this.$store.state.user.use_test" type="button" class="btn btn-billing" @click="showPayModal">
                    Оплатить
                  </button>
                  <button v-if="!this.$store.state.user.use_test" type="button" class="btn btn-billing" @click="showPayModal">
                    Бесплатный тест
                  </button>
                </div>
                <div class="col-md-6 text-center billing__history">
                  <a class="c-title text-decoration-underline billing-history" @click="showPayHistoryModal">История
                    оплаты</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Hellomodal -->
        <div class="modal fade" id="hello-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content p-4 p-lg-33 rounded">
              <div class="mx-auto">
                <h5 class="modal-title text-black">
                  Как начать пользоваться <span class="text-success"><b>Whatsapp</b></span> <strong>Wappi</strong>
                </h5>
              </div>
              <div>
                <div class="rounded alert-info p-4 mt-10">
                  <div class="text-black text-center">
                    <h5>1. Оплатите</h5>
                  </div>
                  <div class="text-gray mt-2">
                    Чтобы воспользоваться бесплатным промопериодом нажмите
                    <button class="btn btn-success btn-min">БЕСПЛАТНЫЙ ТЕСТ</button>,
                    далее выберите тариф <u>«Активировать промо период»</u>.
                  </div>
                </div>
              </div>
              <div class="">
                <div class="rounded alert-primary p-4 mt-23">
                  <div class="text-black text-center">
                    <h5>2. Подключитесь к Wappi</h5>
                  </div>
                  <div class="text-gray mt-2">
                    Чтобы подключить ваш
                    <span class="text-success">Whatsapp</span> к нашему сервису
                    - нажмите
                    <button class="btn btn-success btn-min">
                      <span style="margin-right: 4px">
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#fff" d="M144 32C170.5 32 192 53.49 192 80V176C192 202.5 170.5 224 144 224H48C21.49 224 0 202.5 0 176V80C0 53.49 21.49 32 48 32H144zM128 96H64V160H128V96zM144 288C170.5 288 192 309.5 192 336V432C192 458.5 170.5 480 144 480H48C21.49 480 0 458.5 0 432V336C0 309.5 21.49 288 48 288H144zM128 352H64V416H128V352zM256 80C256 53.49 277.5 32 304 32H400C426.5 32 448 53.49 448 80V176C448 202.5 426.5 224 400 224H304C277.5 224 256 202.5 256 176V80zM320 160H384V96H320V160zM352 448H384V480H352V448zM448 480H416V448H448V480zM416 288H448V416H352V384H320V480H256V288H352V320H416V288z"></path></svg>
                      </span>
                      АВТОРИЗОВАТЬ
                    </button>
                    и авторизуйтесь в приложении
                    <span class="text-success">Whatsapp</span> на телефоне.
                  </div>
                </div>
              </div>
              <div class="">
                <div class="rounded alert-success p-4 mt-23">
                  <div class="text-black text-center">
                    <h5>3. Пользуйтесь Whatsapp API</h5>
                  </div>
                  <div class="text-gray mt-2">
                    Ознакомиться с подробной документацией и протестировать
                    запросы нашего Whatsapp API можно в разделе
                    <a href="/api-documentation"><u>Документация</u></a>.
                  </div>
                </div>
              </div>
              <div class="info-block mt-4 d-flex flex-wrap align-items-center">
                <input type="checkbox" v-model="isNeverShowHello" id="never" class="form-check-input" />
                <label for="never" class="m-0">
                  <span class="c-title">Больше не показывать</span>
                </label>
              </div>
              <div class="text-center mt-2">
                <button type="button" class="btn btn-billing" data-dismiss="modal" aria-label="Close"
                  @click="neverShowHello">
                  ОК
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Modal Hellomodal -->
        <!-- QR Modal -->
        <div class="modal fade" id="qr-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content p-2 p-lg-33">
              <img class="img-fluid w-100" :src="base64_qr" alt="qr code" />
            </div>
          </div>
        </div>
        <!-- end QR Modal -->
        <!-- Modal Payments -->
        <div class="modal fade" id="pay-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content p-lg-33" style="padding: 24px;">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="line-height: 100%;">
                  Оплата <span style="color: #23BB86">{{ this.profileName }}</span>
                </h5>
                <button type="button" class="swal2-close" aria-label="Close this dialog" style="display: flex;margin-top:8px;margin-right:8px;" @click="showPayModal">×</button>
                <!-- <button type="button" class="close" @click="showPayModal">
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                      fill="#6C757D" />
                  </svg>
                </button> -->
              </div>
              <div class="">
                <div class="billing-alert-text billing-alert mt-23">
                  Действует система скидок - чем больший период оплачиваете, тем
                  больше экономите!
                </div>
                <!-- <div class="content-text mt-23 text-center">
                  Выберите любой удобный вам способ оплаты
                </div> -->
                <div class="billing-content-table mt-23">
                  <!--                  <div class="d-flex modal-title-border">-->
                  <!--                    <div-->
                  <!--                      :class="{-->
                  <!--                        billing_black: !is_subscribe,-->
                  <!--                      }"-->
                  <!--                      class="content-text w-50 pointer pt-2 text-center"-->
                  <!--                      @click="is_subscribe = false"-->
                  <!--                    >-->
                  <!--                      Оплатить 1 раз-->
                  <!--                    </div>-->
                  <!--                    <div-->
                  <!--                      :class="{-->
                  <!--                        billing_black: is_subscribe,-->
                  <!--                      }"-->
                  <!--                      class="content-text pt-2 pointer text-center w-50"-->
                  <!--                      @click="is_subscribe = true"-->
                  <!--                    >-->
                  <!--                      Оформить подписку-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="billing-price">
                    <p class="tariff_desc" v-if="is_subscribe === false">
                      Единоразовый платеж за выбранный период
                    </p>
                    <p class="tariff_desc" v-if="is_subscribe === true">
                      Оформление автоплатежей на выбранный период. Мы будем
                      списывать деньги автоматически для продления выбранного
                      тарифа. Вы сможете отменить подписку в любой момент
                    </p>
                    <div :class="{ 'item-selected': item.checked }"
                      class="d-flex align-items-center pl-23 item-select p-2 p-lg-3 tariff_text"
                      @click="select_price(item)" v-for="item in tariffs" :key="item.id">
                      <input type="radio" name="radios" :checked="item.checked"
                        class="modal-price-radio" @click="select_price(item)" id="1m" />
                      <span class="content-text" v-if="item.id == 5">
                        Активировать промо период</span>
                      <span class="content-text" v-if="item.id != 5">{{ item.month | month }} — <b>{{ item.price
                      }}₽</b><span class="c-content discount_text" v-if="item.discount > 0">
                          Скидка {{ item.discount }}₽</span></span>
                    </div>
                    <div class="content-text text-center mt-2 mt-lg-23 mb-2 mb-lg-0">
                      Платеж через сервис Robokassa - принимаются банковские карты РФ, зарубежные Visa и Mastercard
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mx-auto">
                <p class="text-small text-gray mt-3" v-if="!this.is_subscribe">
                  Для дополнительных скидок
                  <span @click="promoCode" class="text-small c-content pointer">введите промокод</span>
                </p>
                <transition name="fade">
                  <input type="text" class="form-control mx-auto c-content mt-2" placeholder="Промокод" v-if="isPromo"
                    v-model="promocodeValue" v-on:keyup.enter="promoCode" />
                </transition>
                <button v-if="!isPromo && !sendingPayment" type="button" class="btn-billing text-uppercase mt-3" @click="send_payment_request()">
                  Оплатить {{ price_value }}<span v-if="price_value">₽</span>
                </button>
                <button v-if="!isPromo && sendingPayment" type="button" disabled class="btn-billing text-uppercase mt-3"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></button>
                <button v-if="isPromo" type="button" class="btn-billing mt-3" @click="promoCode()">
                  Применить промокод
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal billing end-->
        <!-- Modal Billing History -->
        <div class="modal fade" id="billinghistory-modal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-head d-flex align-items-start justify-content-between">
                <h5 class="modal-title" id="billing-history-modal-label">
                  История оплаты <span style="color: #23BB86">{{ this.profileName }}</span>
                </h5>
                <button type="button" class="close" @click="showPayHistoryModal">
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                      fill="#6C757D" />
                  </svg>
                </button>
              </div>
              <div class="">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tbody>
                      <tr class="text-center text-uppercase">
                        <th scope="col">Дата</th>
                        <th scope="col">Сумма</th>
                        <th scope="col">Тип оплаты</th>
                        <th scope="col">Тариф</th>
                        <th scope="col">Статус оплаты</th>
                      </tr>
                      <tr v-for="(payment, index) in payments" :key="index" class="text-center">
                        <td>{{ payment.payment_at }}</td>
                        <td>{{ payment.tariff.price }}</td>
                        <td v-if="payment.is_auto_payment == true">
                          Автоплатеж
                        </td>
                        <td v-if="payment.is_auto_payment == false">
                          Разовый платеж
                        </td>
                        <td v-if="payment.tariff.price == 0">Промопериод</td>
                        <td v-if="payment.tariff.price > 0">
                          {{ payment.tariff.month }} месяца -
                          {{ payment.tariff.price }} руб. (скидка
                          {{ payment.tariff.discount }} руб.)
                        </td>
                        <td v-if="payment.status">Оплачено</td>
                        <td v-if="!payment.status">Не оплачено</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal Billing Histry end -->
        <!-- billing block end-->

        <!-- Modal Account Preferences -->
        <div class="modal fade" id="billing-history-modal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-head d-flex align-items-center justify-content-between">
                <h5 class="modal-title">Настройки аккаунта</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                      fill="#6C757D" />
                  </svg>
                </button>
              </div>
              <div class=""></div>
            </div>
          </div>
        </div>
        <!-- Modal Account Preferences end -->

        <!-- Modal Auth History -->
        <div class="modal fade" id="auth_history_modal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-head d-flex align-items-center justify-content-between">
                <h5 class="modal-title">История авторизаций <span style="color: #23BB86">{{ this.profileName }}</span></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showAuthHistory">
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                      fill="#6C757D" />
                  </svg>
                </button>
              </div>
              <div class="">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tbody>
                      <tr class="text-center text-uppercase">
                        <th scope="col">Дата</th>
                        <th scope="col">Телефон</th>
                        <th scope="col">Авторизация</th>
                        <th scope="col">Причина</th>
                      </tr>
                      <tr v-for="(auth, index) in authorizations" :key="index" class="text-center" :class="{ 'table-success': auth.type === 'login', 'table-danger': auth.type !== 'login' }">
                        <td>{{ auth.created_at | wtz }}</td>
                        <td>{{ auth.phone }}</td>
                        <td v-if="auth.type === 'login'">Login</td>
                        <td v-if="auth.type === 'logout'">Logout</td>
                        <td>{{ auth.reason }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal Account Preferences end -->
      </div>
    </div>
    <Footer v-if="!this.$store.state.user.moysklad"></Footer>
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import QRComponent from './QRComponent.vue';
import CRMBlock from './CRMBlock.vue';
import CODEComponent from './CODEComponent.vue';
import WebhookSettings from './WebhookSettings.vue';
import Footer from './Footer.vue';
export default {
  props: {
    Profile: Array,
    base64_qr: String,
    is_task_exist: Boolean,
    today_tasks_count: Number,
    authorizations: Array,
    user: Object
  },
  components: {
    Footer, CRMBlock, WebhookSettings
  },
  data() {
    return {
      sendingPayment: false,
      isNeverShowHello: false,
      paynotify: false,
      authnotify: false,
      isScaleQR: false,
      tariff_id: "",
      resolve_conflict: false,
      polling: false,
      tariffs: {},
      fullWidthImage: false,
      task_response_counter: 0,
      task_status: "",
      checked: false,
      edited: false,
      editedWHA: false,
      edited_proxy: false,
      checked_item: null,
      profileName: "",
      msg_limit: 0,
      msg_timeout: '',
      user_proxy: '',
      price_value: "700",
      payments: {},
      is_subscribe: false,
      last_payment: 0,
      days_left: 0,
      show_not_paid: false,
      hours_left: 0,
      maxLength: 100,
      isPromo: false,
      promocodeValue: null,
      days_left_sklad: "", 
      expired_at_sklad: "",
    };
  },
  methods: {
    crmConnect() {
      if (confirm("Реально уверен?")) {
        this.$axios.post(
          "https://" +
          window.location.host +
          "/amocrm/connect?profile_id=" +
          this.$store.state.current_profile.uuid,
          {},
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(response => {
          this.$store.state.current_profile.crm_type = "amo";
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "AMOCRM подключена",
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
        })
        .catch((error) => {
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: error.response.data.detail,
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    },
    crmDisconnect() {
      if (confirm("Реально уверен?")) {
        this.$axios.post(
          "https://" +
          window.location.host +
          "/amocrm/disconnect?profile_id=" +
          this.$store.state.current_profile.uuid,
          {},
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(response => {
          this.$store.state.current_profile.crm_type = null;
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "AMOCRM отключена",
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
        })
        .catch((error) => {
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: error.response.data.detail,
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    },
    profile_logout() {
      if (confirm("Действительно хотите выйти?")) {
        this.$axios.get(
          "https://" +
          window.location.host +
          "/api/profile/logout?profile_id=" +
          this.$store.state.current_profile.uuid,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        );
      }
    },
    profile_restart() {
      if (confirm("Действительно хотите перезагрузить приложение?")) {
        // let restore_on_open = document.querySelectorAll(".restore_on_open");
        // restore_on_open.forEach((res) => {
        //   res.classList.add("disabled");
        // });
        this.$axios.get(
          "https://" +
          window.location.host +
          "/api/profile/restart?profile_id=" +
          this.$store.state.current_profile.uuid,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        );
      }
    },
    purgeQueue() {
      if (confirm("Действительно хотите очистить очередь?")) {
        this.$axios
          .get(
            "https://" +
            window.location.host +
            "/api/profile/queue/purge?profile_id=" +
            this.$store.state.current_profile.uuid,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then((data) => {
            if (data.data.status === 'done') {
              this.$store.commit("set_pending");
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Очередь очищена",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
      }
    },
    promoCode() {
      if (!this.isPromo) {
        this.isPromo = true;
      } else {
        if (this.promocodeValue != "") {
          this.$axios
            .post(
              window.location.href + "/promo",
              {
                code: this.promocodeValue,
              },
              {
                headers: {
                  Authorization: this.$store.state.token,
                },
              }
            )
            .then((res) => {
              if (res.data.valid) {
                this.tariffs = res.data.tariffs;
                this.price_value = this.tariffs[0].price;
              } else {
                this.$swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: "error",
                  title: "Неверно указан промокод",
                  timerProgressBar: true,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            });
          this.isPromo = false;
        } else {
          this.isPromo = false;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Не указан промокод",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    },
    showPayModal() {
      $("#pay-modal").modal("toggle");
    },
    showPayHistoryModal() {
      $("#billinghistory-modal").modal("toggle");
    },
    showAuthHistory() {
      $("#auth_history_modal").modal("toggle");
    },
    scaleQr() {
      $("#qr-modal").modal("show");
    },
    initFunc() {
      this.is_subscribe = false; // (this.price = 700), (this.tariff_id = 1), (this.is_subscribe = false);
      this.last_payment = this.payments[0];
      this.helloModal();
    },
    neverShowHello() {
      $("#hello-modal").modal("hide");
      if (this.isNeverShowHello == true) {
        this.$inertia.post(
          "https://" + window.location.host + '/dashboard/'+this.$store.state.current_profile.uuid + "/show-hello",
          "hello"
        );
      }
    },
    helloModal() {
      // console.log(this.$store.state.user);
      if (this.$store.state.user.hello && !this.$store.state.user.moysklad) {
        $("#hello-modal").modal("show");
      }
    },
    cancel_subscribe() {
      this.$swal
        .fire({
          title: "Вы уверены?",
          text: "Ваша подписка будет аннулирована!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Нет",
          confirmButtonColor: "#d33",
          cancelButtonColor: "#23BB86",
          confirmButtonText: "Да, хочу отменить подписку!",
        })
        .then((res) => {
          if (res.isConfirmed) {
            this.$axios.post(
              "/profile/subscribe/cancel",
              {
                uuid: this.$store.state.current_profile.uuid,
              },
              {
                headers: {
                  Authorization: this.$store.state.token,
                },
              }
            );
            this.$store.state.current_profile.is_subscribe = false;
          }
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Ваша подписка отменена",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    get_status_anime() {
      this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Отправлен запрос статуса",
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 3000,
      });
      this.get_qr();
    },
    send_payment_request() {
      this.sendingPayment = true;
      this.$axios
        .post(
          "/payments/create",
          {
            profile_uuid: this.$store.state.current_profile.uuid,
            amount: this.price_value,
            tariff_id: this.tariff_id,
            is_subscription: this.is_subscribe,
            code: this.promocodeValue,
          },
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then((res) => {
          window.location.href = res.data;
        });
    },
    paynotify_change() {
      this.paynotify = !this.paynotify;
      if (this.paynotify == true) {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Напоминание об оплате включено",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Напоминание об оплате выключено",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.$inertia.post(
        this.$store.state.current_profile.uuid + "/paynotify/switch",
        { payments_notify: this.paynotify },
        { preserveScroll: true }
      );
    },
    authnotify_change() {
      this.authnotify = !this.authnotify;
      if (this.authnotify == true) {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Напоминание разавторизации включено",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Напоминание разавторизации выключено",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.$inertia.post(
        this.$store.state.current_profile.uuid + "/authnotify/switch",
        { auth_notify: this.authnotify },
        { preserveScroll: true }
      );
    },
    resolve_conflict_change() {
      this.resolve_conflict = !this.resolve_conflict;
      if (this.resolve_conflict == true) {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Функция 'Использовать здесь' включена ",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Функция 'Использовать здесь' выключена",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.$inertia.post(
        this.$store.state.current_profile.uuid + "/usehere/switch",
        { use_here: this.resolve_conflict },
        { preserveScroll: true }
      );
    },
    async copyUuid() {
      this.copyToClipboard(this.$store.state.current_profile.uuid).then(() => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "UUID был скопирован",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      });
    },
    async copyToClipboard(textToCopy) {
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    async copyToken() {
      this.copyToClipboard(this.$store.state.token).then(() => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Токен был скопирован",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      });
    },
    moyskladDisconnect() {
      this.$axios.post(
        "https://" +
          window.location.host +
        "/mysklad/getform/deleteProfile?profile_id=" + this.$store.state.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad, 
        {},
        {
          headers: {
            Authorization: this.$store.state.token,
            Token: this.$store.state.user.moysklad_token,
          },
        }
      )
      .then(response => {
        console.log("MOYSKLAD DISCONNECT");
        console.log(response);
      })
      .catch((error) => {
        console.log("MOYSKLAD DISKONNECT ERROR");
        console.log(error);
      });
    },
    retailDisconnect() {
      this.$axios.post(
        "https://" + window.location.host + "/retailcrm/disconnect?profile_id=" + this.$store.state.current_profile.uuid,
        {},
        {
          headers: {
            Authorization: this.$store.state.token,
          },
        }
      )
      .then(response => {
        console.log("RETAIL DISCONNECT");
        console.log(response);
      })
      .catch((error) => {
        console.log("RETAIL DISKONNECT ERROR");
        console.log(error);
      });
    },
    deleteInstance() {
      this.$swal
        .fire({
          title: "Вы уверены?",
          text: "При удалении профиля все данные, оплаты и чат-боты, связанные с этим профилем, будут безвозвратно удалены!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Не удалять",
          confirmButtonColor: "#d33",
          cancelButtonColor: "#23BB86",
          confirmButtonText: "Удалить",
          customClass: {
            container: this.$store.state.user.moysklad ? 'custom-swal-container' : '',
            popup: this.$store.state.user.moysklad ? 'custom-swal' : '' // Применение кастомного класса
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            let user_token = document.querySelector("#user_token").innerHTML;
            if (this.$store.state.user.moysklad) {
              this.moyskladDisconnect();
            }
            if (this.$store.state.current_profile.crm_type === "retail") {
              this.retailDisconnect();
            }
            this.$axios.get("/dashboard/instance/delete", {
              headers: {
                Authorization: user_token,
              },
              params: {
                profile_id: this.$store.state.current_profile.uuid
              },
            }).then((res) => {
              this.$store.commit("set_all_wa_profiles", res.data.all_wa_profiles);
              this.$store.commit("set_all_tg_profiles", res.data.all_tg_profiles);
              this.$store.commit("set_all_wa_containers", res.data.all_wa_containers);
              this.$store.commit("set_all_tg_containers", res.data.all_tg_containers);
              this.$inertia.visit(this.route("dashboard"));
              this.$swal.fire({
                confirmButtonColor: "#23BB86",
                title: "Удалено!",
                text: "Ваш инстанс удален.",
                icon: "success",
              });
            });
          }
        });
    },
    select_price(item) {
      this.price_value = item.price;
      this.tariff_id = item.id;
      // this.checked_item = this.price_list.filter( x => x.id === item.id);
      this.tariffs.forEach((item) => (item.checked = false));
      item.checked = true;
    },
    get_qr_animate() {
      this.$emit("qr_updating", "");
      this.get_qr();
    },
    async authorize() {
      const { isConfirmed: confirmed, value: result } = await this.$swal.fire({
        html: `
          <div style="display:flex;justify-content:center;align-items:center;">
            <div class="modalSquare">
              <input type="radio" id="select_auth_qr_input" class="rtest" name="auth_type" value="qr" checked>
              <label class="labelCreateSelect" id="select_auth_qr" for="select_auth_qr_input" style="color:#23BB86;">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                  <g clip-path="url(#clip0_5139_870)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0527344 0.0263672H13.1279V13.0488H0.0527344V0.0263672ZM32.666 32.6924H36V35.9736H32.666V32.6924ZM26.2588 32.6865H29.5928V35.8125H26.2588H26.2559H22.9248V29.4199H26.1533V26.1797H29.4375V19.7197H32.7715V22.8955H35.9473V26.1768H32.7715V29.458H29.4873H29.4375H26.2588V32.6865ZM16.3594 26.1269H19.5879V22.8457H16.4648V19.5645H19.5879V16.2832H16.4121V19.5645H13.0752V16.2832H16.3564V6.53906H19.6904V16.2803H22.9189V19.5615H26.0947V16.2803H29.4287V19.5615H26.2588V22.8428H22.9248V29.3027H19.6934V35.8154H16.3594V26.1269ZM32.6103 16.2803H35.9443V19.5615H32.6103V16.2803ZM6.56543 16.2803H9.89941V19.5615H6.56543V16.2803ZM0.0527344 16.2803H3.38672V19.5615H0.0527344V16.2803ZM16.3594 0.0263672H19.6934V3.30762H16.3594V0.0263672ZM0 22.8955H13.0752V35.918H0V22.8955ZM3.17578 26.0596H9.89941V32.7568H3.17578V26.0596ZM22.8691 0.0263672H35.9443V13.0488H22.8691V0.0263672ZM26.0478 3.19043H32.7715V9.88769H26.0478V3.19043ZM3.22852 3.19043H9.95215V9.88769H3.22852V3.19043Z" fill="#23BB86"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_5139_870">
                      <rect width="36" height="36" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <div style="margin-top: 12px;">Сканирование<br>QR-кода</div>
              </label>
            </div>
            <div class="modalSquare">
              <input type="radio" id="select_auth_code_input" name="auth_type" value="code" class="rtest">
              <label class="labelCreateSelect" id="select_auth_code" for="select_auth_code_input" style="color:#23BB86;">
                <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                  <g clip-path="url(#clip0_5139_883)">
                    <path d="M31.6875 14.625H28.4375V10.5625C28.4375 8.19213 27.4959 5.91884 25.8198 4.24273C24.1437 2.56663 21.8704 1.625 19.5 1.625H17.875C15.5046 1.625 13.2313 2.56663 11.5552 4.24273C9.87913 5.91884 8.9375 8.19213 8.9375 10.5625V14.625H5.6875C5.25652 14.625 4.8432 14.7962 4.53845 15.101C4.2337 15.4057 4.0625 15.819 4.0625 16.25V35.75C4.0625 36.181 4.2337 36.5943 4.53845 36.899C4.8432 37.2038 5.25652 37.375 5.6875 37.375H31.6875C32.1185 37.375 32.5318 37.2038 32.8365 36.899C33.1413 36.5943 33.3125 36.181 33.3125 35.75V16.25C33.3125 15.819 33.1413 15.4057 32.8365 15.101C32.5318 14.7962 32.1185 14.625 31.6875 14.625ZM12.1875 10.5625C12.1875 9.05408 12.7867 7.60744 13.8533 6.54083C14.9199 5.47422 16.3666 4.875 17.875 4.875H19.5C21.0084 4.875 22.4551 5.47422 23.5217 6.54083C24.5883 7.60744 25.1875 9.05408 25.1875 10.5625V14.625H12.1875V10.5625ZM30.0625 34.125H7.3125V17.875H30.0625V34.125Z" fill="#23BB86"/>
                    <path d="M12.1875 28.4375C13.5337 28.4375 14.625 27.3462 14.625 26C14.625 24.6538 13.5337 23.5625 12.1875 23.5625C10.8413 23.5625 9.75 24.6538 9.75 26C9.75 27.3462 10.8413 28.4375 12.1875 28.4375Z" fill="#23BB86"/>
                    <path d="M18.6875 28.4375C20.0337 28.4375 21.125 27.3462 21.125 26C21.125 24.6538 20.0337 23.5625 18.6875 23.5625C17.3413 23.5625 16.25 24.6538 16.25 26C16.25 27.3462 17.3413 28.4375 18.6875 28.4375Z" fill="#23BB86"/>
                    <path d="M25.1875 28.4375C26.5337 28.4375 27.625 27.3462 27.625 26C27.625 24.6538 26.5337 23.5625 25.1875 23.5625C23.8413 23.5625 22.75 24.6538 22.75 26C22.75 27.3462 23.8413 28.4375 25.1875 28.4375Z" fill="#23BB86"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_5139_883">
                      <rect width="39" height="39" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <div style="margin-top: 12px;">По номеру<br>телефона</div>
              </label>
            </div>
          </div>
        `,
        title: "Авторизация Whatsapp",
        showCloseButton: true,
        focusConfirm: false,
        focusCancel: false,
        focusDeny: false,
        confirmButtonText: 'Авторизовать',
        confirmButtonColor: "#23BB86",
        preConfirm: () => {
          return {
            qr: document.getElementById('select_auth_qr_input').checked,
            code: document.getElementById('select_auth_code_input').checked
          }
        }
      });

      if (confirmed) {

        // console.log(result);
        
        if (result.qr) {
          this.$emit("toggle_task_exist", true);
          this.get_qr_new();
          this.$axios.get(
            "https://" +
            window.location.host +
            "/api/sync/qr/get?image_format=jpg&profile_id=" +
            this.$store.state.current_profile.uuid,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.$emit("qr_updating", response.data.qrCode);
            // this.$emit("qr_updating", response.data.qrCode);
          })
          .catch((error) => {
            // this.$emit("toggle_task_exist", false);
            if (error.response) {
              if (error.response.status === 402) {
                this.$swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: "error",
                  title: "Сервис не оплачен",
                  timerProgressBar: true,
                  showConfirmButton: false,
                  width: 400,
                  timer: 4000,
                });
              }
            } else if (error.request) {
              // The request was made but no response was received
              // console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              // console.log('Error', error.message);
            }
          })
          
        } 
        if (result.code) {
          this.auth_phone();
        }
      }
        // const req = {
        //   instance_name: result.textInput,
        //   user_id: this.$store.state.user.id,
        //   platform: result.option
        // };
        // let user_token = document.querySelector("#user_token").innerHTML;
        // this.$axios.get("/dashboard/instance/create", {
        //   headers: {
        //     Authorization: user_token,
        //   },
        //   params: {
        //     param: JSON.stringify(req)
        //   },
        // }).then((res) => {
        //   if(res.data.status == "done") {
        //     this.$store.commit("set_all_wa_profiles", res.data.all_wa_profiles);
        //     this.$store.commit("set_all_tg_profiles", res.data.all_tg_profiles);
        //     this.$store.commit("set_all_wa_containers", res.data.all_wa_containers);
        //     this.$store.commit("set_all_tg_containers", res.data.all_tg_containers);
          
        //     this.$inertia.visit('/dashboard/' + res.data.profile_id);
        //   }
          
        // });
     
    },
    async get_qr_new() {
      await this.$swal.fire({
        title: "Сканирование QR-кода",
        showCloseButton: true,
        focusConfirm: false,
        focusCancel: false,
        focusDeny: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        html: '<div id="swal-qr-component"></div>',
        didOpen: () => {
          new Vue({
            render: h => h(QRComponent, {
              props: {
                base64_qr: this.base64_qr 
              }
            })
          }).$mount('#swal-qr-component');
        }
      });
    },
    async auth_phone() {
      const { isConfirmed: confirmed, value: result } = await this.$swal.fire({
        html: `
          <div style="text-align: left;">Введите <span class="c-content">номер телефона</span>, на который зарегистрирован ваш аккаунт Whatsapp, и нажмите ОК</div>
          <input autocomplete="off" class="form-control" style="margin-top: 24px;" type="text" id="textInput" placeholder="Номер телефона">
        `,
        title: `<div style="text-align: left;">Введите номер телефона</div>`,
        showCloseButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: "#23BB86",
        preConfirm: () => {
          let textInputValue = document.getElementById('textInput').value;
          
          if (!textInputValue) {
            this.$swal.showValidationMessage('Пожалуйста, введите номер телефона');
            return;
          }

          textInputValue = textInputValue.replace(/\D/g, '');

          if (textInputValue.length < 6) {
            this.$swal.showValidationMessage('Номер телефона слишком короткий');
            return;
          }

          return {
            textInput: textInputValue
          };
        }
      });

      if (confirmed) {
        this.$axios.get(
          "https://" +
          window.location.host +
          "/api/sync/auth/code?phone=" + result.textInput + "&profile_id=" +
          this.$store.state.current_profile.uuid,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        ).then(response => {
          // console.log(response);
          if (response.status == 200) {
            this.auth_code(response.data.code, response.data.time_left, result.textInput);
          }
        }).catch((error) => {
          this.$emit("toggle_task_exist", false);
          if (error.response) {
            if (error.response.status === 402) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Сервис не оплачен",
                timerProgressBar: true,
                showConfirmButton: false,
                width: 400,
                timer: 4000,
              });
            }
          } else if (error.request) {
            // The request was made but no response was received
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
          }
        });
      }
    },
    async auth_code(code, time_left, phone) {
      const { isConfirmed: confirmed, value: result } = await this.$swal.fire({
        html: '<div id="swal-code-component"></div>',
        didOpen: () => {
          new Vue({
            render: h => h(CODEComponent, {
              props: {
                code: code,
                time_left: time_left,
                phone: phone,
              }
            })
          }).$mount('#swal-code-component');
        },
        title: `<div style="text-align: left;">Введите код на телефоне</div>`,
        showCloseButton: true,
        focusConfirm: false,
        focusCancel: false,
        focusDeny: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        willClose: async () => {
          const response = await this.$axios.get(
            "https://" +
            window.location.host +
            "/api/profile/restart?profile_id=" +
            this.$store.state.current_profile.uuid,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          );
        },
      });
      setTimeout(() => {
        Swal.close();
      }, 180000);
    },
    get_qr() {
      this.$emit("toggle_task_exist", true);
      this.$axios
        .get(
          "https://" +
          window.location.host +
          "/api/sync/qr/get?image_format=jpg&profile_id=" +
          this.$store.state.current_profile.uuid,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$emit("qr_updating", response.data.qrCode);
        })
        .catch((error) => {
          this.$emit("toggle_task_exist", false);
          if (error.response) {
            if (error.response.status === 402) {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Сервис не оплачен",
                timerProgressBar: true,
                showConfirmButton: false,
                width: 400,
                timer: 4000,
              });
            }
          } else if (error.request) {
            // The request was made but no response was received
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
          }
      });
    },
    get_last_task() {
      this.$axios
        .get(
          "https://" +
          window.location.host +
          "/task/last/get?profile_uuid=" + this.$store.state.current_profile.uuid,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log("get_last_task");
          console.log(response.data);
          this.$store.state.current_profile.last_activity = response.data.command_start
        }).catch((error) => {
          console.log(error);
        });
    },
    editName() {
      this.edited = true;
    },
    editMsgLimit() {
      this.edited_limit = true;
    },
    editUserProxy() {
      this.edited_proxy = true;
    },
    editMsgTimeout() {
      this.edited_timeout = true;
    },
    saveName() {
      this.edited = false;
      this.$store.commit("set_name", {
        name: this.profileName,
        id: this.$store.state.current_profile.id,
        platform: "wz",
      });
      this.$inertia.post(
        this.$store.state.current_profile.uuid + "/name/change",
        { uuid: this.$store.state.current_profile.uuid, name: this.profileName }
      );
      if (this.profileName != this.$store.state.current_profile.name) {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Имя было изменено",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    saveUserProxy() {
      this.edited_proxy = false;

      this.$axios.post(
          "https://" +
          window.location.host +
          "/api/proxy/set?proxy=" + this.user_proxy + "&profile_id=" +
          this.$store.state.current_profile.uuid,
          {},
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        ).then(response => {
          if (response.data.status === 'done') {
            this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Прокси был изменен",
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }).catch(error => {
          this.user_proxy = "";
          this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: error.response.data.detail,
              timerProgressBar: true,
              showConfirmButton: false,
              width: 400,
              timer: 2000,
            });
        });

    },
    days_left_func() {
      if (this.payments === null) {
        return false;
      }
      if (this.payments.length > 0) {
        this.show_not_paid = true;
        var date1 = new Date(this.payments[0].expired_at);
        date1.setDate(date1.getDate() + 1);
        // console.log(date1);
        var t2 = Date.now();
        var t1 = date1.getTime();
        // console.log(t1);
        this.days_left = Math.floor((t1 - t2) / (24 * 3600 * 1000));
        if (this.days_left == 1) {
          this.hours_left = Math.floor((((t1 - t2) / 1000 / 60 / 60) % 24) - 3);
        }
        if (this.days_left < 1) {
          this.show_not_paid = false;
          this.days_left = 0;
        }
      } else {
        this.show_not_paid = false;
        this.days_left = 0;
      }
    },
  },
  filters: {
    moment: function (date) {
      return moment.unix(date).format("LLL");
    },
    moment2: function (date) {
      if (!date) {
        return 'не зафиксирована';
      }
      return moment(date).format("LLL");
    },
    short: function (date) {
      return moment(date).format("L");
    },
    wtz: function (date) {
      return moment(date, "DD.MM.YYYY HH:mm:ss").add(3, 'hours').format("DD.MM.YYYY HH:mm:ss");
    },
    month: function (date) {
      if (date === 1) {
        return date + " месяц";
      } else if (date === 3) {
        return date + " месяца";
      } else if (date === 6) {
        return date + " месяцев";
      } else if (date === 12) {
        return date + " месяцев";
      }
    },
  },
  watch: {
    countAllProfiles(newCount, oldCount) {
      this.helloModal();
    },
  },
  computed: {
    countAllProfiles () {
      return this.$store.state.all_wa_profiles.length
    }
  },
  created() {
    this.get_last_task(); // days_left_sklad expired_at_sklad
    this.$axios.get(
            "https://" +
            window.location.host +
            "/mysklad/getSub?profile_id=" + this.$store.state.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad,
            {
              headers: {
                Authorization: this.$store.state.token,
                Token: this.$store.state.user.moysklad_token,
              },
            }
          )
          .then(response => {
            console.log(response.data);
            this.days_left_sklad = response.data.days;
            this.expired_at_sklad = response.data.till;
          })
          .catch((error) => {
            // console.log(error);
            this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "warning",
                title: "Ошибка получения данных",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
            });
  },
  mounted() {
    // console.log(this.$store.state.current_profile);
    
    this.profileName = this.$store.state.current_profile.name;
    if (this.$store.state.current_profile.spam_level !== null) {
      this.msg_limit = this.$store.state.current_profile.spam_level.msg_limit;
      this.msg_timeout = this.$store.state.current_profile.spam_level.msg_timeout + ':' + this.$store.state.current_profile.spam_level.msg_timeout_to;
    }
    this.resolve_conflict = this.$store.state.current_profile.resolve_conflict;
    this.paynotify = this.$store.state.current_profile.payment_notification;
    this.authnotify = this.$store.state.current_profile.auth_notification;
    
    if (this.$store.state.current_profile.self_proxy === null) {
      this.user_proxy = '';
    } else {
      this.user_proxy = this.$store.state.current_profile.self_proxy;
    }
    this.payments = this.$store.state.payments;
    this.tariffs = this.$store.state.tariffs;
    this.tariffs[0].checked = true;
    this.select_price(this.tariffs[0]);
    this.tariffs.forEach(item => {
      if (item.id === 5 && !this.$store.state.user.use_test) {
        this.select_price(item);
      }
    });
   
    this.initFunc();
    this.days_left_func();
  },
};
</script>