var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Sidebar", {
        attrs: { all_profiles: "all_profiles" },
        on: { side_toggle: _vm.sidebar_toggle },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main-content" },
        [
          _c("Topbar", { on: { side_toggle: _vm.sidebar_toggle } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iq-navbar-header" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "iq-header-img" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "container-fluid content-inner py-0 my-mt-5",
                  style: this.$store.state.user.moysklad
                    ? "min-height:initial !important"
                    : "",
                },
                [_vm._m(1)]
              ),
              _vm._v(" "),
              !this.$store.state.user.moysklad ? _c("Footer") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: {
                id: "accountpref-modal",
                tabindex: "-1",
                role: "dialog",
                "aria-labelledby": "exampleModalLabel",
                "aria-hidden": "true",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog modal-lg",
                  attrs: { role: "document" },
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-head d-flex align-items-center justify-content-between",
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "modal-title",
                            attrs: { id: "accountpref-modal-label" },
                          },
                          [
                            _vm._v(
                              "\n              Настройки аккаунта\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: {
                              type: "button",
                              "data-dismiss": "modal",
                              "aria-label": "Close",
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "16",
                                  height: "15",
                                  viewBox: "0 0 16 15",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z",
                                    fill: "#6C757D",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-text mt-6 text-center p-33" },
                      [
                        _vm._v(
                          "\n            Здесь будут настройки какие нибудь\n          "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-fluid iq-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-wrap",
            },
            [
              _c("div", [
                _c("h1", { staticClass: "c-content" }, [_vm._v("Поддержка")]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row g-0 g-sm-2 g-md-4" }, [
      _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "contacts__info" }, [
                _c("h4", [_vm._v("Контакты")]),
                _vm._v(" "),
                _c("div", { staticClass: "contacts__text mt-4" }, [
                  _vm._v(
                    "\n                          Вы можете связаться с нами любым удобным вам способом.\n                      "
                  ),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "contacts__list mt-4" }, [
                  _c("li", { staticClass: "contacts__list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "contacts__list-link d-flex align-items-center",
                        attrs: { href: "mailto:support@wappi.pro" },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "30px" },
                          attrs: {
                            src: "/static/index/img/icons/mail-icon.svg",
                            "aria-hidden": "true",
                            alt: "email",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "support__contact" }, [
                          _c("span", { staticClass: "contact_name" }, [
                            _vm._v("E-mail:"),
                          ]),
                          _vm._v(
                            " support@wappi.pro\n                                  "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "contacts__list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "contacts__list-link d-flex align-items-center",
                        attrs: {
                          href: "https://t.me/wappi_support",
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "30px" },
                          attrs: {
                            src: "/static/index/img/icons/tg-icon.svg",
                            "aria-hidden": "true",
                            alt: "telegram",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "support__contact" }, [
                          _c("span", { staticClass: "contact_name" }, [
                            _vm._v("Telegram:"),
                          ]),
                          _vm._v(
                            " @wappi_support\n                                  "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }