var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Sidebar", {
        attrs: { all_profiles: "all_profiles" },
        on: { side_toggle: _vm.sidebar_toggle },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main-content" },
        [
          _c("Topbar", { on: { side_toggle: _vm.sidebar_toggle } }),
          _vm._v(" "),
          _c("div", { staticClass: "iq-navbar-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "iq-header-img" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container-fluid content-inner py-0 my-mt-5" },
              [
                _c("div", { staticClass: "row g-0 g-md-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row mt-4" }, [
                          _c("div", { staticClass: "col-6 col-lg-2" }, [
                            _c("div", { staticClass: "c-title text-center" }, [
                              _vm._v("ВСЕГО ПРОФИЛЕЙ"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-title text-center big_number" },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      this.$store.state.all_wa_profiles.length +
                                        this.$store.state.all_tg_profiles
                                          .length +
                                        this.$store.state.all_avito_profiles
                                          .length
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          !this.user.spamer
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("АВТОРИЗОВАНО")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center c-content big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.authorized_count) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 col-lg-2" }, [
                            _c("div", { staticClass: "c-title text-center" }, [
                              _vm._v("СТАТУС OPEN"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "c-title text-center c-content big_number",
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.cont_open) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.user.spamer
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("ОТПРАВЛЕНО СЕГОДНЯ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center c-content big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.today_tasks_count) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.user.spamer
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("ОТПРАВЛЕНО ВСЕГО")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center c-content big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.all_tasks_count) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.user.spamer
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("СТОИМОСТЬ СООБЩЕНИЯ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center c-content big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.user_msg_price) +
                                        " ₽\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.user.spamer
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("ЗАРАБОТАНО ВСЕГО")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center c-content big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.all_earned) +
                                        " ₽\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.user.with_payments
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("ТАРИФ, ₽")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center c-content big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.tariff_rub.price) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.user.pay_system !== "days" &&
                          !_vm.user.spamer &&
                          _vm.user.id !== 12
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("ОПЛАЧЕНО")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center c-content big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.paid) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.user.pay_system !== "days" &&
                          !_vm.user.spamer &&
                          _vm.user.id !== 12
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("НЕ ОПЛАЧЕНО")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center text-danger big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.not_paid) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.user.with_payments &&
                          _vm.user.pay_system === "days" &&
                          !_vm.user.spamer &&
                          _vm.user.id !== 12
                            ? _c("div", { staticClass: "col-6 col-lg-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-title text-center" },
                                  [_vm._v("НЕ ОПЛАЧЕНО, ₽")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-title text-center text-danger big_number",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.all_sum) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.user.pay_system === "days"
                          ? _c(
                              "div",
                              {
                                staticClass: "modal fade",
                                attrs: {
                                  id: "billinghistory-modal",
                                  tabindex: "-1",
                                  role: "dialog",
                                  "aria-labelledby": "exampleModalLabel",
                                  "aria-hidden": "true",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal-dialog modal-xl",
                                    attrs: { role: "document" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "modal-content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "modal-head d-flex align-items-start justify-content-between",
                                          },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass: "modal-title",
                                                attrs: {
                                                  id: "billing-history-modal-label",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          История оплаты "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#23BB86",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(this.profileName)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "close",
                                                attrs: { type: "button" },
                                                on: {
                                                  click:
                                                    _vm.showPayHistoryModal,
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "16",
                                                      height: "15",
                                                      viewBox: "0 0 16 15",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        "clip-rule": "evenodd",
                                                        d: "M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z",
                                                        fill: "#6C757D",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {}, [
                                          _c(
                                            "div",
                                            { staticClass: "table-responsive" },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-striped",
                                                },
                                                [
                                                  _c(
                                                    "tbody",
                                                    [
                                                      _vm._m(1),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.payments,
                                                        function (
                                                          payment,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "text-center",
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    payment.payment_at
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    payment.amount
                                                                  ) + "₽"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-4" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-12 d-flex",
                              staticStyle: {
                                "justify-content": "space-between",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "form",
                                {
                                  attrs: { autocomplete: "off" },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.search_name,
                                        expression: "search_name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "sidebar_search",
                                      placeholder: "Поиск профиля",
                                      autocomplete: "off",
                                    },
                                    domProps: { value: _vm.search_name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.search_name = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.user.pay_system === "days"
                                ? _c("div", { staticClass: "me-3" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "my-green-a",
                                        on: { click: _vm.showPayHistoryModal },
                                      },
                                      [_vm._v("История оплат")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-responsive mt-3" }, [
                          _c(
                            "table",
                            { staticClass: "table table-hover table-bordered" },
                            [
                              _c(
                                "tbody",
                                [
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "thead text-center text-uppercase table-secondary c-title",
                                    },
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "pointer tw1",
                                          attrs: { scope: "col" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_profiles(
                                                "wa_status"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          Статус\n                          "
                                          ),
                                          _c("span", { staticClass: "mb-1" }, [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "15",
                                                  height: "15",
                                                  viewBox: "0 0 15 15",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "pointer",
                                          attrs: { scope: "col" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_profiles("name")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          Имя профиля\n                          "
                                          ),
                                          _c("span", [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "15",
                                                  height: "15",
                                                  viewBox: "0 0 15 15",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "pointer tw1",
                                          attrs: { scope: "col" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_profiles(
                                                "platform"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          Платформа\n                          "
                                          ),
                                          _c("span", [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "15",
                                                  height: "15",
                                                  viewBox: "0 0 15 15",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "tw2",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("id профиля")]
                                      ),
                                      _vm._v(" "),
                                      _vm.user.spamer
                                        ? _c(
                                            "th",
                                            {
                                              staticClass: "tw3 pointer",
                                              attrs: { scope: "col" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sort_profiles(
                                                    "ttc"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Отправлено сегодня\n                          "
                                              ),
                                              _c("span", [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "15",
                                                      height: "15",
                                                      viewBox: "0 0 15 15",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.user.spamer
                                        ? _c(
                                            "th",
                                            {
                                              staticClass: "tw3 pointer",
                                              attrs: { scope: "col" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sort_profiles(
                                                    "atc"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Отправлено всего\n                          "
                                              ),
                                              _c("span", [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "15",
                                                      height: "15",
                                                      viewBox: "0 0 15 15",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.user.pay_system !== "days" &&
                                      !_vm.user.spamer &&
                                      _vm.user.id !== 12
                                        ? _c(
                                            "th",
                                            {
                                              staticClass: "tw3 pointer",
                                              attrs: { scope: "col" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sort_profiles(
                                                    "is_payment"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Оплата\n                          "
                                              ),
                                              _c("span", [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "15",
                                                      height: "15",
                                                      viewBox: "0 0 15 15",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.user.pay_system !== "days" &&
                                      !_vm.user.spamer &&
                                      _vm.user.id !== 12
                                        ? _c(
                                            "th",
                                            {
                                              staticClass: "tw4 pointer",
                                              attrs: { scope: "col" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sort_profiles(
                                                    "left_days"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Остаток дней\n                          "
                                              ),
                                              _c("span", [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "15",
                                                      height: "15",
                                                      viewBox: "0 0 15 15",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.user.pay_system === "days" &&
                                      !_vm.user.spamer
                                        ? _c(
                                            "th",
                                            {
                                              staticClass: "tw4 pointer",
                                              attrs: { scope: "col" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sort_profiles(
                                                    "worked_days"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Дней в работе\n                          "
                                              ),
                                              _c("span", [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "15",
                                                      height: "15",
                                                      viewBox: "0 0 15 15",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                        fill: "currentColor",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "tw4 pointer",
                                          attrs: { scope: "col" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_profiles(
                                                "authorized"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          Авторизован\n                          "
                                          ),
                                          _c("span", [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "15",
                                                  height: "15",
                                                  viewBox: "0 0 15 15",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M4.5 0C4.35 0 4.25 0.05 4.15 0.15L0.15 4.15C0.05 4.25 0 4.35 0 4.5C0 4.65 0.05 4.75 0.15 4.85L0.85 5.55C0.95 5.65 1.1 5.7 1.2 5.7C1.35 5.7 1.45 5.65 1.55 5.55L2.55 4.55C2.7 4.4 3 4.5 3 4.75V11.5C3 11.75 3.25 12 3.5 12H4.5C4.75 12 5 11.75 5 11.5V0.5C5 0.35 4.95 0.25 4.85 0.15C4.75 0.05 4.65 0 4.5 0Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M11.85 7.15L11.15 6.45C11.05 6.35 10.9 6.3 10.8 6.3C10.65 6.3 10.55 6.35 10.45 6.45L9.45 7.45C9.3 7.6 9 7.5 9 7.25V0.5C9 0.25 8.75 0 8.5 0H7.5C7.25 0 7 0.25 7 0.5V11.5C7 11.65 7.05 11.75 7.15 11.85C7.25 11.95 7.4 12 7.5 12C7.65 12 7.75 11.95 7.85 11.85L11.85 7.85C11.95 7.75 12 7.65 12 7.5C12 7.35 11.95 7.25 11.85 7.15V7.15Z",
                                                    fill: "currentColor",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.filterProfiles, function (fp) {
                                    return _c(
                                      "tr",
                                      {
                                        key: fp.uuid,
                                        staticClass: "text-center",
                                        class: { opacity: _vm.isDeleted(fp) },
                                      },
                                      [
                                        _c("td", [
                                          fp.wa_status === "open"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "c-badge green",
                                                },
                                                [_vm._v("open")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          fp.wa_status === "connecting"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "c-badge yellow",
                                                },
                                                [_vm._v("connecting")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          fp.wa_status === "close"
                                            ? _c(
                                                "span",
                                                { staticClass: "c-badge gray" },
                                                [_vm._v("close")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "span",
                                            { staticClass: "wraps" },
                                            [
                                              !_vm.isDeleted(fp) &&
                                              (_vm.$store.state.user.id !==
                                                12 ||
                                                (_vm.$store.state.user.id ===
                                                  12 &&
                                                  fp.tech !== "js"))
                                                ? _c(
                                                    "inertia-link",
                                                    {
                                                      attrs: {
                                                        href: _vm.route(
                                                          "dashboard.instance",
                                                          fp.uuid
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(fp.name))]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.isDeleted(fp) &&
                                              _vm.$store.state.user.id === 12 &&
                                              fp.tech === "js"
                                                ? _c(
                                                    "inertia-link",
                                                    {
                                                      staticStyle: {
                                                        color: "#dc3545",
                                                      },
                                                      attrs: {
                                                        href: _vm.route(
                                                          "dashboard.instance",
                                                          fp.uuid
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(fp.name))]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isDeleted(fp)
                                                ? _c("span", [
                                                    _vm._v(_vm._s(fp.name)),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          fp.platform === "wz"
                                            ? _c("span", [_vm._v("wa")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          fp.platform === "tg"
                                            ? _c("span", [_vm._v("tg")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          fp.platform === "av"
                                            ? _c("span", [_vm._v("av")])
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "span",
                                            { staticClass: "c-title" },
                                            [
                                              !_vm.isDeleted(fp)
                                                ? _c(
                                                    "inertia-link",
                                                    {
                                                      attrs: {
                                                        href: _vm.route(
                                                          "dashboard.instance",
                                                          fp.uuid
                                                        ),
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(fp.uuid))]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isDeleted(fp)
                                                ? _c("span", [
                                                    _vm._v(_vm._s(fp.uuid)),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.user.spamer
                                          ? _c("td", [
                                              _c("span", [
                                                _vm._v(_vm._s(fp.ttc)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.user.spamer
                                          ? _c("td", [
                                              _c("span", [
                                                _vm._v(_vm._s(fp.atc)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.user.pay_system !== "days" &&
                                        !_vm.user.spamer &&
                                        _vm.user.id !== 12
                                          ? _c("td", [
                                              fp.left_days === "-"
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "c-content":
                                                          fp.left_days > 0,
                                                        "text-danger":
                                                          fp.left_days < 1,
                                                      },
                                                    },
                                                    [_vm._v("-")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              (fp.left_days !== "-" &&
                                                fp.left_days != 0 &&
                                                fp.is_payment) ||
                                              fp.left_days > 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "c-content",
                                                    },
                                                    [_vm._v("Оплачен")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              (fp.left_days !== "-" &&
                                                !fp.is_payment) ||
                                              fp.left_days == 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v("Не оплачен")]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.user.pay_system !== "days" &&
                                        !_vm.user.spamer &&
                                        _vm.user.id !== 12
                                          ? _c("td", [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    "c-content":
                                                      fp.left_days > 0,
                                                    "text-danger":
                                                      fp.left_days < 1,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(fp.left_days) +
                                                      "\n                          "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.user.pay_system === "days" &&
                                        !_vm.user.spamer &&
                                        _vm.user.id !== 12
                                          ? _c("td", [
                                              _c("span", [
                                                _vm._v(_vm._s(fp.worked_days)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("td", [
                                          fp.authorized
                                            ? _c(
                                                "span",
                                                { staticClass: "c-content" },
                                                [
                                                  _vm._v(
                                                    "\n                            Авторизован\n                          "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !fp.authorized
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "\n                            Не авторизован\n                          "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-fluid iq-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-wrap",
            },
            [
              _c("div", [
                _c("h1", { staticClass: "c-content" }, [
                  _vm._v("Статистика профилей"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "text-center text-uppercase" }, [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Дата")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Сумма")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }