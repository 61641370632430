<template>
  <div class="lk_footer">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex lkf_links">
        <div class="me-3" v-if="!this.$store.state.user.moysklad"><a href="/oferta" class="grey_link">Пользовательское соглашение</a></div>
        <div class="me-3" v-if="this.$store.state.user.moysklad"><a href="/oferta" class="grey_link" target="_blank">Пользовательское соглашение</a></div>
        <div v-if="!this.$store.state.user.moysklad"><a class="grey_link" href="/privacy">Политика конфиденциальности</a></div>
        <div v-if="this.$store.state.user.moysklad"><a class="grey_link" href="/privacy" target="_blank">Политика конфиденциальности</a></div>
      </div>
      <div class="d-flex">
        <div class="me-2">© {{currentDate.getFullYear()}}</div> 
        <div class="c-content">Wappi</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentDate: new Date,
    };
  },
  methods: {
  },
  props: [],
  created() {
    
  },
  mounted() {
    
  },
  computed: {},
  components: {
   
  },
};
</script>
